import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from "../screens/authentication/login";
import Dashboard from "../screens/dashboard";
import ClientDashboard from "../screens/client_dashboard";
import { Footer } from "../components/footer";
import StaffDashboard from "../screens/staff_dashboard";
import RegistrationDashboard from "../screens/register_dashboard";
import AssetsDasboard from "../screens/assets_dashboard";
import ReportsDasboard from "../screens/reports_dashboard";
import SchedulerDashboard from "../screens/sheduler_dashboard";
import ServiceDashboard from "../screens/service_dashboard";
import AdminDashboard from "../screens/admin_dashboard";
import FinanceDashboard from "../screens/finance-dashboard";
import AuditDasboard from "../screens/audit_dashboard";
import ProfileDashboard from "../screens/profile_dashboard";
import ProtectedRoutes from "../routes/protected_routes";

const Navigation = () => {
    return (
        <BrowserRouter>
            <Routes >
                <Route exact path="/" element={<Login />} />
                <Route path="/my-profile" element={<ProtectedRoutes><ProfileDashboard /> </ProtectedRoutes>} />
                <Route path="/home" element={<ProtectedRoutes> <Dashboard /> </ProtectedRoutes>} />
                <Route path="/client-dashboard" element={<ProtectedRoutes><ClientDashboard /> </ProtectedRoutes>} />
                <Route path="/staff-dashboard" element={<ProtectedRoutes><StaffDashboard /> </ProtectedRoutes>} />
                <Route path="/registration-dashboard" element={<ProtectedRoutes><RegistrationDashboard /> </ProtectedRoutes>} />
                <Route path="/assets-dashboard" element={<ProtectedRoutes><AssetsDasboard /> </ProtectedRoutes>} />
                <Route path="/reports-dashboard" element={<ProtectedRoutes><ReportsDasboard /> </ProtectedRoutes>} />
                <Route path="/scheduler-dashboard" element={<ProtectedRoutes><SchedulerDashboard /> </ProtectedRoutes>} />
                <Route path="/service-dashboard" element={<ProtectedRoutes><ServiceDashboard /> </ProtectedRoutes>} />
                <Route path="/admin-dashboard" element={<ProtectedRoutes><AdminDashboard /> </ProtectedRoutes>} />
                <Route path="/finance-dashboard" element={<ProtectedRoutes><FinanceDashboard /> </ProtectedRoutes>} />
                <Route path="/audit-dashboard" element={<ProtectedRoutes><AuditDasboard /> </ProtectedRoutes>} />
                {/* <Route path="/staff-details-view" element={<StaffDetailsViewV2 />} /> */}
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}

export default Navigation;

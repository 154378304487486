import { createAdministrativeNote, createAdmissionInformation, createAuditCategory, createAuditDocumentName, createAuditModule, createAuditType, createChargeBandDetail, createChargeBandRate, createCheckListAlertsNotification, createClientAnswerLovsReference, createClientDetail, createClientGoal, createClientNote, createClientQuestionAnswerRef, createContactDetailForClient, createContactDetailForStaff, createDocumentsProfessionalList, createEmergencyContactDetailForClient, createEmergencyContactDetailForStaff, createInvoicing, createMedicationsForClient, createNewDocument, createNewStaff, createNewStaffPay, createNewUserForLogin, createOnboardMedicalHistoryDetail, createOrganization, createPlanReview, createPreferenceForStaffWorker, createPriceList, createReferringDoctorInformation, createServiceInformation, createServicePlan, createServiceRequest, createServiceShift, createStaffAnswerLovsReference, createStaffOthersInformation, createStaffPaymentDetail, createStaffQuestionSingleAnswerRef, createVisitDay, createVisitShiftCheckList, createWorkingDay, loginQuery, updateChargeBandRate, updateClientDetail, updateKilometersClaim, updateReimbursement, updateServicePlan, updateStaffDetail, uploadNewDocument } from './mutation'
import { admissionInformationsById, auditCategories, auditDocumentNames, auditModules, auditTypes, clientDetailById, clientDetailList, clientDetailsRelationlData, clientGoalsAndCareDetailsById, designFrontendModules, dynamicFrontends, filterVisitDayAndCheckList, getAllUserDetails, getDocumentList, reimbursementsByServicePlan, getStaffDetailsWithId, getUserData, homeRiskAssessment, individualRiskAssessment, medicalInformationById, organizationList, priceList, referringDoctorInformationsById, safetyPlanById, servicePlans, servicePlansFilters, serviceRequests, staffDetailsList, staffDetailsWithRelationalData, workingDaysByFilters, kilometersClaimsByServicePlan, servicePlansFilter, allRelationalClientInformationById, visitDaysByServiceId, serviceShiftFilters, preferenceForStaffWorkerById, ndisPriceList, chargeBandDetails, chargeBandRates, historyOfClientService, } from './query'

const APIGQL = {
    ApiLogin: loginQuery,
    ApiUserDetails: getUserData,
    ApiUploadNewDocument: uploadNewDocument,
    // ApiDesignFrontendsByTitle: designFrontendsByTitle,
    ApiDynamicFrontends: dynamicFrontends,
    ApiDesignFrontendModules: designFrontendModules,
    ApiGetDocumentList: getDocumentList,
    ApiGetAuditModules: auditModules,
    ApiGetAuditCategories: auditCategories,
    ApiGetAuditTypes: auditTypes,
    ApiGetAuditDocumentNames: auditDocumentNames,
    ApiGetPriceList: priceList,
    ApiGetOrganizationList: organizationList,
    ApiGetStaffDetailsList: staffDetailsList,
    ApiGetStaffDetailsWithId: getStaffDetailsWithId,
    ApiGetClientDetailList: clientDetailList,
    ApiClientDetailsRelationlData: clientDetailsRelationlData,
    ApiGetClientDetailById: clientDetailById,
    ApiGetMedicalInformationById: medicalInformationById,
    ApiGetReferringDoctorInformationsById: referringDoctorInformationsById,
    ApiGetAdmissionInformationsById: admissionInformationsById,
    ApiGetIndividualRiskAssessment: individualRiskAssessment,
    ApiGetHomeRiskAssessment: homeRiskAssessment,
    ApiGetSafetyPlanById: safetyPlanById,
    ApiGetClientGoalsAndCareDetailsById: clientGoalsAndCareDetailsById,
    ApiGetStaffDetailsWithRelationalData: staffDetailsWithRelationalData,
    // // // // -----------------------------------
    ApiCreateNewDocument: createNewDocument,
    ApiCreateNewModule: createAuditModule,
    ApiCreateAuditCategory: createAuditCategory,
    ApiCreateAuditType: createAuditType,
    ApiCreateAuditDocumentName: createAuditDocumentName,
    ApiCreatePriceList: createPriceList,
    ApiCreateOrganization: createOrganization,
    // // // // -----------------staff onboard------------------------------
    ApiCreateNewStaff: createNewStaff,
    ApiCreateStaffQuestionSingleAnswerRef: createStaffQuestionSingleAnswerRef,
    ApiCreateStaffAnswerLovsReference: createStaffAnswerLovsReference,
    ApiCreateContactDetailForStaff: createContactDetailForStaff,
    ApiCreateEmergencyContactDetailForStaff: createEmergencyContactDetailForStaff,
    ApiCreateStaffOthersInformation: createStaffOthersInformation,
    ApiCreateStaffPaymentDetail: createStaffPaymentDetail,
    ApiCreateDocumentsProfessionalList: createDocumentsProfessionalList,
    ApiCreateWorkingDay: createWorkingDay,
    ApiCreateNewUserForLogin: createNewUserForLogin,
    // // ===========================admin==========================================
    ApiGetAllUserDetails: getAllUserDetails,
    /////////========================admin======================================
    ApiUpdateStaffDetail: updateStaffDetail,
    ApiUpdateClientDetail: updateClientDetail,
    // /// /// /// // =================client onboard ==========================
    ApiCreateClientDetail: createClientDetail,
    ApiCreateClientAnswerLovsReference: createClientAnswerLovsReference,
    ApiCreateContactDetailForClient: createContactDetailForClient,
    ApiCreateEmergencyContactDetailForClient: createEmergencyContactDetailForClient,
    ApiCreateAdministrativeNote: createAdministrativeNote,
    ApiCreateServiceInformation: createServiceInformation,
    ApiCreatePreferenceForStaffWorker: createPreferenceForStaffWorker,
    ApiCreateClientNote: createClientNote,
    ApiCreateClientGoal: createClientGoal,
    ApiCreateCheckListAlertsNotification: createCheckListAlertsNotification,
    ApiCreatePlanReview: createPlanReview,
    ApiCreateMedicationsForClient: createMedicationsForClient,
    ApiCreateOnboardMedicalHistoryDetail: createOnboardMedicalHistoryDetail,
    ApiCreateReferringDoctorInformation: createReferringDoctorInformation,
    ApiCreateAdmissionInformation: createAdmissionInformation,
    ApiCreateClientQuestionAnswerRef: createClientQuestionAnswerRef,
    // // // // // =============Servie===========================================
    ApiCreateServiceRequest: createServiceRequest,
    ApiCreateServiceShift: createServiceShift,
    ApiCreateVisitDay: createVisitDay,
    ApiCreateVisitShiftCheckList: createVisitShiftCheckList,
    ApiGetServiceRequests: serviceRequests,
    ApiGetFilterVisitDayAndCheckList: filterVisitDayAndCheckList,
    ApiGetServicePlans: servicePlans,
    ApiCreateServicePlan: createServicePlan,
    ApiServicePlansFilter: servicePlansFilter,
    ApiUpdateServicePlan: updateServicePlan,
    // // // // /---------------invoice=========================================
    ApiGetReimbursementsByServicePlan: reimbursementsByServicePlan,
    ApiUpdateReimbursement: updateReimbursement,
    ApiGetKilometersClaimsByServicePlan: kilometersClaimsByServicePlan,
    ApiUpdateKilometersClaim: updateKilometersClaim,
    // // // // // // // =============View============================================================
    ApiGetAllRelationalClientInformationById: allRelationalClientInformationById,
    // // /// // // // schedule ============================================================
    ApiVisitDaysByServiceId: visitDaysByServiceId,
    ApiServiceShiftFilters: serviceShiftFilters,
    ApiWorkingDaysByFilters: workingDaysByFilters,
    ApiPreferenceForStaffWorkerById: preferenceForStaffWorkerById,
    ApiNdisPriceList: ndisPriceList,
    ApiCreateChargeBandDetail: createChargeBandDetail,
    ApiGetChargeBandDetails: chargeBandDetails,
    ApiCreateChargeBandRate: createChargeBandRate,
    ApiChargeBandRates: chargeBandRates,
    ApiUpdateChargeBandRate: updateChargeBandRate,
    // // // // // =======================invoice============================================
    ApiCreateInvoicing: createInvoicing,
    ApiCreateNewStaffPay: createNewStaffPay,
    ApiHistoryOfClientService: historyOfClientService,
}

export default APIGQL;
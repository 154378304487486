import { useQuery } from "@apollo/client";
import APIGQL from "./graphql";


export const DynamicFrontendContent = () => {
    const { data, loading } = useQuery(APIGQL.ApiDynamicFrontends, {
        fetchPolicy: "network-only",
    })
    const dynamicData = data?.dynamicFrontends?.data;

    return [dynamicData, loading]
}

export const DesignFrontendModules = () => {
    const { data } = useQuery(APIGQL.ApiDesignFrontendModules, {
        fetchPolicy: "network-only",
    })
    const designModule = data?.designFrontendModules?.data;

    return designModule
}
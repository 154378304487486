import { Avatar, Badge, Box, Button, Checkbox, CircularProgress, Collapse, FormControlLabel, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
// import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DateCalendar, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FolderIcon from '@mui/icons-material/Folder';
import { useDispatch, useSelector } from 'react-redux';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { StaffDetailsWithRelationalDataId } from '../../../services/staff_service';
import { IMAGE } from '../../../config/constant';
import moment from 'moment';
import { HistoryOfClientService } from '../../../services/client_service';
import { ServiceShiftFilters, VisitDaysByServiceId } from '../../../services/schedule_service';
import { DateCalendarServerRequest } from '../../../components/calender';

const generateDayTimes = (dateString) => {
    const date = new Date(dateString);
    const dayTimes = [];

    // Set start and end times for the day
    const startTime = new Date(date);
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(date);
    endTime.setHours(23, 59, 59, 999);

    while (startTime <= endTime) {
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const dateString = startTime.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format

        dayTimes.push({
            time: `${formattedHours}:${formattedMinutes} ${ampm}`,
            date: dateString,
            meeting: []
        });

        startTime.setMinutes(startTime.getMinutes() + 30); // Increment by 30 minutes
    }

    return dayTimes;
}

const getAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}

const StaffDetailsViewV2 = () => {
    const [fetchStaffDataById, loading_d, error_d] = StaffDetailsWithRelationalDataId();
    const [getHistoryOfClientService, loading_his, error_his] = HistoryOfClientService();
    const [getServiceShiftFilters, loading_shift, error_shif] = ServiceShiftFilters();
    const [getVisitDaysByServiceId, loading_visit, error_visit] = VisitDaysByServiceId();

    const [activeTab, setActiveTab] = useState("general");
    const [serviceTab, setServiceTab] = useState("ongoing");
    const [incidentsTab, setIncidentsTab] = useState("incidents");
    const [claimsTab, setClaimsTab] = useState("reimbusement");
    const [meetingTab, setMeetingTab] = useState("meeting");
    const _history = useSelector((state) => state._history.staff_details_id);

    const [active, setActive] = useState(true);
    const [staffDetailsData, setStaffDetailsData] = useState(null);
    const [activeService, setActiveService] = useState(null);
    const [serviceRequest, setServiceRequest] = useState(null);
    const [serviceHistory, setServiceHistory] = useState(null);
    const [workingDaysList, setWorkingDaysList] = useState(null);
    const [activeServiceCheck, setActiveServiceCheck] = useState(null);
    const [busyDates, setBusyDates] = useState(null);
    const [loadingInstant, setLoadingInstant] = useState(false);

    useEffect(() => {
        if (_history) {
            getFetchStaffDataById(_history);
        }
    }, [_history])

    useEffect(() => {
        if (loading_d || loading_shift) {
            setLoadingInstant(true)
        }
    }, [loading_d, loading_shift])

    const initCollapse = (data) => {
        return data.map((item) => ({
            ...item,
            collapse: false
        }));
    }

    const groupById = (data) => {
        // Grouping objects by their id using reduce
        const grouped = data.reduce((acc, obj) => {
            // If the id is not already a key in the accumulator, create a new array for it
            const serv_id = obj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.id

            if (!acc[serv_id]) {
                acc[serv_id] = [];
            }
            // Push the object into the array for this id
            acc[serv_id].push(obj);
            return acc;
        }, {});

        // Convert the grouped object into an array of arrays
        return Object.values(grouped);
    }

    const getFetchStaffDataById = async (id) => {
        try {
            const response = await fetchStaffDataById({
                variables: {
                    "filter": {
                        "staff_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    },
                    "id": id,
                    "filterDay": {
                        "staff_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }
            })
            if (response?.data) {
                const copy = response?.data;
                setStaffDetailsData(copy);
                setWorkingDaysList(initCollapse(copy?.workingDays?.data))
            }
            const response_shift = await getServiceShiftFilters({
                variables: {
                    "filter": {
                        "staff_detail_id": {
                            "id": {
                                "eq": id
                            }
                        }
                    }
                }
            });
            if (response_shift?.data) {
                const shifts = response_shift?.data.serviceShifts?.data;
                const margeShiftsDataByServiceRequest = groupById(shifts);
                const copy_group = [];
                const copy = [];
                const copy_history = [];

                for (const data of margeShiftsDataByServiceRequest) {
                    let object = {
                        shifts: []
                    };
                    for (const key in data) {
                        object.service_request = data[key]?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data;
                        if (!object.service_request) {
                            break
                        }
                        object.collapse = false;
                        const findC = copy_group.find((e) => e.service_request.id === object.service_request.id);
                        if (!findC) {
                            object.shifts.push({ ...data[key], collapse: false })
                            copy_group.push(object)
                        } else {
                            findC.shifts.push({ ...data[key], collapse: false })
                        }
                    }
                }

                for (const key in copy_group) {
                    const response_day = await getVisitDaysByServiceId({
                        variables: {
                            "filterCheck": {
                                "service_request_id": {
                                    "id": {
                                        "eq": copy_group[key]?.service_request?.id
                                    }
                                }
                            },
                            "filterVisit": {
                                "service_request_id": {
                                    "id": {
                                        "eq": copy_group[key]?.service_request?.id
                                    }
                                }
                            }
                        }
                    })
                    if (response_day?.data) {
                        const visit_data = response_day?.data;
                        const visit_days = visit_data?.visitDays?.data;
                        const check_list = visit_data?.visitShiftCheckLists?.data;
                        copy_group[key].visit_days = visit_days[0];
                        copy_group[key].service_check_list = check_list.length > 0 ? initCollapse(check_list) : check_list;;
                    }
                    if (copy_group[key].service_request.attributes.status.toLowerCase() === "in progress") {
                        const dates = copy_group[key]?.shifts?.map((e) => e.attributes.shift_date);
                        setBusyDates(dates);
                        setActiveService(copy_group[key]);
                    } else {
                        copy_history.push(copy_group[key]);
                    }
                }
                setServiceRequest(copy);
                setServiceHistory(copy_history);
                setLoadingInstant(false);
            }
        } catch (error) {
            console.log("error error", error)
        }
    }

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const meetingDate = new Date().getDate() + " " + months[new Date().getMonth()] + " " + new Date().getFullYear() + ", " + weekdays[new Date().getDay()]

    const [selectedDate, setSelectedDate] = useState("2024-07-19");
    const [dayTimesMeet, setDayTimesMeet] = useState(null)

    useEffect(() => {
        const objectOfTimes = generateDayTimes(selectedDate);
        const copy = [...objectOfTimes];
        copy[30].meeting.push({ meeting_time: "3:00 PM" })
        copy[31].meeting.push({ meeting_time: "3:30 PM" })
        copy[32].meeting.push({ meeting_time: "4:00 PM" })
        copy[33].meeting.push({ meeting_time: "4:30 PM" })
        setDayTimesMeet(copy);
    }, [])


    const [days, setDays] = useState([
        {
            title: "Monday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: true
        },
        {
            title: "Tuesday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Wednesday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Thursday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Friday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Saturday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        },
        {
            title: "Sunday",
            availability: true,
            time_slots: [
                {
                    start_time: "13:00",
                    end_time: "15:30"
                },
                {
                    start_time: "18:00",
                    end_time: "22:30"
                },
            ],
            availabilityOption: [
                {
                    title: "Unavailable",
                    value: false
                },
                {
                    title: "Whole Day",
                    value: false
                }
            ],
            collapse: false
        }
    ]);

    const [service, setService] = useState([
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "Ongoing",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: true,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "",
                            completed: false,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                }
            ]
        },
        {
            title: "Assistance with self care activity",
            service_type: "Every 3 Days",
            status: "Ongoing",
            pay_travel: "Yes",
            funding_source: "NDIS",
            billable: "Yes",
            collapse: false,
            visit_day: [
                {
                    date: "10 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: true,
                    visit_shift_checklist: [
                        {
                            title: "Shopping",
                            comment: "",
                            completed: false,
                            collapse: true,
                        },
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Cooking",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                },
                {
                    date: "14 june 2024",
                    day: "Monday",
                    start_time: "13:00",
                    end_time: "15:30",
                    collapse: false,
                    visit_shift_checklist: [
                        {
                            title: "Gardening",
                            comment: "",
                            completed: false,
                            collapse: true,
                        },
                        {
                            title: "Singing",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                        {
                            title: "Running",
                            comment: "",
                            completed: false,
                            collapse: false,
                        },
                    ],
                }
            ]
        }
    ]);

    const handleCollapseReq = (index, data, setData) => {
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = false;
        }
        copy[index].collapse = !copy[index].collapse;
        setData(copy)
    }

    const handleCollapse = (index, data, setData) => {
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = false;
        }
        copy[index].collapse = !copy[index].collapse;
        setData(copy)
    }

    const handleCollapseReqShift = (indexMain, index, data, setData) => {
        const copyMain = [...serviceRequest];
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = false;
        }
        copy[index].collapse = !copy[index].collapse;
        copyMain[indexMain].shifts = copy;
        setData(copyMain);
    }

    const handleCollapseReqCheckList = (indexMain, index, data, setData) => {
        const copyMain = [...serviceRequest];
        const copy = [...data];
        for (const key in copy) {
            copy[key].collapse = false;
        }
        copy[index].collapse = !copy[index].collapse;
        copyMain[indexMain].shifts = copy;
        setData(copyMain)
    }

    const handleActiveService = (index, data, setData) => {
        const copy = { ...data };
        for (const key in copy.visit_day) {
            copy.visit_day[key].collapse = false;
        }
        copy.visit_day[index].collapse = !copy.visit_day[index].collapse;
        setActiveServiceCheck(copy.visit_day[index].visit_shift_checklist)
        setData(copy)
    }

    useEffect(() => {
        const active = service.find((e) => e.collapse === true);
        for (const key in active.visit_day) {
            if (active.visit_day[key].collapse) {
                setActiveServiceCheck(active.visit_day[key].visit_shift_checklist)
            }
        }
        setActiveService(active)
    }, [service])

    const gender = staffDetailsData?.staffAnswerLovsReferences?.data?.find((e) => e.attributes.input_name.toLowerCase() === "gender")
    const designation = staffDetailsData?.staffAnswerLovsReferences?.data?.find((e) => e.attributes.input_name.toLowerCase() === "designation")
    const religion = staffDetailsData?.staffDetail?.data?.attributes.religion;
    const language_spoken = staffDetailsData?.staffDetail?.data?.attributes.language_spoken;

    // console.log("activeService", activeService)
    // console.log("serviceRequest", serviceRequest)
    // console.log("serviceHistory", serviceHistory)
    return (
        <Grid container sx={{ bgcolor: "#F5F7F6", }}>
            {loadingInstant ? (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress color="secondary" size={50} />
                </Box>
            ) : (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", flexDirection: "row", height: window.screen.width > 1600 ? "90vh" : "92vh" }}>
                    {/* ================ left container =============== */}
                    <Grid item={true} xs={11} sm={3} md={3} lg={2} >
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Avatar sx={{ width: 70, height: 70, boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.14)", }} alt={staffDetailsData?.staffDetail?.data?.attributes?.first_name} src={`${IMAGE}${staffDetailsData?.staffDetail?.data?.attributes?.profile_picture?.data?.attributes?.url}`} />
                                {/* <Avatar sx={{ width: 75, height: 75 }} src={`/assets/images/Leanna-B_thumb.jpg`} /> */}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                <Typography color="primaryText" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                    {staffDetailsData?.staffDetail?.data?.attributes?.first_name} {staffDetailsData?.staffDetail?.data?.attributes?.last_name}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                    Client Id: {staffDetailsData?.staffDetail?.data?.id}
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <Switch color="primary" checked={staffDetailsData?.staffDetail?.data?.attributes?.active} onClick={() => setActive(!staffDetailsData?.staffDetail?.data?.attributes?.active)} />
                                {staffDetailsData?.staffDetail?.data?.attributes?.active ?
                                    <Typography color={"primary"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                        Active
                                    </Typography> :
                                    <Typography color={"secondary.text3"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                        Inactive
                                    </Typography>
                                }
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                    General Information
                                </Typography>
                                <IconButton>
                                    <BorderColorOutlinedIcon color='primary' />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {moment(staffDetailsData?.staffDetail?.data?.attributes?.date_of_birth).format("DD/MM/YYYY")}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Date of Birth:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {getAge(staffDetailsData?.staffDetail?.data?.attributes?.date_of_birth)}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Age:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {gender?.attributes?.selected_option_value}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Gender:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {religion}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Religion:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {language_spoken}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Language Spoken:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.contactDetail?.data?.attributes?.home_phone}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Home Phone:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.contactDetail?.data?.attributes?.mobile_phone}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Mobile Phone:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {designation?.attributes?.selected_option_value}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Designation:" />
                                </ListItem>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", py: 1, px: 2, backgroundColor: "#fff", border: "1px solid #6ECA35", mx: 3, my: 2, borderRadius: 4, height: 255 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                    Emergency Contact
                                </Typography>
                                <IconButton>
                                    <BorderColorOutlinedIcon color='primary' />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column" }}>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.emergencyContactDetail?.data?.attributes?.first_name}{" "}
                                            {staffDetailsData?.emergencyContactDetail?.data?.attributes?.last_name}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Name:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.emergencyContactDetail?.data?.attributes?.relationship}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Relationship:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.emergencyContactDetail?.data?.attributes?.home_phone}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Home Phone:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.emergencyContactDetail?.data?.attributes?.mobile_phone}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Mobile:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.emergencyContactDetail?.data?.attributes?.address_1}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address_1:" />
                                </ListItem>
                                <ListItem
                                    sx={{ p: 0, m: 0 }}
                                    disableGutters
                                    secondaryAction={
                                        <Typography color="primaryText" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                            {staffDetailsData?.emergencyContactDetail?.data?.attributes?.address_2}
                                        </Typography>
                                    }
                                >
                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Address_2:" />
                                </ListItem>
                            </Box>
                        </Box>
                    </Grid>
                    {/* ================ right container =============== */}
                    <Grid item={true} xs={11} sm={9} md={9} lg={10} >
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                            {activeService ? (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", px: 4, flexDirection: "column", justifyContent: "center", backgroundColor: "#fff", my: 2, height: 90, border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                        Current Status
                                    </Typography>
                                    <Typography color="secondary.text" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.1em" }}>
                                        {activeService?.service_request?.attributes?.status} - {activeService?.service_request?.attributes?.charge_band_rate_id?.data?.attributes?.service_title}
                                    </Typography>
                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "0.8em" }}>
                                        Ends in 27 minutes
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", px: 4, flexDirection: "column", justifyContent: "center", backgroundColor: "#fff", my: 2, height: 90, border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                        Nothing Inprogress
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item={true} xs={12} sm={12} md={4} lg={4} sx={{ backgroundColor: "#fff", p: 2, my: 2, mx: 3, height: 90, border: "1px solid #6ECA35", borderRadius: 4, }}>
                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                    Registration #: <span style={{ color: "#35BE00" }}>{staffDetailsData?.staffDetail?.data?.attributes?.ndis_clearance_number}</span>
                                </Typography>
                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                    Expiry Date: <span style={{ color: "#35BE00" }}>{moment(staffDetailsData?.staffDetail?.data?.attributes?.registration_expiry_date).format('DD/MM/YYYY')}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* tabs */}
                        <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                            <Grid xs={12} sm={12} md={12} lg={12} sx={{ px: 2, display: "flex", justifyContent: "flex start", alignItems: "center", mr: 3, height: 70, border: "2px dotted #999999", }}>
                                <Stack direction={"row"} spacing={2}>
                                    <Button onClick={() => setActiveTab("general")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("general") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                        General
                                    </Button>
                                    <Button onClick={() => setActiveTab("schedual")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("schedual") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                        Service Schedule
                                    </Button>
                                    <Button onClick={() => setActiveTab("reports")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("reports") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                        Incident / Complaint / Feedback
                                    </Button>
                                    <Button onClick={() => setActiveTab("claims")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("claims") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                        Claims & Compliance
                                    </Button>
                                    <Button onClick={() => setActiveTab("meeting")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("meeting") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                        Meeting History
                                    </Button>
                                    <Button onClick={() => setActiveTab("trainings")} color={"primary"} variant="contained" sx={{ boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", bgcolor: activeTab.includes("trainings") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                        Trainings
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* tab container */}
                        <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                            {/* handling active tabs */}
                            {activeTab.includes("general") && (
                                <Grid xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", flexDirection: "row", my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    {/* left container */}
                                    <Box sx={{ width: "65%", borderRadius: 5, ml: 3, bgcolor: "#F7F7F7", height: "100%", overflowY: "auto" }}>
                                        <Box sx={{ px: 2, py: 1 }}>
                                            <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                Working Days
                                            </Typography>
                                        </Box>
                                        <Box sx={{ px: 2, }}>
                                            {workingDaysList?.map((v, i) => {
                                                return (
                                                    <List key={v?.attributes?.select_day} component={Paper} sx={{ p: 0, my: 1 }}>
                                                        <ListItem>
                                                            <ListItemText primaryTypographyProps={{
                                                                fontFamily: "Roboto-Bold",
                                                                fontSize: "1.2em"
                                                            }} primary={v?.attributes?.select_day} secondary={v?.attributes?.unavailable ? "Unavailable" : "Available"} />
                                                            <Stack direction={"row"} spacing={2}>
                                                                <IconButton>
                                                                    <BorderColorOutlinedIcon color={"primary"} />
                                                                </IconButton>
                                                                {v.collapse ?
                                                                    <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, workingDaysList, setWorkingDaysList)} variant='contained' color='primary'>
                                                                        <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                    </Button>
                                                                    : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, workingDaysList, setWorkingDaysList)} variant='contained' color='primary'>
                                                                        <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                    </Button>
                                                                }
                                                            </Stack>
                                                        </ListItem>
                                                        <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 1 }}>
                                                                <Box sx={{ width: "45%", }}>
                                                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1.2em" }}>
                                                                        Time Slots:
                                                                    </Typography>
                                                                    <Box sx={{ px: 5 }}>
                                                                        <Typography color="primary.text" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                            {v?.attributes?.day_start_time.slice(0, 5)} - {v?.attributes?.day_end_time.slice(0, 5)}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ width: "45%", }}>
                                                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1.2em" }}>
                                                                        Availability:
                                                                    </Typography>
                                                                    <Box sx={{ px: 5 }}>
                                                                        <FormControlLabel
                                                                            spacing={0}
                                                                            sx={{ m: 0, p: 0 }}
                                                                            control={
                                                                                <Checkbox
                                                                                    sx={{
                                                                                        color: "primary.text"
                                                                                    }}
                                                                                // checked={vN.value}
                                                                                // onChange={(e) => changeAvailability(i, nI, e.target.value)}
                                                                                />
                                                                            }
                                                                            label={"unavailable :"}
                                                                            labelPlacement='start'
                                                                        />
                                                                        <FormControlLabel
                                                                            spacing={0}
                                                                            sx={{ m: 0, p: 0 }}
                                                                            control={
                                                                                <Checkbox
                                                                                    sx={{
                                                                                        color: "primary.text"
                                                                                    }}
                                                                                // checked={vN.value}
                                                                                // onChange={(e) => changeAvailability(i, nI, e.target.value)}
                                                                                />
                                                                            }
                                                                            label={"Whole Day :"}
                                                                            labelPlacement='start'
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Collapse>
                                                    </List>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    {/* right container */}
                                    <Box sx={{ width: "35%", borderRadius: 5, ml: 3, mr: 3, height: "100%" }}>
                                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: 5, mb: 1, bgcolor: "#F7F7F7", height: "63%" }}>
                                            <Box sx={{ width: "80%", py: 1, }}>
                                                <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                    Calendar
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                <Box sx={{ width: "100%", height: 300, bgcolor: "#fff", borderRadius: 4 }}>
                                                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateCalendar
                                                            fixedWeekNumber={6}
                                                            slots={{
                                                                day: ServerDay,
                                                            }}
                                                            slotProps={{
                                                                day: {
                                                                    busyDates,
                                                                },
                                                            }}
                                                        />
                                                    </LocalizationProvider> */}
                                                    {busyDates ? <DateCalendarServerRequest days={busyDates} /> : null}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "100%", display: 'flex', alignItems: "center", flexDirection: "column", borderRadius: 5, mb: 1, bgcolor: "#F7F7F7", height: "36%" }}>
                                            {/* Head */}
                                            <Box sx={{ width: "80%", display: "flex", py: 0.5, }}>
                                                <Box sx={{ width: "50%", display: 'flex', alignItems: "center" }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                        Leave Application
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
                                                    <Stack direction={"row"} spacing={2}>
                                                        <IconButton>
                                                            <AddCircleOutlineIcon color='primary' />
                                                        </IconButton>
                                                        <IconButton>
                                                            <FolderIcon color='primary' />
                                                        </IconButton>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                            {/* card */}
                                            <Box sx={{ display: "flex", justifyContent: "center", width: "80%", height: 145, bgcolor: "#fff", borderRadius: 4, }}>
                                                <Box sx={{ width: "90%", }}>
                                                    <ListItem
                                                        sx={{ p: 0, m: 0 }}
                                                        disableGutters
                                                        secondaryAction={
                                                            <Typography color="secondary.text" sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>
                                                                Days
                                                            </Typography>
                                                        }
                                                    >
                                                        <ListItemText sx={{ m: 0, }} primaryTypographyProps={{ sx: { fontFamily: "Roboto-Bold", color: "secondary.text", fontSize: "1em" } }} primary="Leave Application:" />
                                                    </ListItem>
                                                    {/* data */}
                                                    <ListItem
                                                        sx={{ p: 0, m: 0 }}
                                                        disableGutters
                                                        secondaryAction={
                                                            <Typography color="primary.text" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                                                12
                                                            </Typography>
                                                        }
                                                    >
                                                        <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Annual Leaves:" />
                                                    </ListItem>
                                                    <ListItem
                                                        sx={{ p: 0, m: 0 }}
                                                        disableGutters
                                                        secondaryAction={
                                                            <Typography color="primary.text" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                                                7
                                                            </Typography>
                                                        }
                                                    >
                                                        <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Public Holidays:" />
                                                    </ListItem>
                                                    <ListItem
                                                        sx={{ p: 0, m: 0 }}
                                                        disableGutters
                                                        secondaryAction={
                                                            <Typography color="primary.text" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                                                10
                                                            </Typography>
                                                        }
                                                    >
                                                        <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Sick Leaves:" />
                                                    </ListItem>
                                                    <ListItem
                                                        sx={{ p: 0, m: 0 }}
                                                        disableGutters
                                                        secondaryAction={
                                                            <Typography color="primary.text" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                                                10
                                                            </Typography>
                                                        }
                                                    >
                                                        <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Maternity/Paternity Leaves:" />
                                                    </ListItem>
                                                    <ListItem
                                                        sx={{ p: 0, m: 0 }}
                                                        disableGutters
                                                        secondaryAction={
                                                            <Typography color="primary.text" sx={{ fontFamily: "Roboto-Bold", fontSize: "0.9em" }}>
                                                                10
                                                            </Typography>
                                                        }
                                                    >
                                                        <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ sx: { color: "secondary.text2", fontSize: "0.9em" } }} primary="Unpaid Leaves:" />
                                                    </ListItem>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {activeTab.includes("schedual") && (
                                <Grid xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", justifyContent: "center", }}>
                                        <Box sx={{ width: "95%", display: "flex", justifyContent: "flex-end" }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <Button color={"primary"} variant="contained" onClick={() => setServiceTab("ongoing")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: serviceTab.includes("ongoing") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                    Ongoing
                                                </Button>
                                                <Button color={"primary"} variant="contained" onClick={() => setServiceTab("history")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: serviceTab.includes("history") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                    History
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Box>
                                    {/* box box bgcolor: "#F7F7F7"*/}
                                    {serviceTab.includes("ongoing") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, justifyContent: "space-evenly" }}>
                                            <Box sx={{ width: "47%", }}>
                                                <Box sx={{ overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                    <Box sx={{ px: 2, py: 1 }}>
                                                        <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                            Service Request Information
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ px: 2, }}>
                                                        {serviceRequest?.map((v, index) => {
                                                            const value = v.service_request;
                                                            return (
                                                                <List key={value?.attributes?.charge_band_rate_id?.data?.attributes?.service_title + index} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                    <ListItem>
                                                                        <ListItemText primaryTypographyProps={{
                                                                            fontFamily: "Roboto-Bold",
                                                                            fontSize: "1.2em"
                                                                        }} primary={value?.attributes?.charge_band_rate_id?.data?.attributes?.service_title?.slice(0, 40)} secondary={v?.visit_days?.attributes?.recurring_type} />
                                                                        <Stack direction={"row"} spacing={2}>
                                                                            <IconButton>
                                                                                <BorderColorOutlinedIcon color={"primary"} />
                                                                            </IconButton>
                                                                            {v.collapse ?
                                                                                <Button onClick={() => handleCollapseReq(index, serviceRequest, setServiceRequest)} sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} variant='contained' color='primary'>
                                                                                    <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                                </Button>
                                                                                : <Button onClick={() => handleCollapseReq(index, serviceRequest, setServiceRequest)} sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} variant='contained' color='primary'>
                                                                                    <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                                </Button>
                                                                            }
                                                                        </Stack>
                                                                    </ListItem>
                                                                    <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 1 }}>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Status: <span style={{ color: "#3A89CC" }}>{value?.attributes?.status}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Pay Travel: <span style={{ color: "#3A89CC" }}>{value?.attributes?.pay_travel_km}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 1 }}>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Funding Source: <span style={{ color: "#3A89CC" }}>{value?.attributes?.funding_source}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: "45%", }}>
                                                                                <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Billable: <span style={{ color: "#3A89CC" }}>{value?.attributes?.billable}</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Collapse>
                                                                </List>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                                <Box sx={{ overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                    <Box sx={{ px: 2, py: 1 }}>
                                                        <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                            Visit Days
                                                        </Typography>
                                                    </Box>
                                                    {serviceRequest?.map((value, index) => {
                                                        const shifts = value.shifts;
                                                        return (
                                                            <Box key={index} sx={{ px: 2, }}>
                                                                {shifts?.map((v, i) => (
                                                                    <List key={v.attributes.shift_date + i} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                        <ListItem>
                                                                            <ListItemText primaryTypographyProps={{
                                                                                fontFamily: "Roboto-Bold",
                                                                                fontSize: "1.2em"
                                                                            }} primary={v.attributes.shift_date} secondary={v.attributes.shift_start_flag} />
                                                                            <Stack direction={"row"} spacing={2}>
                                                                                <IconButton>
                                                                                    <BorderColorOutlinedIcon color={"primary"} />
                                                                                </IconButton>
                                                                                {v.collapse ?
                                                                                    <Button onClick={() => handleCollapseReqShift(index, i, serviceRequest[index].shifts, setServiceRequest)} sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} variant='contained' color='primary'>
                                                                                        <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                                    </Button>
                                                                                    : <Button onClick={() => handleCollapseReqShift(index, i, serviceRequest[index].shifts, setServiceRequest)} sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} variant='contained' color='primary'>
                                                                                        <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                                    </Button>
                                                                                }
                                                                            </Stack>
                                                                        </ListItem>
                                                                        <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                            <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: "center", mb: 2 }}>
                                                                                <Box sx={{ width: "45%", }}>
                                                                                    <Box sx={{ width: "100%" }}>
                                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                            Start Time: {v?.attributes?.shift_start?.slice(0, 5)}
                                                                                        </Typography>
                                                                                        <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                            End Time: {v?.attributes?.shift_end?.slice(0, 5)}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box sx={{ width: "45%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                                                                    <Stack direction={"row"} spacing={2}>
                                                                                        <IconButton>
                                                                                            <AccountBalanceWalletOutlinedIcon color={"primary"} />
                                                                                        </IconButton>
                                                                                        <IconButton>
                                                                                            <DriveEtaOutlinedIcon color={"primary"} />
                                                                                        </IconButton>
                                                                                        <IconButton>
                                                                                            <AccessTimeOutlinedIcon color={"primary"} />
                                                                                        </IconButton>
                                                                                        <IconButton>
                                                                                            <WarningAmberOutlinedIcon color={"primary"} />
                                                                                        </IconButton>
                                                                                    </Stack>
                                                                                </Box>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </List>
                                                                ))}
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: "47%", }}>
                                                <Box sx={{ my: 1, width: "100%", borderRadius: 5, height: "97%", bgcolor: "#F7F7F7" }}>
                                                    <Box sx={{ px: 2, py: 1 }}>
                                                        <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                            Visit Shift Checklist
                                                        </Typography>
                                                    </Box>
                                                    {serviceRequest?.map((value, index) => {
                                                        const checkList = value.service_check_list;
                                                        return (
                                                            <Box key={index} sx={{ px: 2, }}>
                                                                {checkList?.map((v, i) => (
                                                                    <List key={v.attributes.shift_title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                        <ListItem>
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    icon={<RadioButtonUncheckedIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                                    checkedIcon={<CheckCircleIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <ListItemText primaryTypographyProps={{
                                                                                fontFamily: "Roboto-Bold",
                                                                                fontSize: "1.2em"
                                                                            }} primary={v.attributes.shift_title} />
                                                                            {v.collapse ?
                                                                                <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseReqCheckList(index, i, serviceRequest[index].service_check_list, setServiceRequest)} variant='contained' color='primary'>
                                                                                    <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                                </Button> :
                                                                                <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapseReqCheckList(index, i, serviceRequest[index].service_check_list, setServiceRequest)} variant='contained' color='primary'>
                                                                                    <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                                </Button>
                                                                            }
                                                                        </ListItem>
                                                                        <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', mb: 1 }}>
                                                                                <Box sx={{ width: "80%", display: "flex", justifyContent: "center" }}>
                                                                                    <TextField
                                                                                        placeholder='Add a comment'
                                                                                        value={v.attributes.shift_comments}
                                                                                        sx={{ width: "inherit" }}
                                                                                        multiline={true}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </Collapse>
                                                                    </List>
                                                                ))}
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {serviceTab.includes("history") && (
                                        <Box sx={{ display: "flex", justifyContent: "center", my: 2, }}>
                                            <Box sx={{ width: "95%", height: 510, overflowY: "auto", my: 1, borderRadius: 5, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ px: 4, my: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}>
                                                        Service Request History
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ px: 4, mb: 2 }}>
                                                    {serviceHistory?.map((v, i) => {
                                                        return (
                                                            <List key={v?.service_request?.attributes?.charge_band_rate_id?.data?.attributes?.service_title} component={Paper} sx={{ p: 0, my: 1 }}>
                                                                <ListItem>
                                                                    <ListItemText primaryTypographyProps={{
                                                                        fontFamily: "Roboto-Bold",
                                                                        fontSize: "1.2em"
                                                                    }} primary={v?.service_request?.attributes?.charge_band_rate_id?.data?.attributes?.service_title} secondary={
                                                                        <Box>
                                                                            <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                {v.visit_days.attributes.start_date} - {v.visit_days.attributes.end_date}
                                                                            </Typography>
                                                                            {v.collapse &&
                                                                                <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>
                                                                                    Occurred {v.visit_days.attributes.recurring_type}
                                                                                </Typography>
                                                                            }
                                                                        </Box>
                                                                    } />
                                                                    {v.collapse &&
                                                                        <Box sx={{ width: "40%", p: 2 }}>
                                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', }}>
                                                                                <Box sx={{ width: "45%", }}>
                                                                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                        Status: <span style={{ color: "#3A89CC" }}>{v?.service_request?.attributes.status}</span>
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box sx={{ width: "45%", }}>
                                                                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                        Pay Travel: <span style={{ color: "#3A89CC" }}>{v?.service_request?.attributes.pay_travel_km}</span>
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', }}>
                                                                                <Box sx={{ width: "45%", }}>
                                                                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                        Funding Source: <span style={{ color: "#3A89CC" }}>{v?.service_request?.attributes.funding_source}</span>
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box sx={{ width: "45%", }}>
                                                                                    <Typography color="secondary.text3" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                        Billable: <span style={{ color: "#3A89CC" }}>{v?.service_request?.attributes.billable}</span>
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    {v.collapse ?
                                                                        <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, serviceHistory, setServiceHistory)} variant='contained' color='primary'>
                                                                            <ExpandLess sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                        : <Button sx={{ borderRadius: 3, width: 40, minWidth: 40, height: 35 }} onClick={() => handleCollapse(i, serviceHistory, setServiceHistory)} variant='contained' color='primary'>
                                                                            <ExpandMore sx={{ color: "#fff", fontSize: 25 }} />
                                                                        </Button>
                                                                    }
                                                                </ListItem>
                                                                <Collapse in={v.collapse} timeout="auto" unmountOnExit>
                                                                    <Box sx={{ px: 2, width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', mb: 2 }}>
                                                                        <Box sx={{ height: 300, width: "60%", display: "flex", flexDirection: "column", }}>
                                                                            <Box sx={{ my: 1, width: "100%", }}>
                                                                                <Typography color="primary.text" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Visit Shifts
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ width: "95%", px: 6, height: 280, overflowY: "auto" }}>
                                                                                {v?.shifts?.map((vN, iN) => {
                                                                                    return (
                                                                                        <Typography key={iN} color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em", my: 1 }}>
                                                                                            <FiberManualRecordIcon color='#000000' sx={{ fontSize: 12, }} />
                                                                                            <span style={{ paddingLeft: "1%" }}>{vN.attributes.shift_date}</span>
                                                                                            <span style={{ fontFamily: "Roboto-Bold", fontSize: "1.2em" }}> | </span>
                                                                                            <span>{vN.attributes.shift_start.slice(0, 5)} - {vN.attributes.shift_end.slice(0, 5)} </span>
                                                                                        </Typography>
                                                                                    )
                                                                                })}
                                                                            </Box>
                                                                        </Box>
                                                                        <Box sx={{ mx: 5, width: "40%", borderRadius: 5, bgcolor: "#F7F7F7", }}>
                                                                            <Box sx={{ px: 3, my: 2, width: "100%", }}>
                                                                                <Typography color="primary.text" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                                                    Service Request Check List
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box sx={{ px: 4, height: 280, overflowY: "auto" }}>
                                                                                {v?.service_check_list?.map((vN, iN) => {
                                                                                    return (
                                                                                        <ListItem key={iN} sx={{ p: 0 }}>
                                                                                            <ListItemIcon>
                                                                                                <Checkbox
                                                                                                    // checked={vN.attributes.status}
                                                                                                    checked={vN.attributes.status}
                                                                                                    icon={<RadioButtonUncheckedIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                                                    checkedIcon={<CheckCircleIcon color='primary' sx={{ fontSize: 32 }} />}
                                                                                                />
                                                                                            </ListItemIcon>
                                                                                            <ListItemText primaryTypographyProps={{
                                                                                                fontFamily: "Roboto-Bold",
                                                                                                fontSize: "1.2em"
                                                                                            }} primary={vN.attributes.shift_title} secondary={vN.attributes.shift_comments} />
                                                                                        </ListItem>
                                                                                    )
                                                                                })}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Collapse>
                                                            </List>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                            {activeTab.includes("reports") && (
                                <Grid xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", mx: 4, justifyContent: "flex-end" }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Button color={"primary"} variant="contained" onClick={() => setIncidentsTab("incidents")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: incidentsTab.includes("incidents") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Incidents
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setIncidentsTab("complaints")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: incidentsTab.includes("complaints") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Complaints
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setIncidentsTab("feedbacks")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: incidentsTab.includes("feedbacks") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Feedbacks
                                            </Button>
                                        </Stack>
                                    </Box>
                                    {incidentsTab.includes("incidents") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Incident Details
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                        No incident found
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {incidentsTab.includes("complaints") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Complaints Details
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                        No complaints found
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {incidentsTab.includes("feedbacks") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Feedbacks Details
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                        No feedbacks found
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                            {activeTab.includes("claims") && (
                                <Grid xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", mx: 4, justifyContent: "flex-end" }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Button color={"primary"} variant="contained" onClick={() => setClaimsTab("reimbusement")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: claimsTab.includes("reimbusement") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Reimbusement
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setClaimsTab("kilometees")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: claimsTab.includes("kilometees") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Kilometees
                                            </Button>
                                            <Button color={"primary"} variant="contained" onClick={() => setClaimsTab("conflict")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: claimsTab.includes("conflict") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                Conflict
                                            </Button>
                                        </Stack>
                                    </Box>
                                    {claimsTab.includes("reimbusement") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Reimbusement Details
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                        No reimbusement found
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {claimsTab.includes("kilometees") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Kilometees Details
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                        No kilometees found
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {claimsTab.includes("conflict") && (
                                        <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                            <Box sx={{ mx: 4, overflowY: "auto", my: 1, width: "100%", borderRadius: 5, height: 250, bgcolor: "#F7F7F7" }}>
                                                <Box sx={{ p: 3 }}>
                                                    <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                        Conflict Details
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75%" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                                                        No conflict found
                                                    </Typography>
                                                </Box>
                                                {/* list */}
                                                <Box sx={{ p: 3, }}>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Grid>
                            )}
                            {activeTab.includes("meeting") && (
                                <Grid xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", mx: 4, justifyContent: "flex-end" }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Button color={"primary"} variant="contained" onClick={() => setMeetingTab("history")} sx={{ height: 30, boxShadow: "0px 1px 5px 1px lightgrey", fontSize: "1em", bgcolor: "#ffffff", fontFamily: "Roboto-Bold", textTransform: "capitalize", color: "#000000", bgcolor: meetingTab.includes("history") ? "#6ECA35" : "#ffffff", ":hover": { bgcolor: "#6ECA35", } }}>
                                                History
                                            </Button>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", my: 2, }}>
                                        <Box sx={{ mx: 4, my: 1, width: "100%", borderRadius: 5, height: "calc(100vh / 1.8)", bgcolor: "#F7F7F7" }}>
                                            <Box sx={{ px: 3, py: 2 }}>
                                                <Typography color="primary" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>
                                                    Meeting Calendar
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mx: 3, bgcolor: "#fff", borderRadius: 4, height: "calc(100vh / 2.2)", overflowY: "auto" }}>
                                                <Box sx={{ py: 2, width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <Typography color="secondary.text2" sx={{ fontFamily: "Roboto-Medium", fontSize: "1.3em" }}>
                                                        {meetingDate}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                    <Box sx={{ width: "10%", height: "inherit" }}>
                                                        {dayTimesMeet?.map((value, index) => {
                                                            return (
                                                                <Box key={value.date + index} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", px: 3, height: 50 }}>
                                                                    <Typography color="#7D7474" sx={{ fontFamily: "Roboto-Bold", fontSize: index % 2 === 0 ? "1.2em" : "0.9em" }}>
                                                                        {value.time.slice(0, 5)} <span style={{ fontSize: "0.5em" }}>{value.time.slice(5)}</span>
                                                                    </Typography>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>

                                                    <Box sx={{ width: "90%", height: "inherit" }}>
                                                        {dayTimesMeet?.map((value, index) => {
                                                            const matched = value?.meeting?.find((e) => value.time === e.meeting_time)
                                                            if (matched) {
                                                                return (
                                                                    <Box key={value.date + index} sx={{ height: 50, border: "1px solid #3A89CC", borderRadius: 4, width: 250 }}>
                                                                        {index}
                                                                    </Box>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Box key={value.date + index} sx={{ height: 50, }}>
                                                                    </Box>
                                                                )
                                                            }
                                                        })}
                                                        {dayTimesMeet?.map((value, index) => {
                                                            if (value.time === "11:30 AM") {
                                                                return (
                                                                    <Box key={index} sx={{
                                                                        ml: "4%",
                                                                        display: "flex",
                                                                        alignItems: "center"
                                                                    }}>
                                                                        <div style={{ width: 10, height: 10, borderRadius: "100%", backgroundColor: "green" }}></div>
                                                                        <div style={{ width: "calc(60vw)", height: 2, backgroundColor: "green" }}></div>
                                                                    </Box>
                                                                )
                                                            }
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {activeTab.includes("training") && (
                                <Grid xs={12} sm={12} md={12} lg={12} sx={{ my: 2, py: 2, backgroundColor: "#fff", mr: 3, height: "calc(66vh)", border: "1px solid #6ECA35", borderRadius: 4, }}>
                                    <Box sx={{ display: "flex", mx: 4, justifyContent: "flex-end" }}>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid >
    )
}

export default StaffDetailsViewV2;
import { Avatar, Backdrop, Box, Button, Checkbox, Collapse, Fade, CircularProgress, FormControlLabel, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Modal, Paper, Stack, Tab, Tabs, TextField, Typography, Slide, Snackbar, Alert, AlertTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FeatureManagementList, UserManagementList } from "../../../components/pagination_view";
import SearchAndFilters from "../../../components/search_filters";
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import _, { set } from "lodash";
import { useNavigate } from "react-router-dom";
import { GetAllUserDetails, UpdateClientDetail, UpdateStaffDetail } from "../../../services/admin_service";

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const UserManagement = () => {
    const navigate = useNavigate()
    const [style, setStyle] = useState({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        maxWidth: "calc(70%)",
        maxHeight: "calc(90%)",
        minWidth: "calc(60%)",
        boxShadow: 12,
        borderRadius: 1,
        p: 4,
        overflowY: "auto"
    });

    const [UserManagementData, loading_users] = GetAllUserDetails();
    const [apiUpdateStaffDetails, loading_staff, error_staff] = UpdateStaffDetail();
    const [apiUpdateClientDetail, loading_client, error_client] = UpdateClientDetail();
    //  // // // handle menu ==================== ======================================
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const width = () => {
        // modal control
        if (window.screen.width < 900) {
            const copy = { ...style };
            copy.maxWidth = "95%";
            setStyle(copy);
        }

        if (window.screen.width > 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width > 900 && window.screen.width < 1600) {
            return window.screen.width / 3.8
        } else {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [editModal, setEditModal] = useState(false);

    const [modalInformation, setModalInformation] = useState("");

    const [forget, setForget] = useState([
        {
            title: "Email",
            value: "",
            width: screenWidth,
            required: true,
            type: "email",
            multiline: false,
        },
        {
            title: "Description",
            value: "",
            width: screenWidth,
            required: false,
            type: "text",
            multiline: true,
        }
    ]);

    const [writes, setWrites] = useState([
        {
            title: "View",
            value: false,
            width: screenWidth,
            required: false,
            type: "check",
        },
        {
            title: "Read",
            value: false,
            width: screenWidth,
            required: false,
            type: "check",
        },
        {
            title: "Write",
            value: false,
            width: screenWidth,
            required: false,
            type: "check",
        },
        {
            title: "Archive",
            value: false,
            width: screenWidth,
            required: false,
            type: "check",
        },
    ])

    const [subGroup, setSubGroup] = useState([
        {
            title: "Client",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Clients",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Client Onboarding",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                // {
                //     title: "Service Schedule",
                //     selected: false,
                //     nestedOption: _.cloneDeep(writes)
                // },
                // {
                //     title: "Service History",
                //     selected: false,
                //     nestedOption: _.cloneDeep(writes)
                // },
                // {
                //     title: "Client Funding",
                //     selected: false,
                //     nestedOption: _.cloneDeep(writes)
                // },
            ],
        },
        {
            title: "Staff Dashboard",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Staff",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Staff Onboarding",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                // {
                //     title: "Performance review/ Appraisals",
                //     selected: false,
                //     nestedOption: _.cloneDeep(writes)
                // },
                // {
                //     title: "Leave Application",
                //     selected: false,
                //     nestedOption: _.cloneDeep(writes)
                // },
                // {
                //     title: "Staff Warnings",
                //     selected: false,
                //     nestedOption: _.cloneDeep(writes)
                // },
            ],
        },
        {
            title: "My Dashboard",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "My Services",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "My Feedback",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Training / Certification",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "My Conflict Of Interest",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Leave Application",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "My Reimbursements",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "My KM's Claimed",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
            ],
        },
        {
            title: "Incidents / Complaints",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Add an Incident",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Add a Complaint",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Feedback",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
            ],
        },
        {
            title: "Registers",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Client Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Staff Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Medical Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Continues Improvement Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Incident Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Complaint Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Organization Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Feedback Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Conflict Of Interest Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Staff Meeting Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Reimbursements Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Kilometer Register",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
            ],
        },
        {
            title: "Assests",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Home",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Vehicles",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Gadgets",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Other Assets",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },

            ],
        },
        {
            title: "Leads / Referrals",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [],
        },
        {
            title: "Schedule",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Scheduler View",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                // {
                //     title: "Configurations",
                //     selected: false,
                //     nestedOption: _.cloneDeep(writes)
                // },
                {
                    title: "Auto Scheduling",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
            ],
        },
        {
            title: "Audit",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Audit Setup List",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Internal Audit",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
            ],
        },
        {
            title: "Admin",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Organizations",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Add Service Request",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Swap Service Request",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Staff Meetings",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "User Management",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                }
            ],
        },
        {
            title: "Finance",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [
                {
                    title: "Price list",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Adjust Hours",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Payroll Process",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
                {
                    title: "Invoice Ledger",
                    selected: false,
                    nestedOption: _.cloneDeep(writes)
                },
            ],
        },
        {
            title: "Attendence",
            value: "Please Select",
            width: screenWidth,
            required: true,
            type: "drop_down",
            options: [],
        },
    ]);

    const [group, setGroup] = useState([
        {
            title: "Group Name",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
        },
        {
            title: "Description",
            value: "",
            width: screenWidth,
            required: false,
            type: "text",
            multiline: true,
        },
        {
            title: "Module",
            value: "Please Select",
            width: screenWidth,
            required: false,
            type: "drop_down",
            multiline: false,
            options: [
                {
                    title: "Client",
                    selected: false
                },
                {
                    title: "Staff Dashboard",
                    selected: false
                },
                {
                    title: "My Dashboard",
                    selected: false
                },
                {
                    title: "Incidents / Complaints",
                    selected: false
                },
                {
                    title: "Registers",
                    selected: false
                },
                {
                    title: "Assests",
                    selected: false
                },
                {
                    title: "Leads / Referrals",
                    selected: false
                },
                {
                    title: "Schedule",
                    selected: false
                },
                {
                    title: "Audit",
                    selected: false
                },
                {
                    title: "Admin",
                    selected: false
                },
                {
                    title: "Finance",
                    selected: false
                },
                {
                    title: "Attendence",
                    selected: false
                },
            ]
        },

    ]);

    const [cleintManage, setCleintManage] = useState([
        {
            title: "Group Name",
            value: "",
            width: screenWidth,
            required: true,
            type: "text",
            multiline: false,
        },
        {
            title: "Description",
            value: "",
            width: screenWidth,
            required: false,
            type: "text",
            multiline: true,
        },
    ]);

    const [userList, setUserList] = useState([
        {
            title: "Salman Zafar",
            selected: false,
            gender: "Male",
            date_of_birth: "11-05-2000",
            diagnose: "Fever"
        },
        {
            title: "Jolie",
            selected: false,
            gender: "Female",
            date_of_birth: "11-05-2000",
            diagnose: "Fever"
        },
        {
            title: "Scott",
            selected: false,
            gender: "Male",
            date_of_birth: "11-05-2000",
            diagnose: "Fever"
        },
        {
            title: "Stephine",
            selected: false,
            gender: "Female",
            date_of_birth: "11-05-2000",
            diagnose: "Fever"
        },
    ]);

    const headCells = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: false,
            label: 'Id',
        },
        {
            id: 'First Name',
            numeric: false,
            disablePadding: false,
            label: 'First Name',
        },
        {
            id: 'Last Name',
            numeric: false,
            disablePadding: false,
            label: 'Last Name',
        },
        {
            id: 'Email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'Last Login',
            numeric: false,
            disablePadding: false,
            label: 'Last Login',
        },
        {
            id: 'Feature Group',
            numeric: false,
            disablePadding: false,
            label: 'Feature Group',
        },
        {
            id: 'Client Group',
            numeric: false,
            disablePadding: false,
            label: 'Client Group',
        },
        {
            id: 'Enabled / Disabled',
            numeric: false,
            disablePadding: false,
            label: 'Enabled / Disabled',
        },
        {
            id: 'User Type',
            numeric: false,
            disablePadding: false,
            label: 'User Type',
        },
        {
            id: 'Approval Status',
            numeric: false,
            disablePadding: false,
            label: 'Approval Status',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: false,
            label: 'Options',
        }
    ];

    const headCellsFeature = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: false,
            label: 'Id',
        },
        {
            id: 'Group Name',
            numeric: false,
            disablePadding: false,
            label: 'Group Name',
        },
        {
            id: 'Desciption',
            numeric: false,
            disablePadding: false,
            label: 'Desciption',
        },
        {
            id: 'Created By',
            numeric: false,
            disablePadding: false,
            label: 'Created By',
        },
        {
            id: 'Created Date',
            numeric: false,
            disablePadding: false,
            label: 'Created Date',
        },
        {
            id: 'Enabled / Disabled',
            numeric: false,
            disablePadding: false,
            label: 'Enabled / Disabled',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: false,
            label: 'Options',
        },
    ];

    const headCellsClient = [
        {
            id: 'Id',
            numeric: false,
            disablePadding: false,
            label: 'Id',
        },
        {
            id: 'Group Name',
            numeric: false,
            disablePadding: false,
            label: 'Group Name',
        },
        {
            id: 'Desciption',
            numeric: false,
            disablePadding: false,
            label: 'Desciption',
        },
        {
            id: 'Created By',
            numeric: false,
            disablePadding: false,
            label: 'Created By',
        },
        {
            id: 'Created Date',
            numeric: false,
            disablePadding: false,
            label: 'Created Date',
        },
        {
            id: 'Enabled / Disabled',
            numeric: false,
            disablePadding: false,
            label: 'Enabled / Disabled',
        },
        {
            id: 'Options',
            numeric: false,
            disablePadding: false,
            label: 'Options',
        },
    ];

    // const management = (id, first_name, last_name, email, last_login, feature_group, client_group, archive) => {
    //     return {
    //         id, first_name, last_name, email, last_login, feature_group, client_group, archive
    //     }
    // };
    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (UserManagementData) {
            const array = [];
            const staff = UserManagementData.staffDetails.data;
            const client = UserManagementData.clientDetails.data;
            const contact = UserManagementData.contactDetails.data;
            for (const key in staff) {
                const data = staff[key].attributes
                array.push({
                    id: staff[key]?.id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email_info,
                    last_login: data.createdAt,
                    feature_group: "null",
                    client_group: "null",
                    active: data.active,
                    approval: data.approved_status,
                    user_type: data.user_type_id,
                    openMenu: null,
                    menuAnchorEl: null,
                })
            }
            for (const key in client) {
                const data = client[key].attributes;
                const contactInfo = contact?.find((e) => e?.attributes?.client_detail_id?.data?.id === client[key]?.id)
                array.push({
                    id: client[key]?.id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: contactInfo?.attributes?.email,
                    last_login: "Not Applicable",
                    feature_group: "null",
                    client_group: "null",
                    active: data.active,
                    approval: data.approved_status,
                    user_type: data.user_type_id,
                    openMenu: null,
                    menuAnchorEl: null,
                })
            }
            setRows(array);
        }
    }, [UserManagementData])

    const handleUserStatus = (index, user) => {
        const copy = [...rows]
        for (const key in copy) {
            if (copy[key]?.id === index) {
                copy[key].active = !user.active;
                break;
            }
        }
        setRows(copy)
        const object = {
            "id": index,
            "data": {
                "active": user.active
            }
        }
        hitUpdateQueryWithIdentity(user, object, index)
    }

    const handleClickMenu = (dbId, xData) => {
        const copy = [...rows]
        const finds = copy?.find((e) => e.id === dbId)
        setAnchorEl(xData);
        setOpenMenuIndex(finds.id);
    }
    const handleClickMenuClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null);
    };

    const handleMenuOption = (xData, option) => {
        const copy = [...rows]
        if (option === "approval") {
            for (const key in copy) {
                if (copy[key]?.id === xData?.id) {
                    copy[key].approval = !xData.approval;
                    break;
                }
            }
            const object = {
                "id": xData?.id,
                "data": {
                    "approved_status": xData?.approval
                }
            }
            hitUpdateQueryWithIdentity(xData, object, xData?.id)
        }
        setRows(copy)
        handleClickMenuClose()
    }
    const hitUpdateQueryWithIdentity = async (user, object, index) => {
        try {
            const copy = [...rows];
            if (user?.user_type?.toLowerCase() === "staff") {
                const response = await apiUpdateStaffDetails({
                    variables: object
                })
                if (response?.data) {
                    // console.log(response?.data)
                    copy[index].active = response?.data?.updateStaffDetail?.data?.attributes?.active;
                    copy[index].approval = response?.data?.updateStaffDetail?.data?.attributes?.approved_status;
                    handleSnacksApper("User Management", "User has been updated, id is: " + response?.data?.updateStaffDetail?.data?.id, "success")
                }
            }
            if (user?.user_type?.toLowerCase() === "client") {
                const response = await apiUpdateClientDetail({
                    variables: object
                })
                if (response?.data) {
                    // console.log(response?.data)
                    copy[index].active = response?.data?.updateStaffDetail?.data?.attributes?.active;
                    copy[index].approval = response?.data?.updateStaffDetail?.data?.attributes?.approved_status;
                    handleSnacksApper("User Management", "User has been updated, id is: " + response?.data?.updateStaffDetail?.data?.id, "success")
                }
            }
            setRows(copy)
        } catch (error) {
            let message = "";
            if (error_staff || error_client) {
                const errors = error_client || error_staff;
                if (errors?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (errors?.graphQLErrors) {
                    message = errors.graphQLErrors[0]?.message
                }
                handleSnacksApper("User Management", message, "error")
            }
        }
    }

    const [rowsList, setRowsList] = useState([]);

    useEffect(() => {
        const copy = [...rowsList];
        copy.push({
            id: 1,
            group_name: "Admin",
            group_description: "Admin writes",
            email: "admin_@gmail.com",
            created_date: "23-03-2024T18:00",
            archive: true,
            list_type: "feature"
        });
        copy.push({
            id: 1,
            group_name: "Staff 1",
            group_description: "Staff writes",
            email: "staff_@gmail.com",
            created_date: "23-03-2024T18:00",
            archive: true,
            list_type: "feature"
        });
        setRowsList(copy);
    }, []);

    const [rowsLists, setRowsLists] = useState([]);

    useEffect(() => {
        const copy = [...rowsLists];
        copy.push({
            id: 1,
            group_name: "Epileptic",
            group_description: "Care Worker support",
            email: "care_worker@gmail.com",
            created_date: "23-03-2024T18:00",
            archive: true,
            list_type: "client"
        });
        setRowsLists(copy);
    }, []);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", marginTop: { xs: "15%", lg: "1%", md: "1%" } }}>
            <Grid item={true} xs={11.5} sm={11.5} md={11} lg={11} sx={{ display: "flex", flexDirection: { xs: "column", md: "row", lg: "row" } }}>
                <Grid item={true} xs={12} sm={12} md={8} lg={8} sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start", lg: "flex-start" }, alignItems: "center" }}>
                    <Stack direction={window.screen.width < 900 ? "column" : "row"} spacing={2}>
                        <Button onClick={() => {
                            navigate("/client-dashboard", {
                                state: {
                                    activeOption: 1
                                }
                            })
                        }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Create Client
                        </Button>
                        <Button onClick={() => {
                            navigate("/staff-dashboard", {
                                state: {
                                    activeOption: 1
                                }
                            })
                        }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Create Staff
                        </Button>
                        <Button onClick={() => {
                            setEditModal(true);
                            setModalInformation("Feature Group Management");
                        }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Feature Group Management
                        </Button>
                        <Button onClick={() => {
                            setEditModal(true);
                            setModalInformation("Client Group Management");
                        }} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Client Group Management
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={4} lg={4} >
                    <SearchAndFilters groupBy={false} favorite={false} inputWidth={10} />
                </Grid>
            </Grid>
            <Grid item={true} xs={11.5} sm={11.5} md={11} lg={11} sx={{ p: 1, }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab color="secondary.text3" sx={{ fontFamily: "Roboto-Medium" }} label="User Management List" {...a11yProps(0)} />
                            <Tab color="secondary.text3" sx={{ fontFamily: "Roboto-Medium" }} label="Feature Group Management List" {...a11yProps(1)} />
                            <Tab color="secondary.text3" sx={{ fontFamily: "Roboto-Medium" }} label="Client Group Management List" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        {loading_users ? (
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress color="secondary" size={50} />
                            </Box>
                        ) : (
                            <>
                                {rows.length > 0 && <UserManagementList handleClickMenuClose={handleClickMenuClose} anchorEls={anchorEl} openMenuIndex={openMenuIndex} handleClickMenu={handleClickMenu} handleMenuOption={handleMenuOption} rows={rows} handleUserStatus={handleUserStatus} headCells={headCells} />}
                            </>
                        )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {rowsList.length > 0 && <FeatureManagementList rows={rowsList} headCells={headCellsFeature} />}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        {rowsLists.length > 0 && <FeatureManagementList rows={rowsLists} headCells={headCellsClient} />}
                    </CustomTabPanel>
                </Box>
            </Grid>
            {/* -------------------------------------------------------------------------------- */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={editModal}
                onClose={() => setEditModal(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={editModal}>
                    <Box sx={style}>
                        <Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Roboto-Bold", textTransform: "capitalize" }}>
                                {modalInformation}
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            {modalInformation.includes("Forget") && forget.map((v, i) => {
                                return (
                                    <TextField
                                        label={v.title}
                                        required={v.required}
                                        type={v.type}
                                        key={i}
                                        sx={{ m: 1, width: v.width }}
                                        value={v.value}
                                        onChange={(e) => {
                                            const copy = [...forget]
                                            copy[i].value = e.target.value;
                                            setForget(copy)
                                        }}
                                    />
                                )
                            })}
                            {modalInformation.includes("Feature") && group.map((v, i) => {
                                if (v.type === "text") {
                                    return (
                                        <TextField
                                            label={v.title}
                                            required={v.required}
                                            multiline={v.multiline}
                                            type={v.type}
                                            key={i}
                                            sx={{ m: 1, width: v.width }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...group]
                                                copy[i].value = e.target.value;
                                                setGroup(copy)
                                            }}
                                        />
                                    )
                                }
                                if (v.type === "drop_down") {
                                    return (
                                        <div key={i}>
                                            {v?.options?.map((option) => (
                                                <List
                                                    sx={{ mx: 1, my: 1, py: 0, width: '100%', maxWidth: "90%", bgcolor: 'background.paper' }}
                                                    aria-labelledby="nested-list-subheader"
                                                    key={option.title}
                                                    component={Paper}
                                                    elevation={1}
                                                >
                                                    <ListItemButton onClick={() => {
                                                        const copy = [...group];
                                                        copy[2].value = option.title;
                                                        for (const key in copy[i].options) {
                                                            const abc = copy[i].options[key];
                                                            if (abc.title === option.title) {
                                                                abc.selected = !abc.selected;
                                                            } else {
                                                                abc.selected = false;
                                                            }
                                                        }
                                                        setGroup(copy);
                                                    }}>
                                                        <ListItemText primary={option.title} primaryTypographyProps={{ fontFamily: "Roboto-Medium", }} />
                                                        {option.selected ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemButton>
                                                    {group[2].value && subGroup.map((v, index) => {
                                                        if (group[2].value === v.title) {
                                                            return (
                                                                <Collapse in={option.selected} timeout="auto" unmountOnExit>
                                                                    {v?.options?.map((option1, index1) => (
                                                                        <List key={option1.title} component="div" disablePadding>
                                                                            <ListItemButton sx={{ pl: 4 }}>
                                                                                <ListItemText primary={option1.title} />
                                                                                {option1?.nestedOption?.map((v, i) => {
                                                                                    return (
                                                                                        <div style={{ display: "flex", flexDirection: "row", marginLeft: "1%" }}>
                                                                                            <FormControlLabel control={
                                                                                                <Checkbox checked={v.value} onClick={(e) => {
                                                                                                    const copy = [...subGroup];
                                                                                                    copy[index].options[index1].nestedOption[i].value = e.target.checked;
                                                                                                    setWrites(copy);
                                                                                                }} />} label={v.title} />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </ListItemButton>
                                                                        </List>
                                                                    ))}
                                                                </Collapse>
                                                            )
                                                        }
                                                    })}
                                                </List>
                                            ))}
                                        </div>
                                    )
                                }
                            })}
                            {modalInformation.includes("Client") && cleintManage.map((v, i) => {
                                if (v.type !== "drop_down") {
                                    return (
                                        <TextField
                                            label={v.title}
                                            required={v.required}
                                            multiline={v.multiline}
                                            type={v.type}
                                            key={i}
                                            sx={{ m: 1, width: v.width }}
                                            value={v.value}
                                            onChange={(e) => {
                                                const copy = [...cleintManage]
                                                copy[i].value = e.target.value;
                                                setCleintManage(copy)
                                            }}
                                        />
                                    )
                                }
                            })}
                            {modalInformation.includes("Client") && userList.map((v, i) => {
                                return (
                                    <List
                                        sx={{ mx: 1, py: 0, my: 1, width: '100%', maxWidth: '90%', bgcolor: 'background.paper' }}
                                        aria-labelledby="nested-list-subheader"
                                        elevation={1}
                                        component={Paper}
                                        key={i}
                                    >
                                        <ListItem disablePadding
                                            secondaryAction={
                                                <Typography>Diagnosis: {v.diagnose}</Typography>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Checkbox checked={v.selected} onClick={(e) => {
                                                    const copy = [...userList];
                                                    copy[i].selected = e.target.checked;
                                                    setUserList(copy);
                                                }} />
                                            </ListItemAvatar>
                                            <ListItemText primary={v.title} secondary={v.date_of_birth} />
                                        </ListItem>
                                    </List>
                                )
                            })}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 3 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained">
                                    Save
                                </Button>
                                <Button variant="contained" onClick={() => setEditModal(false)}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>

            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Grid >
    )
}
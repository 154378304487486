import { gql } from "@apollo/client";

export const loginQuery = gql`
    mutation loginQuery($input: UsersPermissionsLoginInput) {
        login(input: $input) {
            jwt
            user {
                id
                username
            }
        }
    }
`;

export const createNewUserForLogin = gql`
    mutation CreateUser($input: UsersPermissionsRegisterInput!) {
        register(input: $input) {
            jwt
            user {
                id
                username
                email
            }
        }
    }
`;

export const uploadNewDocument = gql`
    mutation Upload($file: Upload!) {
        upload(file: $file) {
            data {
                id
                attributes {
                    url
                }
            }
        }
    }
`;

//  // // // =======================================Staff mutations.............................................
export const createNewStaff = gql`
    mutation createStaffDetail(
        $first_name: String,
        $last_name: String,
        $email_info: String,
        $date_of_birth: Date,
        $ndis_clearance_number: String,
        $registration_expiry_date: Date,  
        $do_you_have_medical_card: Boolean,
        $medicare_number: Long,
        $police_check: String,
        $do_you_have_driving_license: Boolean,
        $driving_license: Long,
        $user_type_id: String,
        $language_spoken: String,
        $religion: String,
        $age: String
    ){
        createStaffDetail(data:{
            first_name: $first_name,
            last_name: $last_name,
          	email_info: $email_info,
            date_of_birth: $date_of_birth,
            ndis_clearance_number: $ndis_clearance_number,
            registration_expiry_date: $registration_expiry_date,
            do_you_have_medical_card: $do_you_have_medical_card,  
            medicare_number: $medicare_number,
            police_check: $police_check,
            do_you_have_driving_license: $do_you_have_driving_license,
            driving_license: $driving_license,
          	user_type_id: $user_type_id,
            language_spoken: $language_spoken,
            religion: $religion,
            age: $age
        }){
            data{
                id
                attributes{
                    first_name
                    last_name
                    email_info
                }
            }
        }
    }
`;

export const createContactDetailForStaff = gql`
    mutation createContactDetail(
        $email: String,
        $home_phone: String,
        $mobile_phone: String,
        $work_phone: String,
        $address_1: String,
        $address_2: String,
        $post_code: String,
        $staff_detail_id: ID
    ){
        createContactDetail(data:{
            email: $email,
            home_phone: $home_phone,
            mobile_phone: $mobile_phone,
            work_phone: $work_phone,
            address_1: $address_1,
            address_2: $address_2,
            post_code: $post_code,
            staff_detail_id: $staff_detail_id
        }){
            data{
                id
                attributes{
                    email
                }
            }
        } 
    }
`;

export const createEmergencyContactDetailForStaff = gql`
    mutation createEmergencyContactDetail(
        $emergency_first_name: String,
        $emergency_last_name: String,
        $relationship: String,
        $email: String,
        $home_phone: String,
        $mobile_phone: String,
        $address_1: String,
        $address_2: String,
        $staff_detail_id: ID
    ){
        createEmergencyContactDetail(data:{
            emergency_first_name: $emergency_first_name,
            emergency_last_name: $emergency_last_name,
            relationship: $relationship,
            email: $email,
            home_phone: $home_phone,
            mobile_phone: $mobile_phone,
            address_1: $address_1,
            address_2: $address_2,
            staff_detail_id: $staff_detail_id
        }){
            data{
                id
                attributes{
                    emergency_first_name
                }
            }
        } 
    }
`;

export const createWorkingDay = gql`
    mutation createWorkingDay(
        $select_day: ENUM_WORKINGDAY_SELECT_DAY,
        $staff_detail_id: ID,
        $day_start_time: Time,
        $day_end_time: Time,
        $whole_day: Boolean,
        $unavailable: Boolean,
    ){
        createWorkingDay(data:{
            select_day: $select_day,
            staff_detail_id: $staff_detail_id,
            day_start_time: $day_start_time,
            day_end_time: $day_end_time,
            whole_day: $whole_day,
            unavailable: $unavailable,
        }){
            data{
                id
                attributes{
                    select_day
                }
            }
        } 
    }
`;

export const createDocumentsProfessionalList = gql`
    mutation createDocumentsProfessionalList(
        $module: String,
        $category: String,
        $type:String,
        $document_name: String,
        $staff_details_id: ID,
        $expiry_date: Date,
        $active: Boolean,
        $documents_description: String,
        $document_link: ID
    ){
        createDocumentsProfessionalList(data:{
            module: $module,
            category: $category,
            type: $type,
            document_name: $document_name,
            staff_details_id: $staff_details_id,
            expiry_date: $expiry_date,
            active: $active,
            documents_description: $documents_description,
            document_link: $document_link
        }){
            data{
                id
                attributes{
                    document_link{
                        data{
                            id
                        }
                    }
                }
            }
        } 
    }
`;

export const createStaffOthersInformation = gql`
    mutation createStaffOthersInformation(
        $staff_details_id: ID,
        $staff_question_answer_refs: [ID]
    ){
        createStaffOthersInformation(data:{
            staff_details_id: $staff_details_id,
          	staff_question_answer_refs: $staff_question_answer_refs
        }){
            data{
                id
                attributes{
                    staff_question_answer_refs{
                        data{
                            id
                            attributes{
                                answer_type{
                                    ... on ComponentAnswersSingleAnswer{
                                        drop_down_option_lovs{
                                            data{
                                                id
                                                attributes{
                                                    title
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
`;

export const createStaffPaymentDetail = gql`
    mutation createStaffPaymentDetail(
        $account_name: String,
        $account_number: String,
        $bsb_number: String,
        $fund_name: String,
        $member_number: String,
        $tax_file_number: String,
        $reason_notes: String,
        $staff_detail_id: ID,
  		$tax_free_threshold: Boolean
    ){
		createStaffPaymentDetail(data:{
            account_name: $account_name,
            account_number: $account_number,
            bsb_number: $bsb_number,
            fund_name: $fund_name,
            member_number: $member_number,
            tax_file_number: $tax_file_number,
            reason_notes: $reason_notes,
            staff_detail_id: $staff_detail_id,
      		tax_free_threshold: $tax_free_threshold
        }){
            data{
                id
                attributes{
                    account_name
                    account_number
                    bsb_number
                    fund_name
                    member_number
                    tax_file_number
                    reason_notes
                  	tax_free_threshold
                    staff_detail_id{
                        data{
                            id
                        }
                    }
                }
            }
        } 
    }
`;

export const createStaffAnswerLovsReference = gql`
    mutation createStaffAnswerLovsReference(
        $staff_detail_id: ID,
        $module: String,
        $category: String,
        $input_name: String,
        $selected_option_value: String,
    ){
        createStaffAnswerLovsReference(data:{
            staff_detail_id: $staff_detail_id,
            module: $module,
            category: $category,
            input_name: $input_name,
            selected_option_value: $selected_option_value
        }){
            data{
                id
                attributes{
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const createStaffQuestionSingleAnswerRef = gql`
    mutation createStaffQuestionAnswerRef(
        $staff_detail_id: ID,
        $module: String,
        $category: String,
        $question_title: String,
        $answer_type: [StaffQuestionAnswerRefAnswerTypeDynamicZoneInput!]
    ){
        createStaffQuestionAnswerRef(data:{
            staff_detail_id: $staff_detail_id,
            module: $module,
            category: $category,
            question_title: $question_title,
            answer_type: $answer_type
        }){
            data{
                id
                attributes{
                    staff_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

// {
//     "answer_type": [
//         {
//             "__typename": "ComponentAnswersSingleAnswer",
//             "__component": "answers.single-answer",
//             "active": true,
//             "drop_down_option_lovs": 75
//         }
//     ]
// }


//  // // // =======================================Client mutations.............................................
export const createClientDetail = gql`
    mutation createClientDetail(
        $first_name: String,
        $last_name: String,
        $date_of_birth: Date,
        $age: Long,
        $ndis_number: String,
        $primary_diagnosis: String,
        $rankings: Int,
        $medicare_number: String,
        $medicare_expiry_date: Date,
        $centerlink_dva_number: String,
        $active: Boolean
    ){
        createClientDetail(data:{
            first_name: $first_name,
            last_name: $last_name,
            date_of_birth: $date_of_birth,
            age: $age,
            ndis_number: $ndis_number,
            primary_diagnosis: $primary_diagnosis,
            rankings: $rankings,
            medicare_number: $medicare_number,
            medicare_expiry_date: $medicare_expiry_date,
            centerlink_dva_number: $centerlink_dva_number,
            active: $active
        }){
            data{
                id
                attributes{
                    first_name
                }
            }
        }
    }
`;

export const createContactDetailForClient = gql`
    mutation createContactDetail(
        $email: String,
        $home_phone: String,
        $mobile_phone: String,
        $work_phone: String,
        $address_1: String,
        $address_2: String,
        $post_code: String,
        $client_detail_id: ID
    ){
        createContactDetail(data:{
            email: $email,
            home_phone: $home_phone,
            mobile_phone: $mobile_phone,
            work_phone: $work_phone,
            address_1: $address_1,
            address_2: $address_2,
            post_code: $post_code,
            client_detail_id: $client_detail_id
        }){
            data{
                id
                attributes{
                    email
                }
            }
        } 
    }
`;

export const createEmergencyContactDetailForClient = gql`
    mutation createEmergencyContactDetail(
        $emergency_first_name: String,
        $emergency_last_name: String,
        $relationship: String,
        $email: String,
        $home_phone: String,
        $mobile_phone: String,
        $address_1: String,
        $address_2: String,
        $client_detail_id: ID
    ){
        createEmergencyContactDetail(data:{
            emergency_first_name: $emergency_first_name,
            emergency_last_name: $emergency_last_name,
            relationship: $relationship,
            email: $email,
            home_phone: $home_phone,
            mobile_phone: $mobile_phone,
            address_1: $address_1,
            address_2: $address_2,
            client_detail_id: $client_detail_id
        }){
            data{
                id
                attributes{
                    emergency_first_name
                }
            }
        } 
    }
`;

export const createCenterLinkHealthcare = gql`
    mutation createCenterLinkHealthcare(
        $medicare_number: String,
        $medicare_expiry_date: Date,
        $centerlink_dva_number: String,
        $client_id: ID,
    ){
        createCenterLinkHealthcare(data:{
            medicare_number: $medicare_number,
            medicare_expiry_date: $medicare_expiry_date,
            centerlink_dva_number: $centerlink_dva_number,
            client_id: $client_id,
        }){
            data{
                id
                attributes{
                    medicare_number
                    client_id{
                        data{
                            id
                        }
                    }
                }
            }
        } 
    }
`;

export const createAdministrativeNote = gql`
    mutation createAdministrativeNote(
        $general_notes: String,
        $client_detail_id: ID
    ){
        createAdministrativeNote(data:{
            general_notes: $general_notes,
            client_detail_id: $client_detail_id
        }){
            data{
                id
                attributes{
                    general_notes
                    client_detail_id{
                        data{
                            id
                        }
                    }
                }
            }
        } 
    }
`;

export const createServiceInformation = gql`
    mutation createServiceInformation(
        $notes: String,
        $client_detail_id: ID
    ){
        createServiceInformation(data:{
            notes: $notes,
            client_detail_id: $client_detail_id
        }){
            data{
                id
                attributes{
                    notes
                }
            }
        }
    }
`;

export const createPreferenceForStaffWorker = gql`
    mutation createPreferenceForStaffWorker(
        $client_detail_id: ID,
        $active: Boolean,
        $age: Long,
        $gender: String,
        $language_spoken: String,
        $religion: String
    ){
        createPreferenceForStaffWorker(data:{
            client_detail_id: $client_detail_id,
            active: $active,
            age: $age,
            gender: $gender,
            language_spoken: $language_spoken,
            religion: $religion
        }){
            data{
                id
                attributes{
                    active
                    age
                }
            }
        }
    }
`;

export const createClientNote = gql`
    mutation createClientNote(
        $note_title: String,
        $comments: String,
        $client_detail_id: ID,
    ){
        createClientNote(data:{
            note_title: $note_title,
            comments: $comments,
            client_detail_id: $client_detail_id,
        }){
            data{
                id
                attributes{
                    note_title
                    comments
                }
            }
        } 
    }
`;

export const createClientGoal = gql`
    mutation createClientGoal(
        $goal: String,
        $start_date: Date,
        $end_date: Date,
        $client_detail_id: ID
    ){
        createClientGoal(data:{
            goal: $goal,
            start_date: $start_date,
            end_date: $end_date,
            client_detail_id: $client_detail_id,
        }){
            data{
                id
                attributes{
                    goal
                    goal_status
                    start_date
                    end_date
                }
            }   
        } 
    }
`;

export const createCheckListAlertsNotification = gql`
    mutation createCheckListAlertsNotification(
        $check_list_title: String,
        $check_list_comments: String,
		$notification_time: Time
    ){
        createCheckListAlertsNotification(data:{
            check_list_title: $check_list_title,
            check_list_comments: $check_list_comments,
            notification_time: $notification_time
        }){
            data{
                id
                attributes{
                    check_list_title
                    check_list_comments
                    notification_time
                }
            }
        } 
    }
`;

export const createPlanReview = gql`
    mutation createPlanReview(
        $created_by_plan: String,
        $plan_created_on: Date,
        $client_detail_id: ID
    ){
        createPlanReview(data:{
            created_by_plan: $created_by_plan,
            plan_created_on: $plan_created_on,
            client_detail_id: $client_detail_id,
        }){
            data{
                id
                attributes{
                    created_by_plan
                }
            }
        }
    }
`;

export const createClientAnswerLovsReference = gql`
    mutation createClientAnswerLovsReference(
        $client_detail_id: ID,
        $module: String,
        $category: String,
        $input_name: String,
        $selected_option_value: String,
        ){
            createClientAnswerLovsReference(data:{
                client_detail_id: $client_detail_id,
                module: $module,
                category: $category,
                input_name: $input_name,
                selected_option_value: $selected_option_value
            }){
                data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                            attributes{
                                first_name
                            }            
                        }
                    }
                }
            }
        }
    }
`;

export const createMedicationsForClient = gql`
    mutation createMedicalInformation(
        $medication_title: String,
        $comments: String,
        $dosage: String,
        $expiry_date: Date,
        $notification_time: Time,
        $client_detail_id: ID
    ){
        createMedicalInformation(data:{
            medication_title: $medication_title,
            comments: $comments,
            dosage: $dosage,
            expiry_date: $expiry_date,
            notification_time: $notification_time,
            client_detail_id: $client_detail_id
        }){
            data{
                id
                attributes{
                    medication_title
                }
            }
        }
    }
`;

export const createOnboardMedicalHistoryDetail = gql`
    mutation createOnboardMedicalHistoryDetail(
        $client_detail_id: ID,
        $question_title: String,
        $answer_value: String
    ){
        createOnboardMedicalHistoryDetail(data:{
            client_detail_id: $client_detail_id,
            question_title: $question_title,
            answer_value: $answer_value
        }){
            data{
                id
                attributes{
                    question_title
                }
            }
        }
    }
`;

export const createReferringDoctorInformation = gql`
    mutation createReferringDoctorInformation(
        $client_detail_id: ID,
        $medical_record_number: String,
        $referring_doctor: String,
        $referring_doctor_address: String,
        $referring_doctor_phone: String
    ){
        createReferringDoctorInformation(data:{
            client_detail_id: $client_detail_id,
            medical_record_number: $medical_record_number,
            referring_doctor: $referring_doctor,
            referring_doctor_address: $referring_doctor_address,
            referring_doctor_phone: $referring_doctor_phone
        }){
            data{
                id
                attributes{
                    medical_record_number
                }
            }
        }
    }
`;

export const createAdmissionInformation = gql`
    mutation createAdmissionInformation(
        $client_detail_id: ID,
        $principal_diagnosis: String,
        $provisional_diagnosis: String,
        $identified_major_procedure: String
    ){
        createAdmissionInformation(data:{
            client_detail_id: $client_detail_id,
            principal_diagnosis: $principal_diagnosis,
            provisional_diagnosis: $provisional_diagnosis,
            identified_major_procedure: $identified_major_procedure
        }){
            data{
                id
                attributes{
                    principal_diagnosis
                }
            }
        }
    }
`;

export const createClientQuestionAnswerRef = gql`
    mutation createClientQuestionAnswerRef(
        $client_detail_id: ID,
        $module: String,
        $category: String,
        $question_title: String,
        $answer_type: [ClientQuestionAnswerRefAnswerTypeDynamicZoneInput!],
    ){
        createClientQuestionAnswerRef(data:{
            client_detail_id: $client_detail_id,
            module: $module,
            category: $category,
            question_title: $question_title,
            answer_type: $answer_type,
        }){
            data{
                id
                attributes{
                    question_title
                }
            }
        }
    }
`;

// // // // ============================================================================================================================
export const createDefaultLovsDropDown = gql`
    mutation createDropDownOption(
        $title: String,
        $active: Boolean
    ){
        createDropDownOption(data:{
            title: $title,
            active: $active
        }){
            data{
                id
                attributes{
                    title
                }
            }
        }
    }
`;

export const createDesignFrontendInputName = gql`
    mutation createDesignFrontendInputName(
        $input_name: String,
    ){
        createDesignFrontendInputName(data:{
            input_name:   $input_name,
        }){
            data{
                id
                attributes{
                    input_name
                }
            }
        }
    }
`;

export const createQuestionList = gql`
    mutation createQuestionList(
        $question: String,
        $active: Boolean
    ){
        createQuestionList(data:{
            question: $question,
            active: $active
        }){
            data{
                id
                attributes{
                    question
                    active
                }
            }
        }
    }
`;

// // // // // // // ==========================================================================================================

export const createNewDocument = gql`
    mutation createAuditDocument(
        $audit_module: ID,
        $audit_category: ID,
        $audit_type: ID,
        $document_description: String,
        $audit_document_name: ID,
        $active: Boolean,
        $company_id: ID,
        $reminder: Boolean
    ){
        createAuditDocument(data:{
            audit_module: $audit_module,
            audit_category: $audit_category,
            audit_type: $audit_type,
            document_description: $document_description,
            audit_document_name: $audit_document_name,
            active: $active,
            company_id: $company_id,
            reminder: $reminder
        }){
            data{
                id
                attributes{
                    audit_module{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                    audit_document_name{
                        data{
                            id
                            attributes{
                                title
                                active
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const createAuditModule = gql`
    mutation createAuditModule(
        $title: String,
        $active: Boolean,
        $description: String,
        $company_id: ID
    ){
        createAuditModule(data:{
            title: $title,
            active: $active,
            description: $description,
            company_id: $company_id
        }){
            data{
                id
                attributes{
                    title
                    active
                }
            }
        }
    }
`;

export const createAuditCategory = gql`
    mutation createAuditCategory(
        $title: String,
        $active: Boolean,
        $description: String,
        $company_id: ID
    ){
        createAuditCategory(data:{
            title: $title,
            active: $active,
            description: $description,
            company_id: $company_id
        }){
            data{
                id
                attributes{
                    title
                    active
                }
            }
        }
    }
`;

export const createAuditType = gql`
    mutation createAuditType(
        $title: String,
        $active: Boolean,
        $description: String,
        $company_id: ID
    ){
        createAuditType(data:{
            title: $title,
            active: $active,
            description: $description,
            company_id: $company_id
        }){
            data{
                id
                attributes{
                    title
                    active
                }
            }
        }
    }
`;

export const createAuditDocumentName = gql`
    mutation createAuditDocumentName(
        $title: String,
        $active: Boolean,
        $description: String,
        $company_id: ID,
        $type: String,
    ){
        createAuditDocumentName(data:{
            title: $title,
            active: $active,
            description: $description,
            company_id: $company_id,
            type: $type
        }){
            data{
                id
                attributes{
                    title
                    active
                    description
                    type
                }
            }
        }
    }
`;

// /// /// // // // ==================================== Price List =================================================
export const createPriceList = gql`
    mutation createPriceList(
        $code: String,
        $service_name: String,
        $rate_type: ENUM_PRICELIST_RATE_TYPE,
        $rate: Long,
        $afternoon_rate: Long,
        $night_rate: Long,
        $saturday_rate: Long,
        $sunday_rate: Long,
        $public_holiday_rate: Long
    ){
        createPriceList(data:{
            code: $code,
            service_name: $service_name,
            Rate_Type: $rate_type,
            rate: $rate,
            afternoon_rate: $afternoon_rate,
            night_rate: $night_rate,
            saturday_rate: $saturday_rate,
            sunday_rate: $sunday_rate,
            public_holiday_rate: $public_holiday_rate
        }){
            data{
                id
                attributes{
                    service_name
                }
            }
        }
    }
`;
// // // // // // =================================== Organization =============================================
export const createOrganization = gql`
    mutation createOrgnization(
        $registration_number: String,
        $company_name: String,
        $email: String,
        $phone: String,
        $website_url: String,
        $abn: String,
        $description: String,
        $primary_invoice: String,
        $secondary_invoice: String,
        $primary_schedule_email: String,
        $secondary_schedule_email: String,
        $notes: String
    ){
        createOrgnization(data:{
            registration_number: $registration_number,
            company_name: $company_name,
            email: $email,
            phone: $phone,
            website_url: $website_url,
            abn: $abn,
            description: $description,
            primary_invoice: $primary_invoice,
            secondary_invoice: $secondary_invoice,
            primary_schedule_email: $primary_schedule_email,
            secondary_schedule_email: $secondary_schedule_email,
            notes: $notes
        }){
            data{
                id
                attributes{
                    company_name
                }
            }
        }
    }
`;

// // /// // =============================update data ====================================================

export const updateStaffDetail = gql`
    mutation updateStaffDetail($id: ID!, $data: StaffDetailInput!){
        updateStaffDetail(id:$id, data: $data){
            data{
                id
                attributes{
                    first_name
                    active
                    approved_status
                    last_name
                    ndis_clearance_number
                    email_info
                }
            }
        }
    }
`;

export const updateClientDetail = gql`
    mutation updateClientDetail($id: ID!, $data: ClientDetailInput!){
        updateClientDetail(id:$id, data: $data){
            data{
                id
                attributes{
                    first_name
                    active
                    approved_status
                }
            }
        }
    }
`;

// // // // // ===========================Service=================================================================

export const createServiceRequest = gql`
    mutation createServiceRequest(
        $company_id: ID,
        $client_detail_id: ID,
        $charge_band_rate_id: ID,
        $funding_source: String,
        $billable: String,
        $remove_plan_managers: String,
        $pay_travel_km: String
    ){
        createServiceRequest(data:{
            company_id: $company_id,
            client_detail_id: $client_detail_id,
            charge_band_rate_id: $charge_band_rate_id,
            funding_source: $funding_source,
            billable: $billable,
            remove_plan_managers: $remove_plan_managers,
            pay_travel_km: $pay_travel_km
        }){
            data{
                id
                attributes{
                    client_detail_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const createServiceShift = gql`
    mutation createServiceShift(
        $shift_start: Time,
        $shift_end: Time,
        $visit_day_id: ID,
        $total_shift_hours: Int,
        $shift_start_flag: ENUM_SERVICESHIFT_SHIFT_START_FLAG,
        $shift_completed:Boolean,
        $shift_comments: String,
        $day_time_hours: Int,
        $evening_hours: Int,
        $night_hours: Int,
        $shift_end_flag: ENUM_SERVICESHIFT_SHIFT_END_FLAG,
  		$sleepover_hours: Int,
        $saturday_hours: Int,
        $sunday_hours: Int,
        $holiday_hours: Int,
        $shift_date: Date,
        $staff_detail_id: ID
    ){
        createServiceShift(data:{
            shift_start: $shift_start,
            shift_end: $shift_end,
            visit_day_id: $visit_day_id,
            total_shift_hours: $total_shift_hours,
            shift_start_flag: $shift_start_flag,
            shift_completed: $shift_completed,
            shift_comments: $shift_comments,
            day_time_hours: $day_time_hours,
            evening_hours: $evening_hours,
            night_hours: $night_hours,
            shift_end_flag: $shift_end_flag,
            sleepover_hours: $sleepover_hours,
            saturday_hours: $saturday_hours,
            sunday_hours: $sunday_hours,
            holiday_hours: $holiday_hours,
            shift_date: $shift_date,
            staff_detail_id: $staff_detail_id
        }){
            data{
                id
                attributes{
                    shift_start_flag
                    shift_end_flag
                    day_time_hours
                    evening_hours
                    night_hours
                    visit_day_id{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const createVisitDay = gql`
    mutation createVisitDay(
        $recurring_type: String,
        $start_time: Time,
        $end_time: Time,
        $start_date: Date,
        $end_date: Date,
        $week_days: JSON,
        $selected_days:JSON,
        $x_days: String,
        $service_request_id: ID
    ){
        createVisitDay(data:{
            recurring_type: $recurring_type,
            start_time: $start_time,
            end_time: $end_time,
            start_date: $start_date,
            end_date: $end_date,
            week_days: $week_days,
            selected_days: $selected_days,
            x_days: $x_days,
            service_request_id: $service_request_id
        }){
            data{
                id
                attributes{
                    recurring_type
                }
            }
        }
    }
`;

export const createVisitShiftCheckList = gql`
    mutation createVisitShiftCheckList(
        $shift_title: String,
        $shift_comments: String,
        $client_detail_id: ID,
        $service_request_id: ID
    ){
        createVisitShiftCheckList(data:{
            shift_title: $shift_title,
            shift_comments: $shift_comments,
            client_detail_id: $client_detail_id,
            service_request_id: $service_request_id,
        }){
            data{
                id
            }
        }
    }
`;

export const updateReimbursement = gql`
    mutation updateReimbursement($id:ID!, $data: ReimbursementInput! ){
        updateReimbursement(id: $id, data: $data){
            data{
                id
                attributes{
                    approved_status
                    rejected_status
                }
            }
        }
    }
`;

export const updateKilometersClaim = gql`
    mutation updateKilometersClaim($id:ID!, $data: KilometersClaimInput! ){
        updateKilometersClaim(id: $id, data: $data){
            data{
                id
                attributes{
                    approved_status
                    rejected_status
                }
            }
        }
    }
`;

export const createServicePlan = gql`
    mutation createServicePlan(
        $staff_id: ID,
        $service_request_id: ID,
        $description: String,
        $created_date: Date,
    ){
        createServicePlan(data:{
            staff_id: $staff_id,
            service_request_id: $service_request_id,
            description: $description,
            created_date: $created_date
        }){
            data{
                id
                attributes{
                    description
                }
            }
        }
    }
`;

export const updateServicePlan = gql`
    mutation updateServicePlan($id: ID!, $data: ServicePlanInput!){
        updateServicePlan(id: $id, data: $data){
            data{
                id
            }
        }
    }
`;

export const createChargeBandDetail = gql`
    mutation createChargeBandDetail(
        $charge_band_name: String,
        $filter_key: String
    ){
        createChargeBandDetail(data:{
            charge_band_name: $charge_band_name,
            filter_key_brokerage_private_ndis_aged_care_pakage: $filter_key
        }){
            data{
                id
                attributes{
                    charge_band_name
                }
            }
        }
    }
`;

export const createChargeBandRate = gql`
    mutation createChargeBandRate(
        $service_title: String,
        $service_code: ID,
        $service_rate: String,
        $service_actual_rate: String,
        $charge_band_detail: ID,
    ){
        createChargeBandRate(data:{
            service_title: $service_title,
            service_code: $service_code,
            service_rate: $service_rate,
            service_actual_rate: $service_actual_rate,
            charge_band_detail: $charge_band_detail
        }){
            data{
                id
                attributes{
                    service_title
                }
            }
        }
    }
`;

export const updateChargeBandRate = gql`
    mutation createChargeBandRate($id: ID!, $data: ChargeBandRateInput!){
        updateChargeBandRate(id: $id,data: $data){
            data{
                id
                attributes{
                    service_title
                }
            }
        }
    }
`;

// // /// // =======================================invoicing=========================================

export const createInvoicing = gql`
    mutation createInvoicing(
        $claim_reference: String,
        $quantity: String,
        $hours: String,
        $unit_price: String,
        $total_price: String,
        $gst_code: String,
        $authorised_by: String,
        $total_kilometers_claim: String,
        $total_invoice_amount: String,
        $registration_number: String,
        $client_detail_id: ID,
        $service_request_id: ID,
        $invoicing_start_date: Date,
        $invoicing_end_date: Date,
        $company_id: ID,
        $csv_generated: Boolean,
        $pdf_generated: Boolean,
        $invoice_genreated_reference: String
    ){
        createInvoicing(data:{
            claim_reference: $claim_reference,
            quantity: $quantity,
            hours: $hours,
            unit_price: $unit_price,
            total_price: $total_price,
            gst_code: $gst_code,
            authorised_by: $authorised_by,
            total_kilometers_claim: $total_kilometers_claim,
            total_invoice_amount: $total_invoice_amount,
            registration_number: $registration_number,
            client_detail_id: $client_detail_id,
            service_request_id: $service_request_id,
            invoicing_start_date: $invoicing_start_date,
            invoicing_end_date: $invoicing_end_date,
            company_id: $company_id,
            csv_generated: $csv_generated,
            pdf_generated: $pdf_generated,
            invoice_genreated_reference: $invoice_genreated_reference
        }){
            data{
            id
                attributes{
                    claim_reference
                }
            }
        }
    }
`;

export const createNewStaffPay = gql`
    mutation createStaffPayroll(
        $staff_detail_id: ID,
        $day_rate: String,
        $day_hours: String,
        $total_day_amount: String,
        $night_rate: String,
        $night_hours: String,
        $total_night_amount: String,
        $evening_hours: String,
        $evening_rate: String,
        $total_evening_amount: String,
        $saturday_rate: String,
        $saturday_hours: String,
        $total_saturday_amount: String,
        $sunday_rate: String,
        $sunday_hours: String,
        $total_sunday_amount: String,
        $holiday_rate: String,
        $holiday_hours: String,
        $total_holiday_amount: String,
        $kilometers_claim: String,
        $reimbursement: String,
        $bonus: String,
        $total_pay: String,
        $total_hours: String,
        $approved: String,
        $pay_start_date: Date,
        $pay_end_date: Date,
        $satff_pay_id: String
    ){
        createStaffPayroll(data:{
            staff_detail_id: $staff_detail_id,
            day_rate: $day_rate,
            day_hours: $day_hours,
            total_day_amount: $total_day_amount,
            night_rate: $night_rate,
            night_hours: $night_hours,
            total_night_amount: $total_night_amount,
            evening_hours: $evening_hours,
            evening_rate: $evening_rate,
            total_evening_amount: $total_evening_amount,
            saturday_rate: $saturday_rate,
            saturday_hours: $saturday_hours,
            total_saturday_amount: $total_saturday_amount,
            sunday_rate: $sunday_rate,
            sunday_hours: $sunday_hours,
            total_sunday_amount: $total_sunday_amount,
            holiday_rate: $holiday_rate,
            holiday_hours: $holiday_hours,
            total_holiday_amount: $total_holiday_amount,
            kilometers_claim: $kilometers_claim,
            reimbursement: $reimbursement,
            bonus: $bonus,
            total_pay: $total_pay,
            total_hours: $total_hours,
            approved: $approved,
            pay_start_date: $pay_start_date,
            pay_end_date: $pay_end_date,
            satff_pay_id: $satff_pay_id
        }){
            data{
                id
                attributes{
                    total_pay
                }
            }  
        }
    }
`;
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button, CircularProgress, Grid, Paper, Stack, Step, StepConnector, StepContent, StepLabel, Stepper, Tooltip, Typography, stepConnectorClasses } from "@mui/material";
import PropTypes from 'prop-types';
import LocalPharmacyRoundedIcon from '@mui/icons-material/LocalPharmacyRounded';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// import IndividualRiskAssessment from "../form_screens/individual_risk-assessment";
// import HomeRiskAssessment from "../form_screens/home_risk-assessment";
// import SafteyPlans from "../form_screens/safety_plans";
// import NewClients from "../form_screens/new_client";
// import MedicalHistoryForm from "../form_screens/medical_history_form";
// import { CustomModalDraftList } from "./screen/custom_modal";
import { useSelector, useDispatch } from "react-redux";
import { client_id, client_onboard_data_lists, selected_draft } from "../../middleware/reducers/client_onboarding_reducer";
import { DynamicFrontendContent, DesignFrontendModules } from "../../services/design_frontend";
import { DynamicFormScreen } from "./screen/dynamic_form_screen";
import { useNavigate } from "react-router-dom";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 24,
        left: 'calc(-50% + 26px)',
        right: 'calc(50% + 26px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#cccccc',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: "#6ECA35",
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: "rgba(0,0,0,0.5)",
    }),

}));

const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <MedicalInformationIcon />,
        2: <LocalPharmacyRoundedIcon />,
        3: <AirlineSeatIndividualSuiteIcon />,
        4: <AddHomeWorkIcon />,
        5: <HealthAndSafetyIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const NewClientManagement = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [openDraft, setOpenDraft] = useState(false);
    const _draft_list = useSelector((state) => state._client_onboarding_module.draftList);
    const [clientDraftList, setClientDraftList] = useState(_draft_list);
    const [dynamicDesignData, loading] = DynamicFrontendContent();
    const dynamicModules = DesignFrontendModules()
    const _saving_list = useSelector((state) => state._client_onboarding_module.client_onboard_data_list);

    const [steps, setSteps] = useState([]);

    useEffect(() => {
        try {
            if (dynamicModules?.length && dynamicDesignData?.length) {
                const copy = [...steps];
                for (const key in dynamicModules) {
                    if (dynamicModules[key]?.attributes?.active && dynamicModules[key]?.attributes?.category === "Client") {
                        const title = dynamicModules[key]?.attributes?.title;
                        const matchCategory = dynamicDesignData.filter((e) => e.attributes?.design_frontend_module_id?.data?.attributes?.title === title)
                        copy.push({
                            label: title,
                            screen: <DynamicFormScreen category={matchCategory} />
                        })
                    }
                }
                setSteps(copy)
            }
        } catch (error) {
            console.log(error)
        }
    }, [dynamicModules, dynamicDesignData])

    const handleNext = (index) => {
        setActiveStep(index);
    };

    const handleModal = () => {
        setOpenDraft(false);
    }

    useEffect(() => {
        if (clientDraftList?.length) {
            setOpenDraft(true);
            dispatch(selected_draft(null));
        } else {
            setOpenDraft(false);
        }
    }, [])

    const saveAndSubmitStaffInfo = () => {
        try {
            if (_saving_list) {
                dispatch(client_onboard_data_lists(null));
                dispatch(client_id(null));
                navigate("/client-dashboard", {
                    state: {
                        activeOption: 0
                    }
                });
                /// add a logic to display success message
            } else {
                throw "data list empty or something missing"
            }
        } catch (error) {
            console.log("ooppssss!..", error)
        }
    }

    return (
        <Grid container sx={{ justifyContent: "center", marginTop: { xs: "10%", sm: "2%", lg: "1%" }, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, overflowY: "hidden" }} >
            {loading ? (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress color="secondary" size={50} />
                </Box>
            ) : null}
            {steps?.length ? (
                <Grid item={true} xs={12} sm={12} lg={11}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Button color={"primary"}
                            variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Send Link
                        </Button>
                    </Grid>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                        {steps?.map((v, i) => (
                            <Step key={i} sx={{ padding: { xs: 0 } }}>
                                <StepLabel sx={{ cursor: "pointer", }} onClick={() => handleNext(i)} StepIconComponent={ColorlibStepIcon}>
                                    <span sx={{ color: activeStep === i ? "currentColor" : "#cccccc", fontFamily: "Roboto-Regular", fontSize: "12px !important" }}>
                                        {v.label}
                                    </span>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Grid item={true} sx={{ mt: 1, py: 1, display: 'flex', justifyContent: "center", }}>
                        {steps[activeStep]?.screen}
                    </Grid>
                    <Grid item={true} xs={11} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Stack direction={"row"} spacing={2}>
                            {/* <Tooltip title="Can Change After Save" placement="left">
                                <Button color={"primary"}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button>
                            </Tooltip> */}
                            <Tooltip title="Can Not Change After Submit" placement="right">
                                <Button color={"primary"} onClick={() => saveAndSubmitStaffInfo()}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Submit
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Grid>
                    {/* <CustomModalDraftList openDraft={openDraft} handleModal={handleModal} clientDraftList={clientDraftList} /> */}
                </Grid>
            ) : null}
            {!dynamicDesignData?.length && !loading ? (
                <Grid item={true} xs={12} sm={12} lg={12} sx={{ display: "flex", height: "calc(85vh)", justifyContent: "center", alignItems: "center", }}>
                    <Typography>
                        No Data
                    </Typography>
                </Grid>
            ) : null}
        </Grid >
    )
}

export default NewClientManagement;
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import Person2Icon from '@mui/icons-material/Person2';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';
import Groups2Icon from '@mui/icons-material/Groups2';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ReportIcon from '@mui/icons-material/Report';
import SpaIcon from '@mui/icons-material/Spa';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { FilterUserById } from '../../services/auth_service';
import { useDispatch } from 'react-redux';
import { userStateManagement } from '../../middleware/reducers/user';


const DashboardOptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [apiGetUserInfo, loadings, error] = FilterUserById();
    const [userInfo, setUserInfo] = React.useState(null);
    const [userType, setUserType] = React.useState(null);

    const getMyDetails = async () => {
        try {
            setLoading(true)
            const users = JSON.parse(localStorage.getItem("user"))
            const response = await apiGetUserInfo({
                "variables": {
                    "id": users?.id
                }
            })
            if (response?.data?.usersPermissionsUser?.data) {
                const final_data = response?.data?.usersPermissionsUser?.data;
                setUserInfo(final_data);
                dispatch(userStateManagement(final_data))
                setLoading(false)
            }
        } catch (error) {
            console.log("error")
            setLoading(false)
        }
    }

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!userInfo && user) {
            getMyDetails()
        }
        if (userInfo) {
            setUserType(userInfo?.attributes?.user_type?.data?.attributes?.user_type);
        }
    }, [userInfo])

    return (
        <Grid container sx={{ p: 2, display: "flex", justifyContent: "center", alignItems: "center", }}>
            {loading ? (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress color="secondary" size={50} />
                </Box>
            ) : (
                <Grid item={true} xs={12} sm={11} md={10} lg={8} sx={{ marginTop: { lg: "2%" }, marginBottom: { xs: "10%", lg: "0%" }, display: "flex", justifyContent: "center", }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: "inherit",
                            justifyContent: "center",
                            '& > :not(style)': {
                                m: 1,
                                width: { lg: "16ch", md: "14ch", xs: "14ch" },
                                height: "22ch",
                            },
                        }}
                    >
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/client-dashboard")}>
                                    <Person2Icon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Clients"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/staff-dashboard")} >
                                    <Groups2Icon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Staff"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() === "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/service-dashboard")} >
                                    <SpaIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"My Dashboard"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/reports-dashboard")}>
                                    <ReportIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Incidents / Complaints"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/registration-dashboard")}>
                                    <LibraryBooksIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Registers"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/assets-dashboard")}>
                                    <WebAssetIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Assets"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} >
                                    <Diversity1Icon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Leads / Referrals"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/scheduler-dashboard")}>
                                    <CalendarMonthIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Schedule"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/audit-dashboard")}>
                                    <AssignmentIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Audit"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/admin-dashboard")}>
                                    <AdminPanelSettingsIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Admin"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => navigate("/finance-dashboard")}>
                                    <InsertChartIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Finance"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                        {userType && userType?.toLowerCase() !== "staff" ? (
                            <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                                <div className="dashboard-btn" elevation={0} onClick={() => { }}>
                                    <ScheduleSendIcon className='icon-color' sx={{ fontSize: 70 }} color='secondary' />
                                </div>
                                <div style={{
                                    height: "5ch",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <Typography className='text-btn' textAlign="center" sx={{ fontFamily: "Roboto-Regular" }}>{"Attendance"}</Typography>
                                </div>
                            </Paper>
                        ) : null}
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}

export default DashboardOptions;
import { Grid, Paper, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VerticalLinearStepper } from "../../../components/form_steps";
import { DynamicInputs } from "./dynamic_inputs";

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
}));


export const DynamicFormScreen = ({ category }) => {
    const [steps, setSteps] = useState([])

    useEffect(() => {
        if (category?.length) {
            const copy = [];
            for (const key in category) {
                const active = category[key]?.attributes?.design_frontend_category_id?.data?.attributes?.active
                if (active) {
                    const title = category[key]?.attributes?.design_frontend_category_id?.data?.attributes?.title;
                    const inputType = category[key]?.attributes?.Input_type;
                    const inputsList = category[key]?.attributes?.design_frontend_input_names?.data;
                    const questionList = category[key]?.attributes?.question_list_ids?.data;
                    const categoryValue = category[key]?.attributes?.design_frontend_category_id?.data?.attributes?.title;
                    const module = category[key]?.attributes?.design_frontend_module_id?.data?.attributes?.title;
                    const can_create_multiple = category[key]?.attributes?.can_create_multiple;
                    copy.push({
                        label: title,
                        screen: <DynamicInputs can_create_multiple={can_create_multiple} inputType={inputType} inputsList={inputsList} questionList={questionList} categoryValue={categoryValue} module={module} />
                    })
                }
            }
            setSteps(copy)
        }
    }, [category]);

    return (
        <Grid container sx={{ justifyContent: "center", marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, overflowY: "hidden", }} >
            <Grid item={true} xs={12} md={12} sm={12} lg={12} sx={{ flexGrow: 1, border: "1px solid #6ECA35", borderRadius: 2, overflow: "hidden" }} >
                {steps?.length && (
                    <StyledPaper
                        sx={{
                            mx: 'auto',
                        }}
                        elevation={0}
                    >
                        <VerticalLinearStepper steps={steps} />
                    </StyledPaper>
                )}
            </Grid>
        </Grid>
    )
}
// store.js
import { configureStore } from '@reduxjs/toolkit';
import SearchReducer from './reducers/search';
import MyServicesReducer from './reducers/my_services';
import ServiceRequestd from './reducers/service_request';
import MyAuditReducer from "./reducers/audit"
import ClientOnboardReducer from './reducers/client_onboarding_reducer';
import StaffOnboardReducer from './reducers/staff_onboarding_reducer';
import HistoryReducer from './reducers/history_reducers';
import DesignReducer from './reducers/design'
import MyAuthReducer from './reducers/user';
import PaymentProcessingReducer from "./reducers/payment_proccess_reducer"

const store = configureStore({
  reducer: {
    _authentication: MyAuthReducer,
    _search: SearchReducer,
    _my_services: MyServicesReducer,
    _service_request: ServiceRequestd,
    _audit_module: MyAuditReducer,
    _client_onboarding_module: ClientOnboardReducer,
    _staff_onboarding_module: StaffOnboardReducer,
    _history: HistoryReducer,
    _desing_data: DesignReducer,
    _payment_processing_reducer: PaymentProcessingReducer,
  },
});

export default store;
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button, CircularProgress, Grid, Menu, MenuItem, Stack, ToggleButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { useDispatch } from 'react-redux';
import { selectedServiceReducer } from '../../middleware/reducers/my_services';
import { Delete, Download, Edit, RemoveRedEye } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import PreviewIcon from '@mui/icons-material/Preview';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useNavigate } from 'react-router-dom';
import { clientDetailsId, staffDetailsId } from '../../middleware/reducers/history_reducers';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { convertToShortForm } from '../functions';


const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        style={{ minWidth: headCell.label?.length >= 9 ? 200 : headCell.label.toLowerCase() === "id" ? 40 : 130 }}
                        sortDirection={orderBy === index ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === index}
                            direction={orderBy === index ? order : 'asc'}
                            onClick={createSortHandler(index)}
                            style={{ fontSize: "1.1em", fontFamily: "Roboto-Bold", textTransform: "capitalize" }}
                        >
                            <Tooltip title={headCell.label?.split("_").join(" ").toUpperCase()} placement="bottom">
                                {convertToShortForm(headCell.label?.split("_").join(" "))}
                            </Tooltip>
                            {orderBy === index ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableHeadWithCheckBox = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="secondary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        style={{ minWidth: headCell.label?.length >= 10 ? 150 : headCell.label === "Id" ? 50 : 150 }}
                        sortDirection={orderBy === index ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === index}
                            direction={orderBy === index ? order : 'asc'}
                            onClick={createSortHandler(index)}
                            style={{ fontSize: "1.1em", fontFamily: "Roboto-Bold" }}
                        >
                            {headCell.label}
                            {orderBy === index ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadWithCheckBox.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const RegisterClinetsList = ({ list, details, headCells }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = list.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

    const visibleRows = React.useMemo(
        () => stableSort(list, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={list.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {list.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const v = row?.attributes;
                                let find, fGender;
                                if (v?.user_type_id === "Client") {
                                    find = details?.find((e) => e?.client_id === row?.id)
                                    const genderC = find?.clientAnswerLovsReferences?.data.find((e) => e?.attributes?.input_name?.toLowerCase() === "gender");
                                    if (genderC) {
                                        fGender = genderC?.attributes?.selected_option_value;
                                    }
                                }
                                if (v?.user_type_id === "Staff") {
                                    find = details?.find((e) => e?.staff_id === row?.id)
                                    const genderS = find?.staffAnswerLovsReferences?.data.find((e) => e?.attributes?.input_name?.toLowerCase() === "gender");
                                    if (genderS) {
                                        fGender = genderS?.attributes?.selected_option_value;
                                    }
                                }
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="secondary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ p: 1 }} >{index + 1}</TableCell>
                                        <TableCell sx={{ p: 1 }} >{row.attributes.first_name + " " + row.attributes.last_name}</TableCell>
                                        <TableCell sx={{ p: 1 }} >{fGender}</TableCell>
                                        <TableCell sx={{ p: 1 }} >{row.attributes.age}</TableCell>
                                        <TableCell sx={{ p: 1 }} >{row.attributes.ndis_number}</TableCell>
                                        <TableCell sx={{ p: 1 }} >{row.attributes.medicare_number || "Not Defined"}</TableCell>
                                        <TableCell sx={{ p: 1 }} >{row.attributes.medicare_expiry_date || "Not Defined"}</TableCell>
                                        <TableCell sx={{ p: 1 }}>{find?.contactDetails?.data[0]?.attributes?.email || "Not Defined"}</TableCell>
                                        <TableCell sx={{ p: 1 }}>{row.attributes.active ? "Active" : "Archived"}</TableCell>
                                        <TableCell sx={{ p: 1 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <Button sx={{ boxShadow: "0px 1px 1px 1px lightgrey", color: "#000", fontFamily: "Roboto-Medium" }} onClick={() => {
                                                    dispatch(clientDetailsId(row.id));
                                                    navigate("/client-dashboard", {
                                                        state: {
                                                            activeOption: 0
                                                        }
                                                    })
                                                }}>
                                                    Details
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={list.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const RegisterClinetsGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Name:</span> {v.Name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Gender:</span> {v.Gender}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Age:</span> {v.Age}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>IsNdisFunded:</span> {v.IsNdisFunded}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>IsPackageFunded:</span> {v.IsPackageFunded}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>DivisionName:</span> {v.DivisionName}</Typography>
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>PrimaryPhone:</span> {v.PrimaryPhone}</Typography> */}
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>SecondaryPhone:</span> {v.SecondaryPhone}</Typography> */}
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>PrimaryEmailAddress:</span> {v.PrimaryEmailAddress}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>State:</span> {v.State}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>IsActive:</span> {v.IsActive}</Typography>
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>ContactId:</span> {v.ContactId}</Typography> */}
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>ContactCode:</span> {v.ContactCode}</Typography> */}
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const RegisterStaffList = ({ rows, headCells }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            // console.log("newSelected", newSelected)
            setSelected(newSelected);
            return;
        }
        setSelected([]);
        // console.log("rows", rows)
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="secondary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell id={labelId}>{row.id}</TableCell>
                                        <TableCell
                                            id={labelId}
                                        // sx={{ minWidth: row.Name.length * 10, }}
                                        >
                                            {row.Name}
                                        </TableCell>
                                        <TableCell sx={{ minWidth: row.GenderName.length * 20, }}>{row.GenderName}</TableCell>
                                        <TableCell>{row.Age}</TableCell>
                                        <TableCell>{row.IsCarer}</TableCell>
                                        {/* <TableCell>{row.PrimaryPhone}</TableCell>
                                        <TableCell>{row.SecondaryPhone}</TableCell> */}
                                        <TableCell>{row.PrimaryEmail}</TableCell>
                                        <TableCell>{row.FormattedAddress}</TableCell>
                                        {/* <TableCell>{row.StartDate}</TableCell> */}
                                        {/* <TableCell>{row.EmergencyContactName}</TableCell> */}
                                        {/* <TableCell>{row.EmergencyContactPrimaryNumber}</TableCell> */}
                                        <TableCell>{row.Email}</TableCell>
                                        <TableCell>{row.TaxFileNumber}</TableCell>
                                        <TableCell>{row.TaxResident}</TableCell>
                                        <TableCell>{row.TaxClaimThreshold}</TableCell>
                                        <TableCell>{row.SuperFundName}</TableCell>
                                        <TableCell>{row.SuperFundNumber}</TableCell>
                                        <TableCell>{row.WagesBsb}</TableCell>
                                        <TableCell>{row.WagesAccount}</TableCell>
                                        <TableCell>{row.WasgesAccountName}</TableCell>
                                        <TableCell>{row.ContractTypeName}</TableCell>
                                        <TableCell>{row.PayBandName}</TableCell>
                                        <TableCell>{row.IsActive}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <Button sx={{ boxShadow: "0px 1px 1px 1px lightgrey", color: "#000", fontFamily: "Roboto-Medium" }} onClick={() => {
                                                    dispatch(staffDetailsId(row.id));
                                                    navigate("/staff-dashboard", {
                                                        state: {
                                                            activeOption: 0
                                                        }
                                                    })
                                                }}>
                                                    Details
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const RegisterStaffGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Name:</span> {v.Name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Gender:</span> {v.Gender}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Age:</span> {v.Age}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>IsCarer:</span> {v.IsCarer}</Typography>
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>PrimaryPhone:</span> {v.PrimaryPhone}</Typography> */}
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>SecondaryPhone:</span> {v.SecondaryPhone}</Typography> */}
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>PrimaryEmail:</span> {v.PrimaryEmail}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>FormattedAddress:</span> {v.FormattedAddress}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>StartDate:</span> {v.StartDate}</Typography>
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>EmergencyContactName:</span> {v.EmergencyContactName}</Typography> */}
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>EmergencyContactPrimaryNumber:</span> {v.EmergencyContactPrimaryNumber}</Typography> */}
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const RegisterMedicationList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
                                        <TableCell id={labelId}>{row.id}</TableCell>
                                        <TableCell >{row.client_id}</TableCell>
                                        <TableCell >{row.first_name}</TableCell>
                                        <TableCell >{row.last_name}</TableCell>
                                        <TableCell >{row.medication}</TableCell>
                                        <TableCell >{row.purpose_medication}</TableCell>
                                        <TableCell >{row.dose_route}</TableCell>
                                        <TableCell >{row.expiry_date}</TableCell>
                                        <TableCell >{row.comments}</TableCell>
                                        <TableCell >{row.medical_practitioner}</TableCell>
                                        <TableCell >{row.medical_contact}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const IncedentRegisterList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
                                        <TableCell sx={{ py: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.first_name}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.last_name}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.gender}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.date_of_birth}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.address1}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.address2}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.home_phone}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.service_name}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.incident_reporter_name}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.position}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.physical_aggression}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.verbal_aggression}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.property_damage}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.location_of_incident}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.date_and_time}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.duration}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.what_happened_before}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.what_inappropriate_behavior_happened}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.what_happened_after}</TableCell>
                                        <TableCell sx={{ py: 1 }} >{row.notes}</TableCell>
                                        <TableCell sx={{ py: 1 }} >
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                <IconButton>
                                                    <FeedbackIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const RegisterMedicationGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Client Id:</span> {v.client_id}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>First Name:</span> {v.first_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Last Name:</span> {v.last_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Medication:</span> {v.Medication}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Dose:</span> {v.Dose}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Route:</span> {v.Route}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Expirydate:</span> {v.Expirydate}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Comments:</span> {v.Comments}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const IncidentRegisterGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Surname:</span> {v.surname}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Given Name:</span> {v.given_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Gender:</span> {v.gender}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Address:</span> {v.address}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Home Phone:</span> {v.home_phone}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Date Of Birth:</span> {v.date_of_birth}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Service_Name:</span> {v.service_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Name:</span> {v.name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Position:</span> {v.position}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const ComplaintRegisterList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.category}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.option}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.date_of_complaint}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.first_name}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.last_name}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.telephone}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.email}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.i_am_a}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.disability}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.details_needed}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.details_complaint}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >{row.notes}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                <IconButton>
                                                    <FeedbackIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const ComplaintRegisterGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>I am a:</span> {v.i_am_a}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Ctegory:</span> {v.category}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Classification Option:</span> {v.option}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Date Of Complaint:</span> {v.date_of_complaint}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>First Name:</span> {v.first_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Last Name:</span> {v.last_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Telephone:</span> {v.telephone}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Email:</span> {v.email}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const RegisterContinousImprovmentList = ({ rows, headCells, editOpen, setEditOpen }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
                                        <TableCell sx={{ py: 1, }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.type}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.date}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.category}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.description}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.actions}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.continous_improvment}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.status}</TableCell>
                                        <TableCell sx={{ py: 1, }}>{row.date_addressed}</TableCell>
                                        <TableCell sx={{ py: 1, }}>
                                            <IconButton onClick={() => { setEditOpen(true) }}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const RegisterContinousImprovmentGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Type:</span> {v.type}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Category:</span> {v.category}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Complaint Description:</span> {v.complaint_description}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Continous Improvment Description:</span> {v.continous_improvment_description}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const OrgnizationsList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.email}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.phone}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.website}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.Abn}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.Description}</TableCell>
                                        {/* <TableCell >{row.invoice_to}</TableCell> */}
                                        <TableCell sx={{ padding: 1 }}>{row.primary_invoice}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.secondary_invoice}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.primary_schedule_email}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.secondary_schedule_email}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>
                                            <IconButton>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const OrgnizationsGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Email:</span> {v.email}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Phone:</span> {v.phone}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Website:</span> {v.website}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Abn:</span> {v.Abn}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Description:</span> {v.Description}</Typography>
                        {/* <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Invoice To:</span> {v.invoice_to}</Typography> */}
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Primary Invoice:</span> {v.primary_invoice}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Secondary Invoice:</span> {v.secondary_invoice}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const PriceListGrid = ({ rows, headCells, editPriceItem }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                // const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell id={labelId}>{row.id}</TableCell>
                                        <TableCell >{row.code}</TableCell>
                                        <TableCell >{row.service_name}</TableCell>
                                        <TableCell >{row.rate_type}</TableCell>
                                        <TableCell >{row.rate}</TableCell>
                                        <TableCell >{row.afternoon_rate}</TableCell>
                                        <TableCell >{row.night_rate}</TableCell>
                                        <TableCell >{row.saturday_rate}</TableCell>
                                        <TableCell >{row.sunday_rate}</TableCell>
                                        <TableCell >{row.public_holiday_rate}</TableCell>
                                        <TableCell >
                                            <Stack direction="row" spacing={2}>
                                                <IconButton onClick={() => editPriceItem(index, row.code)} size="medium">
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => { }} size="medium">
                                                    <ArchiveIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const NDISPriceListGrid = ({ rows, headCells, editPriceItem }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                // const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell sx={{ py: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.support_item_number}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.support_item_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.registration_group_number}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.registration_group_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.support_category_number}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.support_category_number_pace}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.support_category_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.support_category_name_pace}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.unit}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.quote}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.start_date}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.end_date}</TableCell>
                                        <TableCell sx={{ py: 1, color: "#35BE00" }}>{row.wa}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.non_face_to_face_support_provision}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.provider_travel}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.short_notice_cancellations}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.ndia_requested_reports}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.irregular_sil_supports}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.type}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Stack direction="row" spacing={2}>
                                                <IconButton disabled onClick={() => editPriceItem(index, row.db_id)} size="medium">
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton disabled onClick={() => { }} size="medium">
                                                    <ArchiveIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const ChargeBandListGrid = ({ rows, headCells, editPriceItem, loading_rates }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                // const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        // tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell sx={{ py: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.charge_band_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.filter_key_brokerage_private_ndis_aged_care_pakage}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.status ? "Active" : "Archive"}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Stack direction="row" spacing={2}>
                                                <IconButton onClick={() => editPriceItem(index)} size="medium">
                                                    {loading_rates ? <CircularProgress color="secondary" size={26} /> : <EditIcon />}
                                                </IconButton>
                                                <IconButton onClick={() => { }} size="medium">
                                                    <ArchiveIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const OrganizationGrid = ({ rows, headCells, editOrgnizItem }) => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () => stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${row.id}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="secondary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.ABN}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.registration_number}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.company_name}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.email}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.phone}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.primary_invoice}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.primary_schedule_email}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.secondary_invoice}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.secondary_schedule_email}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.website_url}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.notes}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }} >{row.description}</TableCell>
                                        <TableCell sx={{ p: 0, px: 2 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <Button sx={{ boxShadow: "0px 1px 1px 1px lightgrey", color: "#000", fontFamily: "Roboto-Medium" }} onClick={() => {
                                                    // dispatch(clientDetailsId(row.id));
                                                    // navigate("/client-dashboard", {
                                                    //     state: {
                                                    //         activeOption: 0
                                                    //     }
                                                    // })
                                                }}>
                                                    Details
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

const colorCode = { //  // // color code for auto scheduling
    "pending": {
        "main": 'rgba(255,241,118,0.30)',
    },
    "plan": {
        "main": 'rgba(79,195,247,0.30)',
    },
    "approved": {
        "main": 'rgba(225,190,231,0.30)',
    },
    "acknowledged": {
        "main": 'rgba(255,183,77,0.30)',
    },
    "done": {
        "main": 'rgba(169,210,121,0.30)',
    },
    "rejected": {
        "main": 'rgba(248,16,59,0.30)',
    },
};

export const MyServicesList = ({ rows, headCells, setSelectedItem }) => {
    const dispatch = useDispatch()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', overflowX: "auto" }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                // const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        // aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ backgroundColor: colorCode[row.service_status.toLowerCase()].main }}
                                    >
                                        <TableCell id={labelId}>{row.id}</TableCell>
                                        <TableCell >{row.code}</TableCell>
                                        <TableCell >{row.service_date}</TableCell>
                                        <TableCell sx={{ textTransform: "capitalize" }}>{row.service_status}</TableCell>
                                        <TableCell >{row.service_title}</TableCell>
                                        <TableCell >{row.client_name}</TableCell>
                                        {/* <TableCell >{row.contact_no}</TableCell> */}
                                        {/* <TableCell >{row.NIDS_no}</TableCell> */}
                                        <TableCell >{row.gender}</TableCell>
                                        <TableCell >{row.service_location}</TableCell>
                                        <TableCell >
                                            <Stack direction="row" spacing={2}>
                                                <IconButton onClick={() => {
                                                    dispatch(selectedServiceReducer({ objId: row.id, serv_code: row.code }))
                                                }} size="medium">
                                                    <RateReviewIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const ReimbursementPageView = ({ rows, headCells, setSelectedItem }) => {
    const dispatch = useDispatch()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', overflowX: "auto" }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                    // selected={isItemSelected}
                                    // sx={{ backgroundColor: colorCode[row.service_status.toLowerCase()].main }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell >{row.Re_id}</TableCell>
                                        <TableCell >{row.service_id}</TableCell>
                                        <TableCell >{row.client_id}</TableCell>
                                        <TableCell >{row.staff_id}</TableCell>
                                        <TableCell >{row.staus}</TableCell>
                                        <TableCell >{row.amont}</TableCell>
                                        <TableCell >{row.purpose_note}</TableCell>
                                        <TableCell >{row.address_of_paymnt}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const KilometerClaimView = ({ rows, headCells, setSelectedItem }) => {
    const dispatch = useDispatch()
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', overflowX: "auto" }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                    // selected={isItemSelected}
                                    // sx={{ backgroundColor: colorCode[row.service_status.toLowerCase()].main }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell >{row.KMC_id}</TableCell>
                                        <TableCell >{row.service_id}</TableCell>
                                        <TableCell >{row.client_id}</TableCell>
                                        <TableCell >{row.staff_id}</TableCell>
                                        <TableCell >{row.staus}</TableCell>
                                        <TableCell >{row.date_of_travel}</TableCell>
                                        <TableCell >{row.total_kilometers}</TableCell>
                                        <TableCell >{row.purpose_of_travel}</TableCell>
                                        <TableCell >{row.vehicle_used}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const AuditListTableView = ({ rows, headCells, editListItem }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () => stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, labelId)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={labelId}
                                    // // selected={isItemSelected}
                                    >
                                        <TableCell sx={{ py: 1 }}>{row.id}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.module.title}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.category.title}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.types.title}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.document_name.title}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Stack direction="row" spacing={2}>
                                                <Tooltip title="6 Months Reminder " placement="right">
                                                    <Switch />
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Stack direction="row" spacing={2}>
                                                <Tooltip title="Edit" placement="left">
                                                    <IconButton onClick={() => editListItem(index)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Active / Inactive" placement="right">
                                                    <Switch />
                                                </Tooltip>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const FeedbackList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell sx={{ py: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.first_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.last_name}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.contact}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.email}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.feedback_type}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.source_feedback}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.areas_feedback}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.comments}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.date_of_report}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <FeedbackIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const FeedbackGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>First Name:</span> {v.first_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Last Name:</span> {v.last_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Contact:</span> {v.contact}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Email:</span> {v.email}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Feedback Type:</span> {v.feedback_type}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Source Feedback:</span> {v.source_feedback}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Areas Feedback:</span> {v.areas_feedback}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Comments:</span> {v.comments}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Date Of Birth:</span> {v.date_of_report}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const InvoiceLedgerList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell id={labelId}>{row.id}</TableCell>
                                        <TableCell id={labelId}>{row.type}</TableCell>
                                        <TableCell id={labelId}>{row.recipient}</TableCell>
                                        <TableCell id={labelId}>{row.invoice}</TableCell>
                                        <TableCell id={labelId}>{row.net}</TableCell>
                                        <TableCell id={labelId}>{row.gst}</TableCell>
                                        <TableCell id={labelId}>{row.gross}</TableCell>
                                        <TableCell id={labelId}>{row.date}</TableCell>
                                        <TableCell id={labelId}>{row.due_date}</TableCell>
                                        <TableCell id={labelId}>{row.status}</TableCell>
                                        <TableCell id={labelId}>
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <Switch />
                                                </IconButton>
                                                <IconButton>
                                                    <Download />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

const CustomeWithTableHeaders = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        style={{ minWidth: headCell.label?.length >= 10 ? 200 : headCell.label === "Id" ? 50 : 150 }}
                        sortDirection={orderBy === index ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === index}
                            direction={orderBy === index ? order : 'asc'}
                            onClick={createSortHandler(index)}
                            style={{ fontSize: "1.1em", fontFamily: "Roboto-Bold" }}
                        >
                            {headCell.label}
                            {orderBy === index ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

CustomeWithTableHeaders.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['Id', 'Id']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export const ConflictInterestList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <CustomeWithTableHeaders
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell id={labelId}>{row.id}</TableCell>
                                        <TableCell id={labelId}>{row.first_name}</TableCell>
                                        <TableCell id={labelId}>{row.last_name}</TableCell>
                                        <TableCell id={labelId}>{row.phone}</TableCell>
                                        <TableCell id={labelId}>{row.email}</TableCell>
                                        <TableCell id={labelId}>{row.role}</TableCell>
                                        <TableCell id={labelId}>{row.related_to}</TableCell>
                                        <TableCell id={labelId}>{row.nature_of_interest}</TableCell>
                                        <TableCell id={labelId}>{row.conflict_description}</TableCell>
                                        <TableCell id={labelId}>{row.date_of_disclosure}</TableCell>
                                        <TableCell id={labelId}>{row.actions_taken}</TableCell>
                                        <TableCell id={labelId}>{row.approval_status}</TableCell>
                                        <TableCell id={labelId}>{row.link_to_document}</TableCell>
                                        <TableCell sx={{ padding: 1 }} >
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                <IconButton>
                                                    <FeedbackIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const ConflictInterestGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>First Name:</span> {v.first_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Last Name:</span> {v.last_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Phone:</span> {v.phone}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Email:</span> {v.email}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Role:</span> {v.role}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Nature Of Interest:</span> {v.nature_of_interest}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Conflict Description:</span> {v.conflict_description}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Date Of Disclosure:</span> {v.date_of_disclosure}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Actions Taken:</span> {v.actions_taken}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Approval Status:</span> {v.approval_status}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Follow Up Actions:</span> {v.follow_up_actions}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Notes:</span> {v.notes}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Approver Name:</span> {v.approver_name}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Outcome:</span> {v.outcome}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Link To Document:</span> {v.link_to_document}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

export const TrainingList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.description}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.category}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.documentName}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.expiryDate}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton>
                                                    <Delete />
                                                </IconButton>
                                                <IconButton>
                                                    <DescriptionIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const MeetingList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    return (
        <Box sx={{ width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Paper sx={{ width: '100%', }} elevation={0}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, index)}
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell sx={{ py: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.meetingTitle}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.meetingCategory}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.meetingDate}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.meetingTime}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.attendeesName}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.agendaItems}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.actionItems}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.notes}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.follow_up_actions}</TableCell>
                                        <TableCell sx={{ py: 1 }}>{row.NextMeetingDate}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Stack direction={"row"} spacing={2}>
                                                <IconButton>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton>
                                                    <PreviewIcon />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
        </Box>
    );
}

export const UserManagementList = ({ handleClickMenuClose, rows, headCells, handleUserStatus, handleClickMenu, handleMenuOption, anchorEls, openMenuIndex }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClickz = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ width: '100%', }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        // hover
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => handleClick(event, index)}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }} id={labelId}>{index + 1}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Tooltip title={row?.first_name}>
                                                {row?.first_name?.slice(0, 15)}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Tooltip title={row?.last_name}>
                                                {row?.last_name?.slice(0, 15)}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Tooltip title={row?.email}>
                                                {row?.email?.slice(0, 15)}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Tooltip title={row?.last_login}>
                                                {row?.last_login?.slice(0, 15)}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Tooltip title={row?.feature_group}>
                                                {row?.feature_group?.slice(0, 15)}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Tooltip title={row?.client_group}>
                                                {row?.client_group?.slice(0, 15)}
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <Switch color='primary' checked={row?.active} onClick={() => handleUserStatus(row.id, row)} />
                                        </TableCell>
                                        <TableCell sx={{ py: 1, textTransform: "capitalize" }}>{row?.user_type}</TableCell>
                                        <TableCell sx={{ py: 1, textTransform: "capitalize" }}>{row?.approval ? "Approved" : "Pending"}</TableCell>
                                        <TableCell sx={{ py: 1 }}>
                                            <IconButton
                                                id="basic-button"
                                                aria-controls={openMenuIndex === row.id ? 'simple-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={row?.openMenu ? 'true' : undefined}
                                                onClick={(event) => handleClickMenu(row.id, event.currentTarget)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEls}
                                                open={openMenuIndex === row.id}
                                                onClose={() => handleClickMenuClose()}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem onClick={() => handleMenuOption(row, "approval")} >
                                                    {row?.approval ? "Cancel Approval" : "Approve"}</MenuItem>
                                                <MenuItem onClick={() => handleMenuOption(row, "editUser")} >
                                                    Edit User
                                                </MenuItem>
                                                <MenuItem onClick={() => handleMenuOption(row, "editFeatureGroup")} >
                                                    Edit Feature Group
                                                </MenuItem>
                                                <MenuItem onClick={() => handleMenuOption(row, "editCleintGroup")} >
                                                    Edit Cleint Group
                                                </MenuItem>
                                                <MenuItem onClick={() => handleMenuOption(row, "forgetPassword")} >
                                                    Forget Password
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box >
    );
}

export const FeatureManagementList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClickz = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ width: '100%', }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => handleClick(event, index)}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.group_name}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.group_description}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.email}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.created_date}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>
                                            <Switch />
                                        </TableCell>
                                        <TableCell sx={{ padding: 1 }}>
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const ApplicationsList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClickz = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ width: '100%', }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => handleClick(event, index)}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.id}</TableCell>

                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.staff_name}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.staff_id}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.leave_type}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.leave_start_date}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.leave_end_date}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.duration}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.reason_for_leave}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.contact_information}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.alternate_contact}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.approval_status}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.comments_notes}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.department}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.leave_balance}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.date_of_submission}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.date_of_approval_rejection}</TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.status}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const WarningList = ({ rows, headCells }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    // console.log(window.screen.height / 45)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClickz = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ width: '100%', }}>
            <Paper sx={{ width: '100%', }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHeadWithCheckBox
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(index);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={index}
                                        // // selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => handleClick(event, index)}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ padding: 1 }} id={labelId}>{row.id}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.staff_name}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.staff_id}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.date_of_warning}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.warning_type}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.issued_by}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.incident_date}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.description_of_incident}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.policy_voilated}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.previos_warnings}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.action_taken}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.improvement_plan}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.follow_up_actions}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.witnesses}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.acknowledgment}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.next_setup}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.date_of_resolution}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>{row.status}</TableCell>
                                        <TableCell sx={{ padding: 1 }}>
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export const MeetingGrid = ({ rows, headCells, screenWidth }) => {
    return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            {rows.map((v, i) => {
                return (
                    <Paper key={i} elevation={2} sx={{ width: screenWidth, p: 2, m: 1 }}>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Meeting Date:</span> {v.meetingDate}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Meeting Time:</span> {v.meetingTime}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Meeting Title:</span> {v.meetingTitle}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Attendees Name:</span> {v.attendeesName}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Agenda Items:</span> {v.agendaItems}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Notes:</span> {v.notes}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Follow Up Actions:</span> {v.follow_up_actions}</Typography>
                        <Typography sx={{ fontFamily: "Roboto-Regular" }}><span style={{ fontFamily: "Roboto-Regular", color: "#808080" }}>Next Meeting Date:</span> {v.NextMeetingDate}</Typography>
                    </Paper>
                )
            })}
        </Grid>
    )
}

import styled from "@emotion/styled";
import { Box, Button, CircularProgress, Grid, Paper, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { VerticalLinearStepper } from "../../../components/form_steps";
import { WorkDaysAndTimeSlots } from "./staff_information";
// import { CustomModalDraftListStaff } from "./screen/custom_modal";
import { useDispatch, useSelector } from "react-redux";
import { createdId, DynamicStaffOnboard, SelectedDraft } from "../../../middleware/reducers/staff_onboarding_reducer";
import { DesignFrontendModules, DynamicFrontendContent } from "../../../services/design_frontend";
import { DynamicInputsStaff } from "./screen/dynamic_Inputs";
import { useNavigate } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
}));

const StaffOnBoarding = () => {
    const disaptch = useDispatch();

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5.2
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        }
        if (window.screen.width < 600) {
            return window.screen.width / 1.4
        }
    };

    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window]);

    const navigate = useNavigate();
    const [openDraft, setOpenDraft] = useState(false);
    const _draft_list = useSelector((state) => state._staff_onboarding_module.draftList);
    const [staffDraftList, setStaffDraftList] = useState(_draft_list);
    const [dynamicDesignData, loading] = DynamicFrontendContent();
    const dynamicModules = DesignFrontendModules();
    const _saving_staff_list = useSelector((state) => state._staff_onboarding_module.dynamic_staff_onboard);

    const handleModal = () => {
        setOpenDraft(false);
    };

    useEffect(() => {
        if (staffDraftList?.length) {
            setOpenDraft(true);
            disaptch(SelectedDraft(null));
        } else {
            setOpenDraft(false);
        }
    }, [staffDraftList]);

    const dispatch = useDispatch();

    const [steps, setSteps] = useState([]);

    useEffect(() => {
        try {
            if (dynamicModules?.length && dynamicDesignData?.length) {
                const copy = [...steps];
                for (const key in dynamicModules) {
                    if (dynamicModules[key]?.attributes?.active && dynamicModules[key]?.attributes?.category === "Staff") {
                        const title1 = dynamicModules[key]?.attributes?.title;
                        const category = dynamicDesignData.filter((e) => e.attributes?.design_frontend_module_id?.data?.attributes?.title === title1)
                        for (const key in category) {
                            const active = category[key]?.attributes?.design_frontend_category_id?.data?.attributes?.active
                            const title = category[key]?.attributes?.design_frontend_category_id?.data?.attributes?.title;
                            if (active && title !== "Upload Documents") {
                                const inputType = category[key]?.attributes?.Input_type;
                                const inputsList = category[key]?.attributes?.design_frontend_input_names?.data;
                                const questionList = category[key]?.attributes?.question_list_ids?.data;
                                const documents = category[key]?.attributes?.Documents;
                                copy.push({
                                    label: title,
                                    screen: <DynamicInputsStaff category={title} documents={documents} inputType={inputType} inputsList={inputsList} questionList={questionList} />
                                })
                            }
                        }
                    }
                }
                copy.push({
                    label: 'Working Days (Start Time & End Time)',
                    screen: <WorkDaysAndTimeSlots />
                })
                setSteps(copy)
            }
        } catch (error) {
            console.log(error)
        }
    }, [dynamicModules, dynamicDesignData])

    const saveAndSubmitStaffInfo = () => {
        try {
            if (_saving_staff_list) {
                // console.log("_saving_staff_list", _saving_staff_list)
                dispatch(DynamicStaffOnboard(null));
                dispatch(createdId(null));
                navigate("/staff-dashboard", {
                    state: {
                        activeOption: 0
                    }
                });
                /// add a logic to display success message
            } else {
                throw "data list empty or something missing"
            }
        } catch (error) {
            console.log("ooppssss!..", error)
        }
    }

    return (
        <Grid container sx={{ justifyContent: "center", marginTop: { xs: "10%", sm: "2%", lg: "1%" }, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, overflowY: "hidden" }} >
            {loading ? (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <CircularProgress color="secondary" size={50} />
                </Box>
            ) : null}
            {steps?.length ? (
                <Grid item={true} xs={12} sm={12} lg={11}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Button color={"primary"}
                            variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Send Link
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ py: 1, mt: 1, }}>
                        <StyledPaper
                            sx={{
                                my: 1,
                                mx: 'auto',
                                border: "1px solid #6ECA35",
                                borderRadius: 3
                            }}
                            elevation={0}
                        >
                            <VerticalLinearStepper steps={steps} />
                        </StyledPaper>
                    </Grid>
                    <Grid item={true} xs={11} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                        <Stack direction={"row"} spacing={2}>
                            {/* <Tooltip title="Can Change After Save" placement="left">
                                <Button color={"primary"}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button>
                            </Tooltip> */}
                            <Tooltip title="Can Not Change After Submit" placement="right">
                                <Button color={"primary"} onClick={() => saveAndSubmitStaffInfo()}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Submit
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            ) : null}
            {/* <CustomModalDraftListStaff
                openDraft={openDraft}
                handleModal={handleModal}
                staffDraftList={staffDraftList}
            /> */}
        </Grid>
    )
}

export default StaffOnBoarding;

import { Box, Button, CircularProgress, Divider, Grid, IconButton, MenuItem, Paper, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import PersonIcon from '@mui/icons-material/Person';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupsIcon from '@mui/icons-material/Groups';
import CustomCalendar from '../custom_calendar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchAndFilters from '../../../components/search_filters';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClientInfoCard, EmployeeCard, ParticipantCard } from '../../../components/cards';
import { DragAndDrop } from '../drag-and-drop';
import AutoSchedulingComponent from '../auto-scheduling';
import { useNavigate } from 'react-router-dom';
import { GetFilterVisitDayAndCheckList, GetServicePlans, GetServiceRequests } from '../../../services/service_request';
import { ServicePlansFilter } from '../../../services/finance_service';
import ResponsiveLayout from '../example';
import { GetStaffList, PreferenceForStaffWorkerById, ServiceShiftFilters, VisitDaysByServiceId, WorkingDaysByFilters } from '../../../services/schedule_service';
import { GetStaffDetailsWithId } from '../../../services/staff_service';


export const Scheduler = () => {
    const navigate = useNavigate();
    const [view, setView] = useState("week");
    const [weekList, setWeekList] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(moment());
    const [currentStartDate, setCurrentStartDate] = useState(moment());
    const [viewExpose, setViewExpose] = useState("participant");
    const [selectedDay, setSelectedDay] = useState(moment());
    const [hourList, setHourList] = useState([]);
    const [loadingData, setLoadingData] = useState(false)

    const [servicePlans, setServicePlans] = useState(null)
    const [getPlansFiltersById, loading, errors] = ServicePlansFilter();
    const [getVisitDaysByServiceId, loading_visit, error_visit] = VisitDaysByServiceId();
    const [getServiceShiftsByVisitId, loading_shift, error_shif] = ServiceShiftFilters();

    const getPlansForView = async () => {
        try {
            setLoadingData(true)
            const response = await getPlansFiltersById({
                variables: {
                    "filter": {
                        "service_request_status": {
                            "ne": "Rejected"
                        }
                    }
                }
            })
            if (response?.data) {
                const list = response?.data?.servicePlans?.data
                // console.log("list", list)
                const array = [];
                for (const key in list) {
                    const data = list[key].attributes;
                    const object = {
                        dbId: list[key].id,
                        created_date: data?.created_date,
                        service_request_status: data?.service_request_status,
                        service_request: {
                            id: data?.service_request_id?.data?.id,
                            funding_source: data?.service_request_id?.data?.attributes?.funding_source,
                            pay_travel_km: data?.service_request_id?.data?.attributes?.pay_travel_km,
                            billable: data?.service_request_id?.data?.attributes?.billable,
                            service_type: {
                                code: data?.service_request_id?.data?.attributes?.charge_band_rate_id.data?.attributes?.service_code?.data?.attributes?.support_item_number,
                                service_name: data?.service_request_id?.data?.attributes?.charge_band_rate_id.data?.attributes?.service_title,
                            }
                        },
                        client_detail: {
                            id: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.id,
                            first_name: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.first_name,
                            last_name: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.last_name,
                            ndis_number: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.ndis_number,
                            primary_diagnosis: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.primary_diagnosis,
                        },
                        staff_detail: {
                            id: data?.staff_id?.data?.id,
                            first_name: data?.staff_id?.data?.attributes?.first_name,
                            last_name: data?.staff_id?.data?.attributes?.last_name,
                            medicare_number: data?.staff_id?.data?.attributes?.medicare_number,
                            ndis_clearance_number: data?.staff_id?.data?.attributes?.ndis_clearance_number,
                            email_info: data?.staff_id?.data?.attributes?.email_info,
                        }
                    }
                    const response_visit = await getVisitDaysByServiceId({
                        variables: {
                            "filterCheck": {
                                "service_request_id": {
                                    "id": {
                                        "eq": object.service_request.id
                                    }
                                }
                            },
                            "filterVisit": {
                                "service_request_id": {
                                    "id": {
                                        "eq": object.service_request.id
                                    }
                                }
                            }
                        }
                    })

                    if (response_visit?.data) {
                        const visit_data = response_visit?.data?.visitDays?.data
                        const visit_check_list = response_visit?.data?.visitShiftCheckLists?.data
                        object.visit_data = visit_data;
                        object.visit_check_list = visit_check_list;
                        if (visit_data?.length > 0) {
                            const response_shifts = await getServiceShiftsByVisitId({
                                variables: {
                                    "filter": {
                                        "visit_day_id": {
                                            "id": {
                                                "eq": visit_data[0].id
                                            }
                                        }
                                    }
                                }
                            })
                            if (response_shifts?.data) {
                                const shifts_data = response_shifts?.data?.serviceShifts?.data
                                // console.log("shifts_data", shifts_data)
                                object.shifts_data = shifts_data;
                            }
                        }
                    }
                    array.push(object)
                }
                setServicePlans(array)
                setLoadingData(false)
            }
        } catch (error) {
            console.log("error", error, errors, error_visit, error_shif)
        }
    }

    useEffect(() => {
        getPlansForView()
    }, [])

    const statsus = {
        pending: "lightyellow",	//////When a Service Request is added
        plan: "lightblue",	//////After Auto Scheduling
        approved: "lightpurple",	//////After Company Admin Approved
        acknowledged: "lightorange",	//////After Staff Worker Accepted
        done: "lightgreen",	//////After the Company Admin Approved and Staff Worker is accepted
        rejected: "lightred",	//////After Staff Worker Rejected
    }

    // // // --------------------------Api----------------------------
    // const [listOfPendingRequest, loading] = GetServiceRequests();
    // const [getVisitDayAndCheckList, loading_list, error_list] = GetFilterVisitDayAndCheckList();
    // const [servicePlanData, loading_plan] = GetServicePlans()
    // const [serviceRequestsDetails, setServiceRequestsDetails] = useState([])

    // const getListOFRelationalData = async (data) => {
    //     try {
    //         const response = await getVisitDayAndCheckList({
    //             variables: {
    //                 "filterVisit": {
    //                     "service_request_id": {
    //                         "id": {
    //                             "eq": data?.id
    //                         }
    //                     }
    //                 },
    //                 "filterCheck": {
    //                     "service_request_id": {
    //                         "id": {
    //                             "eq": data?.id
    //                         }
    //                     }
    //                 }
    //             }
    //         })
    //         if (response?.data) {
    //             return (response?.data)
    //         }
    //     } catch (error) {
    //         let message = "";
    //         if (error?.message || error_list) {
    //             const errors = error_list;
    //             if (errors?.networkError) {
    //                 message = "Network error occurred. Please try again later"
    //             }
    //             if (errors?.graphQLErrors) {
    //                 message = errors.graphQLErrors[0]?.message
    //             }
    //             if (error?.message) {
    //                 message = error?.message;
    //             }
    //             console.log(message)
    //             // handleSnacksApper(category?.toUpperCase(), message, "error")
    //         }
    //     }
    // }

    // const maintainState = async () => {
    //     try {
    //         const copy = [...serviceRequestsDetails]
    //         for (const key in listOfPendingRequest) {
    //             const response = await getListOFRelationalData(listOfPendingRequest[key]);
    //             copy.push({
    //                 service: listOfPendingRequest[key],
    //                 visitShiftCheckLists: response?.visitShiftCheckLists?.data,
    //                 visitDays: response?.visitDays?.data[0],
    //             })
    //         }
    //         setServiceRequestsDetails(copy)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // useEffect(() => {
    //     if (listOfPendingRequest?.length) {
    //         maintainState();
    //     }
    // }, [listOfPendingRequest])
    // // // ---------------------------------------------------------

    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };

    const [participant, setParticipant] = useState([
        {
            name: "Abc client",
            serviceHistory: [
                {
                    date: "2024-01-16",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-18",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceProvider: "Muhammad Hamid"
        },
        {
            name: "ZXC client",
            serviceHistory: [
                {
                    date: "2024-01-17",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-19",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceProvider: "Muhammad Hamid"
        },
        {
            name: "VFR client",
            serviceHistory: [
                {
                    date: "2024-01-18",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-20",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceProvider: "Muhammad Hamid"
        },
        {
            name: "TRY client",
            serviceHistory: [
                {
                    date: "2024-01-22",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-24",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceProvider: "Muhammad Hamid"
        },
        {
            name: "ERT client",
            serviceHistory: [
                {
                    date: "2024-01-23",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-25",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceProvider: "Muhammad Hamid"
        },

    ]);

    const [employee, setEmployee] = useState([
        {
            name: "Abc Employee",
            serviceHistory: [
                {
                    date: "2024-01-16",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-18",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "pending"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: "Abc Client"
        },
        {
            name: "REW Employee",
            serviceHistory: [
                {
                    date: "2024-01-17",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-18",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "pending"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: "Abc Client"
        },
        {
            name: "UIO Employee",
            serviceHistory: [
                {
                    date: "2024-01-20",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-21",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "pending"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: "Abc Client"
        },
        {
            name: "GHJ Employee",
            serviceHistory: [
                {
                    date: "2024-01-22",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-23",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "pending"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: "Abc Client"
        },
        {
            name: "UYT Employee",
            serviceHistory: [
                {
                    date: "2024-01-16",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-18",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "pending"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: "Abc Client"
        },
        {
            name: "FRE Employee",
            serviceHistory: [
                {
                    date: "2024-01-16",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "ongoing",
                },
                {
                    date: "2024-01-18",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "pending"
                }
            ],
            spendTime: "04:00",
            Billable: "04:00",
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: "Abc Client"
        },

    ]);

    const generateDateList = (month) => {
        const startDate = moment(month).startOf('month');
        const endDate = moment(month).endOf('month');
        const daysInMonth = endDate.diff(startDate, 'days') + 1;
        const list = [];
        for (let i = 0; i < daysInMonth; i++) {
            const currentDate = moment(startDate).add(i, 'days');
            list.push({
                date: currentDate.format('YYYY-MM-DD'),
                day: currentDate.format('dddd'),
                month: currentDate.format('MMMM'),
            });
        }
        return list;
    };

    const generate7DaysList = (startDate) => {
        const list = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = moment(startDate).add(i, 'days');
            list.push({
                date: currentDate.format('YYYY-MM-DD'),
                day: currentDate.format('dddd'),
                month: currentDate.format('MMMM'),
            });
        }
        return list;
    };

    const switchDateRange = (increment) => {
        setCurrentStartDate((prevStartDate) => moment(prevStartDate).add(increment * 7, 'days'));
    };

    const switchMonth = (increment) => {
        setCurrentMonth((prevMonth) => moment(prevMonth).add(increment, 'months'));
    };

    const generateHourList = (selectedDate) => {
        const hours = Array.from({ length: 24 }, (_, index) => {
            const hourMoment = moment(selectedDate).startOf('day').add(index, 'hours');
            return {
                hour: hourMoment.format('HH:mm'),
                dateTime: hourMoment.format('YYYY-MM-DD HH:mm:ss'),
                date: hourMoment.format('YYYY-MM-DD'),
            };
        });
        return hours;
    };

    const switchDay = (increment) => {
        setSelectedDay((prevDay) => moment(prevDay).add(increment, 'day'));
    };

    useEffect(() => {
        setHourList(generateHourList(selectedDay));
    }, [selectedDay]);

    useEffect(() => {
        setDateList(generateDateList(currentMonth));
        setWeekList(generate7DaysList(currentStartDate));
    }, [currentStartDate, currentMonth]);

    return (
        <Grid container>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", flexDirection: { lg: "row", xs: "column", md: "row" } }}>
                <Grid item={true} xs={12} sm={3} md={1.3} lg={1.5} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                    <Stack spacing={2} direction="row">
                        <ToggleButtonGroup
                            color="primary"
                            value={viewExpose}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton sx={{ width: window.screen.width < 900 ? "calc(20vw)" : "calc(2.5vw)", }} value="participant">
                                <Tooltip title="Participant">
                                    <PersonIcon sx={{ fontSize: "1.7em" }} />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton sx={{ width: window.screen.width < 900 ? "calc(20vw)" : "calc(2.5vw)", }} value="groupby">
                                <Tooltip title="Goup by">
                                    <GroupsIcon sx={{ fontSize: "1.7em" }} />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton sx={{ width: window.screen.width < 900 ? "calc(20vw)" : "calc(2.5vw)", }} value="employee">
                                <Tooltip title="Employee">
                                    <EngineeringIcon sx={{ fontSize: "1.7em" }} />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} sm={3} md={4} lg={4} sx={{ display: "flex", py: { xs: 1, lg: 0 }, }}>
                    {view === "week" && (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-end", }}>
                                <Button onClick={() => switchDateRange(-1)}>
                                    <ArrowBackIosIcon />
                                </Button>
                            </Grid>
                            <Grid item={true} xs={8} sm={8} md={8} lg={10} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "1.1em", color: "#000000", fontFamily: "Roboto-Regular" }}>
                                    {currentStartDate.format('MMMM D')} - {moment(currentStartDate).add(7, 'days').format('MMMM D')}
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-start", }}>
                                <Button onClick={() => switchDateRange(1)}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {view === "month" && (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-end", }}>
                                <Button onClick={() => switchMonth(-1)}>
                                    <ArrowBackIosIcon />
                                </Button>
                            </Grid>
                            <Grid item={true} xs={8} sm={8} md={10} lg={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "1.1em", color: "#000000", fontFamily: "Roboto-Regular" }}>
                                    {currentMonth.format('MMMM YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-start", }}>
                                <Button onClick={() => switchMonth(1)}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {view === "day" && (
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-end", }}>
                                <Button onClick={() => switchDay(-1)}>
                                    <ArrowBackIosIcon />
                                </Button>
                            </Grid>
                            <Grid item={true} xs={8} sm={8} md={8} lg={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "1.1em", color: "#000000", fontFamily: "Roboto-Medium" }}>
                                    {selectedDay.format('MMMM DD YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={2} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "flex-start", }}>
                                <Button onClick={() => switchDay(1)}>
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item={true} xs={12} sm={4} md={4} lg={4} sx={{ py: { xs: 1 }, display: "flex", justifyContent: 'center' }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant={'outlined'} onClick={() => setView("week")} sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Week
                        </Button>
                        <Button variant={'outlined'} onClick={() => setView("month")} sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Month
                        </Button>
                        <Button variant={'outlined'} onClick={() => setView("day")} sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Day
                        </Button>
                        <Button variant={'outlined'} onClick={() => {
                            navigate("/admin-dashboard", {
                                state: {
                                    activeOption: 2
                                }
                            })
                        }} sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Swap Service
                        </Button>
                    </Stack>
                </Grid>
                <Grid item={true} xs={12} sm={4} md={4} lg={4} sx={{ display: "flex", py: { xs: 1 }, }}>
                    <SearchAndFilters topMargin={0} inputWidth={10} />
                </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", }}>
                {loadingData ? (
                    <Box sx={{
                        background: "rgba(255,255,255,0.0)",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <CircularProgress color="secondary" size={"3rem"} />
                    </Box>
                ) : (
                    <CustomCalendar
                        view={view}
                        weekList={weekList}
                        dateList={dateList}
                        participant={participant}
                        viewExpose={viewExpose}
                        employee={employee}
                        hourList={hourList}
                        servicePlans={servicePlans}
                    />
                )}
            </Grid>
        </Grid >
    );
}

export const Configurations = () => {
    // const navigate = useNavigate();
    const height = window.screen.height;
    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5.5
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        } else {
            return window.screen.width / 1.3
        }
    }
    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen])

    const [showHide, setShowHide] = React.useState('show');
    const [allAny, setAllAny] = React.useState('any');
    // const [andOr, setAndOr] = React.useState('and');
    const [conditonsBox, setConditionsBox] = useState([]);

    const [listOfConditions, setListOfConditions] = useState({
        title: "Condition",
        value: "equals",
        width: screenWidth,
        type: "drop_down",
        required: true,
        options: [
            {
                title: "equals",
                selected: true
            },
            {
                title: "does not equal",
                selected: false
            },
            {
                title: "is empty",
                selected: false
            },
            {
                title: "is not empty",
                selected: false
            },
            {
                title: "is greater than",
                selected: false
            },
            {
                title: "is greater than or equal to",
                selected: false
            },
            {
                title: "is less than",
                selected: false
            },
            {
                title: "is less than equal to",
                selected: false
            },
            {
                title: "is one of",
                selected: false
            },
            {
                title: "is not one of",
                selected: false
            },
            {
                title: "is between",
                selected: false
            },
        ]
    });

    const [options, setOptions] = useState([
        {
            title: "Option",
            value: "Availability",
            width: screenWidth,
            type: "drop_down",
            required: true,
            condition: _.cloneDeep(listOfConditions),
            options: [
                {
                    title: "Availability",
                    selected: true,
                },
                {
                    title: "Client",
                    selected: true,
                },
                {
                    title: "Gender",
                    selected: false,
                },
                {
                    title: "Age",
                    selected: false,
                },
                {
                    title: "Language",
                    selected: false,
                },
                {
                    title: "Religion",
                    selected: false,
                },
            ],
            nestedOptions: [
                {
                    title: "Availability",
                    value: "Yes",
                    width: screenWidth,
                    type: "drop_down",
                    required: true,
                    options: [
                        {
                            title: "Yes",
                            selected: true
                        },
                        {
                            title: "No",
                            selected: false
                        },
                    ]
                },
                {
                    title: "Client",
                    value: "",
                    width: screenWidth,
                    type: "text",
                    required: true,
                },
                {
                    title: "Gender",
                    value: "Male",
                    width: screenWidth,
                    type: "drop_down",
                    required: true,
                    options: [
                        {
                            title: "Male",
                            selected: true
                        },
                        {
                            title: "Female",
                            selected: false
                        },
                        {
                            title: "Others",
                            selected: false
                        }
                    ]
                },
                {
                    title: "Age",
                    value: 0,
                    width: screenWidth,
                    type: "number",
                    required: true,
                },
                {
                    title: "Language",
                    value: "English",
                    type: "drop_down",
                    width: screenWidth,
                    required: true,
                    options: [
                        {
                            title: "English",
                            selected: true
                        },
                        {
                            title: "Urdu",
                            selected: false
                        },
                        {
                            title: "Arabic",
                            selected: false
                        },
                        {
                            title: "Persian",
                            selected: false
                        }
                    ]
                },
                {
                    title: "Religion",
                    value: "Islam",
                    type: "drop_down",
                    width: screenWidth,
                    required: true,
                    options: [
                        {
                            title: "Islam",
                            selected: true
                        },
                        {
                            title: "Christianity",
                            selected: false
                        },
                        {
                            title: "Hinduism",
                            selected: false
                        }
                    ]
                },
            ]
        },
    ]);

    const [conditionsLogic, setConditionsLogic] = useState([
        {
            logicId: 1,
            // logicGates: "and",
            selectedOptions: _.cloneDeep(options)
        },
    ]);

    const handleChangeB = (index, newAdd) => {
        const copy = [...conditionsLogic];
        copy[index].logicGate = newAdd;
        if (conditionsLogic.length > 1) {
            const copy = [...conditionsLogic];
            for (const key in copy) {
                if (newAdd === "and") {
                    copy[key].logicGate = "and";
                    setAllAny("all");
                } else {
                    copy[key].logicGate = "or";
                    setAllAny("any");
                }
            }
        }
        setConditionsLogic(copy);
    };

    const addAnotherLogic = () => {
        const copy = [...conditionsLogic];
        copy.push({
            logicId: copy.length + 1,
            logicGate: "or",
            selectedOptions: _.cloneDeep(options)
        })
        setConditionsLogic(copy);
    }
    const deleteLogic = (index) => {
        const copy = [...conditionsLogic];
        const filter = copy.filter(e => e.logicId !== index);
        setConditionsLogic(filter);
    }
    const handleChange = (event, newAdd) => {
        setShowHide(newAdd);
    };

    const handleChangeA = (event, newAdd) => {
        const copy = [...conditionsLogic];
        setAllAny(newAdd);
        if (conditionsLogic.length > 1) {
            for (const key in copy) {
                if (newAdd === "all") {
                    copy[key].logicGate = "and";
                } else {
                    copy[key].logicGate = "or";
                }
            }
        }
        setConditionsLogic(copy);
    };

    return (
        <Grid container sx={{ my: 4, }} >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: { xs: "15%", lg: "1%" } }}>
                <Grid item={true} xs={11} sm={10} md={8} lg={8} sx={{ p: 3, }} component={Paper} elevation={2}>
                    {/* heading */}
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ py: 1, display: "flex", }}>
                        <Grid item={true} xs={12} sm={6} md={6} lg={6}>
                            <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1.3em" }}>
                                Conditons
                            </Typography>
                            <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "0.9em", color: "#808080" }}>
                                Conditons allow fields to show or hide based on responses in other field.
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={6} lg={6} sx={{ px: 2, display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                            <Button variant='contained'>
                                Save & Generate
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 2, borderRadius: 2, border: "1px solid lightgrey", }}>
                        {/* handle logics */}
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: { lg: "flex", md: "flex" }, height: { xs: "auto", lg: 55 } }}>
                            <Grid item={true} xs={12} sm={6} md={6} lg={3} sx={{ display: "flex", }}>
                                <Grid item xs={5} sm={4} md={4} lg={6} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={showHide}
                                        exclusive
                                        onChange={handleChange}
                                        aria-label="ShowHide"
                                    >
                                        <ToggleButton sx={{ fontFamily: "Roboto-Medium" }} value="hide">Hide</ToggleButton>
                                        <ToggleButton sx={{ fontFamily: "Roboto-Medium" }} value="show">Show</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item xs={7} sm={4} md={4} lg={6} sx={{ px: 1, display: "flex", justifyContent: { xs: "flex-start", lg: "center", md: "center" }, alignItems: "center" }}>
                                    <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1.1em" }}>
                                        this field if
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item={true} xs={12} sm={6} md={6} lg={9} sx={{ display: "flex", }}>
                                <Grid item xs={5} sm={4} md={4} lg={2} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={allAny}
                                        exclusive
                                        onChange={handleChangeA}
                                        aria-label="AllAny"
                                    >
                                        <ToggleButton sx={{ fontFamily: "Roboto-Medium" }} value="all">All</ToggleButton>
                                        <ToggleButton sx={{ fontFamily: "Roboto-Medium" }} value="any">Any</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                                <Grid item xs={7} sm={4} md={4} lg={10} sx={{ p: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                    <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1.1em" }}>
                                        of the following are met:
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* condition  */}
                        {conditionsLogic?.map((value, iP) => {
                            return (
                                <Grid item={true} key={value.logicId} xs={12} sm={12} md={12} lg={12} sx={{ my: 1, }}>
                                    {/* logic gates */}
                                    {value?.logicGate && iP !== 0 ? (
                                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", py: 2, }}>
                                            <Grid item={true} xs={5} sm={5} md={5} lg={6} sx={{ px: 1, display: "flex", alignItems: "center" }}>
                                                <div style={{
                                                    border: "1px solid lightgrey",
                                                    width: "100%"
                                                }}></div>
                                            </Grid>
                                            <Grid item={true} xs={4} sm={1} md={1} lg={1} sx={{ display: "flex", height: 40, justifyContent: "center" }}>
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={value.logicGate}
                                                    exclusive
                                                    aria-label="andor"
                                                    onChange={(e) => handleChangeB(iP, e.target.value)}
                                                >
                                                    <ToggleButton sx={{ fontFamily: "Roboto-Medium" }} value="and">
                                                        And
                                                    </ToggleButton>
                                                    <ToggleButton sx={{ fontFamily: "Roboto-Medium" }} value={"or"}>
                                                        Or
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                            <Grid item={true} xs={5} sm={5} md={5} lg={6} sx={{ px: 1, display: "flex", alignItems: "center" }}>
                                                <div style={{
                                                    border: "1px solid lightgrey",
                                                    width: "100%"
                                                }}></div>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ borderRadius: 2, p: 2, display: { lg: "flex", md: "flex" }, border: "1px solid lightgrey", backgroundColor: "#e7e7e7" }}>
                                        {/* inputs */}
                                        <Grid xs={12} sm={11.5} md={11.5} lg={11.5} sx={{ display: { lg: "flex", md: "flex" }, justifyContent: "flex-end" }}>
                                            {value.selectedOptions?.map((v, i) => {
                                                return (
                                                    <>
                                                        <TextField
                                                            select={true}
                                                            htmlFor={v.title}
                                                            key={i}
                                                            required={v.required}
                                                            sx={{ m: 1, width: { xs: "95%", lg: v.width } }}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: "#000000"
                                                                }
                                                            }}
                                                            value={v.value}
                                                            onChange={(e) => {
                                                                const copy = [...conditionsLogic];
                                                                copy[iP].selectedOptions[i].value = e.target.value;
                                                                setConditionsLogic(copy);
                                                            }}
                                                        // helperText={"Please select your " + v.title}
                                                        >
                                                            {v?.options?.map((option) => (
                                                                <MenuItem key={option.title} value={option.title}>
                                                                    {option.title}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField >
                                                        <TextField
                                                            select={true}
                                                            htmlFor={v.condition.title}
                                                            key={i}
                                                            required={v.condition.required}
                                                            sx={{ m: 1, width: { xs: "95%", lg: v.condition.width } }}
                                                            value={v.condition.value}
                                                            InputLabelProps={{
                                                                style: {
                                                                    color: "#000000"
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                const copy = [...conditionsLogic];
                                                                copy[i].selectedOptions[0].condition.value = e.target.value;
                                                                setConditionsLogic(copy);
                                                            }}
                                                        >
                                                            {v.condition?.options?.map((option) => (
                                                                <MenuItem key={option.title} value={option.title}>
                                                                    {option.title}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                        {
                                                            v?.nestedOptions.map((vN, iN) => {
                                                                if (v.value.includes(vN.title)) {
                                                                    return (
                                                                        <>
                                                                            {vN.type === "number" || vN.type === "text" ? (
                                                                                <TextField
                                                                                    key={iN}
                                                                                    type={vN.type}
                                                                                    required={vN.required}
                                                                                    label={vN.title}
                                                                                    htmlFor={vN.title}
                                                                                    value={vN.value}
                                                                                    sx={{ m: 1, width: { xs: "95%", lg: vN.width } }}
                                                                                    onChange={(e) => {
                                                                                        const copy = [...conditionsLogic];
                                                                                        copy[i].selectedOptions[0].nestedOptions[iN].value = e.target.value;
                                                                                        setConditionsLogic(copy);
                                                                                    }}
                                                                                />
                                                                            ) : null}
                                                                            {vN.type === "drop_down" ? (
                                                                                <TextField
                                                                                    key={iN}
                                                                                    select={true}
                                                                                    // label={vN.title}
                                                                                    htmlFor={vN.title}
                                                                                    required={vN.required}
                                                                                    sx={{ m: 1, width: { xs: "95%", lg: vN.width } }}
                                                                                    defaultValue={vN.value}
                                                                                    InputLabelProps={{
                                                                                        style: {
                                                                                            color: "#000000"
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {vN?.options?.map((option) => (
                                                                                        <MenuItem key={option.title} value={option.title}>
                                                                                            {option.title}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </TextField>
                                                                            ) : null}
                                                                        </>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </>
                                                )
                                            })}
                                        </Grid>
                                        {/* delete node */}
                                        <Grid item xs={12} sm={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <IconButton onClick={() => deleteLogic(value.logicId)} aria-label="delete" size="medium">
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", py: 2, justifyContent: "center" }}>
                            <Grid item={true} xs={5} sm={5} md={5} lg={6} sx={{ px: 1, display: "flex", alignItems: "center" }}>
                                <div style={{
                                    border: "1px solid lightgrey",
                                    width: "100%"
                                }}></div>
                            </Grid>
                            <Grid item={true} xs={8} sm={3} md={3} lg={2} sx={{ display: "flex", justifyContent: "center", height: 40 }}>
                                <Button onClick={() => addAnotherLogic()} component="label" variant="outlined" startIcon={<AddCircleOutlineIcon />}>
                                    Add / or
                                </Button>
                            </Grid>
                            <Grid item={true} xs={5} sm={5} md={5} lg={6} sx={{ px: 1, display: "flex", alignItems: "center" }}>
                                <div style={{
                                    border: "1px solid lightgrey",
                                    width: "100%"
                                }}></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export const SortableList = ({ children }) => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>;
};

export const AutoScheduling = () => {
    const [viewExpose, setViewExpose] = useState("participant");
    const [defualtSetting, setDefualtSetting] = useState(false);
    const [loadingData, setLoadingData] = useState(false)
    // // // Api-------------------------------------------------
    const [waitingForServices, setWaitingForServices] = useState(null);
    const [modifiedStaffData, setModifiedStaffData] = useState(null);
    const [getStaffLists, loading_staff] = GetStaffList();
    const [getStaffDetailsByID, loading_details] = GetStaffDetailsWithId();
    const [getWorkingDaysByShiftPref, loading_w] = WorkingDaysByFilters();
    const [getPlansFiltersById, loading, errors] = ServicePlansFilter();
    const [getVisitDaysByServiceId, loading_visit, error_visit] = VisitDaysByServiceId();
    const [getServiceShiftsByVisitId, loading_shift, error_shif] = ServiceShiftFilters();
    const [getPreferenceForStaffWorker, loading_pref, error_pref] = PreferenceForStaffWorkerById();

    const handleLovsDataById = async (staffList) => {
        try {
            const staffData = _.cloneDeep(staffList)
            const response_list = await getPlansFiltersById()
            if (response_list?.data) {
                const list = response_list?.data?.servicePlans?.data;
                // const unmatchedStaff = staffData.filter(staffMember =>
                //     !list.some(plan => plan.attributes.staff_id.data?.id === staffMember.id)
                // );
                for (const key in staffData) {
                    const respons = await getStaffDetailsByID({
                        variables: {
                            "filter": {
                                "staff_detail_id": {
                                    "id": {
                                        "eq": staffData[key]?.id
                                    }
                                }
                            },
                            "filters": {
                                "staff_detail_id": {
                                    "id": {
                                        "eq": staffData[key]?.id
                                    }
                                }
                            }
                        }
                    })
                    const copy = { ...staffData[key] }
                    copy.attributes.lovs_answers = respons.data.staffAnswerLovsReferences.data;
                    setModifiedStaffData(staffData);
                    // setLoadingData(false);
                }
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const handleStaffsData = async () => {
        try {
            setLoadingData(true)
            const respons_staff = await getStaffLists();
            if (respons_staff?.data) {
                const staffList = respons_staff?.data?.staffDetails?.data;
                handleLovsDataById(staffList)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        handleStaffsData()
    }, [])

    const getPlansForView = async () => {
        try {
            // /// /// -------------0-0---------------------------
            const response = await getPlansFiltersById({
                variables: {
                    "filter": {
                        "service_request_status": {
                            "eq": "Rejected"
                        }
                    }
                }
            })
            if (response?.data) {
                const list = response?.data?.servicePlans?.data
                // console.log("list", list)
                const array = [];
                for (const key in list) {
                    const data = list[key].attributes;
                    const object = {
                        plan_id: list[key].id,
                        created_date: data?.created_date,
                        service_request_status: data?.service_request_status,
                        service_request: {
                            id: data?.service_request_id?.data?.id,
                            funding_source: data?.service_request_id?.data?.attributes?.funding_source,
                            pay_travel_km: data?.service_request_id?.data?.attributes?.pay_travel_km,
                            billable: data?.service_request_id?.data?.attributes?.billable,
                            service_type: {
                                code: data?.service_request_id?.data?.attributes?.service_type?.data?.attributes?.code,
                                service_name: data?.service_request_id?.data?.attributes?.service_type?.data?.attributes?.service_name,
                            }
                        },
                        client_detail: {
                            id: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.id,
                            first_name: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.first_name,
                            last_name: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.last_name,
                            ndis_number: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.ndis_number,
                            primary_diagnosis: data?.service_request_id?.data?.attributes?.client_detail_id?.data?.attributes?.primary_diagnosis,
                        },
                        // staff_detail: {
                        //     id: data?.staff_id?.data?.id,
                        //     first_name: data?.staff_id?.data?.attributes?.first_name,
                        //     last_name: data?.staff_id?.data?.attributes?.last_name,
                        //     medicare_number: data?.staff_id?.data?.attributes?.medicare_number,
                        //     ndis_clearance_number: data?.staff_id?.data?.attributes?.ndis_clearance_number,
                        //     email_info: data?.staff_id?.data?.attributes?.email_info,
                        // }
                    }

                    const respons_cl = await getPreferenceForStaffWorker({
                        variables: {
                            "filter": {
                                "client_detail_id": {
                                    "id": {
                                        "eq": object.client_detail.id
                                    }
                                }
                            }
                        }
                    })

                    if (respons_cl?.data) {
                        const preference = respons_cl?.data?.preferenceForStaffWorkers?.data[0]
                        object.preference = preference;
                    }

                    const response_visit = await getVisitDaysByServiceId({
                        variables: {
                            "filterCheck": {
                                "service_request_id": {
                                    "id": {
                                        "eq": object.service_request.id
                                    }
                                }
                            },
                            "filterVisit": {
                                "service_request_id": {
                                    "id": {
                                        "eq": object.service_request.id
                                    }
                                }
                            }
                        }
                    })

                    if (response_visit?.data) {
                        const visit_data = response_visit?.data?.visitDays?.data
                        const visit_check_list = response_visit?.data?.visitShiftCheckLists?.data
                        object.visit_data = visit_data;
                        object.visit_check_list = visit_check_list;
                        const response_shifts = await getServiceShiftsByVisitId({
                            variables: {
                                "filter": {
                                    "visit_day_id": {
                                        "id": {
                                            "eq": visit_data[0].id
                                        }
                                    }
                                }
                            }
                        })
                        if (response_shifts?.data) {
                            const shifts_data = response_shifts?.data?.serviceShifts?.data
                            // console.log("shifts_data", shifts_data)
                            object.shifts_data = shifts_data;
                            for (const keyS in shifts_data) {
                                const pref_working_hr = await getWorkingDaysByShiftPref({
                                    variables: {
                                        "filter": {
                                            "day_start_time": { "eq": shifts_data[keyS].attributes.shift_start.slice(0, 8) },
                                            "day_end_time": { "eq": shifts_data[keyS].attributes.shift_end.slice(0, 8) }
                                        }
                                    }
                                })

                                if (pref_working_hr?.data) {
                                    const staff_pref = pref_working_hr.data.workingDays.data;
                                    // console.log("staff_pref", staff_pref)
                                    object.staff_pref = staff_pref;
                                }
                            }
                        }
                    }
                    array.push(object)
                }
                setWaitingForServices(array)
                setLoadingData(false)
            }
        } catch (error) {
            console.log("error", error, errors, error_visit, error_shif)
        }
    }

    useEffect(() => {
        getPlansForView()
    }, [])


    const handleChange = (event, newAlignment) => {
        setViewExpose(newAlignment);
    };
    const [participant, setParticipant] = useState([
        {
            name: "Farha",
            serviceRequired: [
                {
                    dateFrom: "2024-01-30",
                    dateTo: "2024-02-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Zara",
            serviceRequired: [
                {
                    dateFrom: "2024-02-17",
                    dateTo: "2024-03-17",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 2,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Veena",
            serviceRequired: [
                {
                    dateFrom: "2024-03-17",
                    dateTo: "2024-05-17",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 3,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Tahira",
            serviceRequired: [
                {
                    dateFrom: "2024-05-17",
                    dateTo: "2024-08-17",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 4,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Elma",
            serviceRequired: [
                {
                    dateFrom: "2024-03-15",
                    dateTo: "2024-03-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 5,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Farha",
            serviceRequired: [
                {
                    dateFrom: "2024-03-15",
                    dateTo: "2024-03-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 6,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Tahir",
            serviceRequired: [
                {
                    dateFrom: "2024-03-15",
                    dateTo: "2024-03-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 7,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Ahsan",
            serviceRequired: [
                {
                    dateFrom: "2024-03-15",
                    dateTo: "2024-03-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 8,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Zubair",
            serviceRequired: [
                {
                    dateFrom: "2024-03-15",
                    dateTo: "2024-03-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 9,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Xender",
            serviceRequired: [
                {
                    dateFrom: "2024-03-15",
                    dateTo: "2024-03-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 10,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
        {
            name: "Jhon",
            serviceRequired: [
                {
                    dateFrom: "2024-03-15",
                    dateTo: "2024-03-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    status: "waiting",
                },
            ],
            id: 11,
            serviceType: "Core: Assistance with self care",
            serviceProvider: null
        },
    ]);
    // const participantId = useMemo(() => participant.map((col) => col.id), [participant]);
    const [employee, setEmployee] = useState([
        {
            name: "Saba",
            serviceTime: [
                {
                    dateFrom: "2024-01-30",
                    dateTo: "2024-02-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    jobStatus: "Pending",
                },
            ],
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: [3, 8]
        },
        {
            name: "salman",
            serviceTime: [
                {
                    dateFrom: "2024-01-30",
                    dateTo: "2024-02-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    jobStatus: "Pending",
                },
            ],
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: [2]
        },
        {
            name: "Rameez",
            serviceTime: [
                {
                    dateFrom: "2024-01-30",
                    dateTo: "2024-02-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    jobStatus: "Pending",
                },
            ],
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: []
        },
        {
            name: "Roshan",
            serviceTime: [
                {
                    dateFrom: "2024-01-30",
                    dateTo: "2024-02-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    jobStatus: "Pending",
                },
            ],
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: []
        },
        {
            name: "Uzma",
            serviceTime: [
                {
                    dateFrom: "2024-01-30",
                    dateTo: "2024-02-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    jobStatus: "Pending",
                },
            ],
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: [5]
        },
        {
            name: "Farzana",
            serviceTime: [
                {
                    dateFrom: "2024-01-30",
                    dateTo: "2024-02-30",
                    startTime: "08:00",
                    endTime: "17:00",
                    jobStatus: "Pending",
                },
            ],
            id: 1,
            serviceType: "Core: Assistance with self care",
            serviceTo: [1, 8]
        },
    ]);


    return (
        <Grid container sx={{ height: "calc(85vh)" }}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", p: 1, }}>
                {/* <Grid item={true} xs={12} sm={4} md={4} lg={4} >
                    <Stack spacing={2} direction="row">
                        <ToggleButtonGroup
                            color="primary"
                            value={viewExpose}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton sx={{ width: window.screen.width < 900 ? "calc(20vw)" : "calc(3.5vw)", }} value="participant">
                                <Tooltip title="Participant">
                                    <PersonIcon sx={{ fontSize: "1.5em" }} />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton sx={{ width: window.screen.width < 900 ? "calc(20vw)" : "calc(3.5vw)", }} value="groupby">
                                <Tooltip title="Goup by">
                                    <GroupsIcon sx={{ fontSize: "1.5em" }} />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton sx={{ width: window.screen.width < 900 ? "calc(20vw)" : "calc(3.5vw)", }} value="employee">
                                <Tooltip title="Employee">
                                    <EngineeringIcon sx={{ fontSize: "1.5em" }} />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Grid> */}
                {/* <Grid item={true} xs={12} sm={4} md={4} lg={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                    <Grid item={true} xs={4} sm={4} md={4} lg={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography sx={{ fontFamily: "Roboto-Medium" }}>Defualt Settings</Typography>
                            <Switch checked={defualtSetting} color="primary" onChange={() => setDefualtSetting(!defualtSetting)} />
                            <Typography sx={{ fontFamily: "Roboto-Medium" }}>Custom Settings</Typography>
                        </Stack>
                    </Grid>
                </Grid> */}
                <Grid item={true} xs={12} sm={8} md={8} lg={8} >
                    <SearchAndFilters topMargin={0} inputWidth={6} />
                </Grid>
            </Grid>
            {loadingData ? (
                <Box sx={{
                    background: "rgba(255,255,255,0.3)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress color="secondary" size={"3rem"} />
                </Box>
            ) : (
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ px: 1, display: 'flex' }}>
                    {/* <DragAndDrop /> */}
                    {/* <ResponsiveLayout /> */}
                    <AutoSchedulingComponent
                        handleStaffsData={handleStaffsData}
                        getPlansForView={getPlansForView}
                        waitingForServices={waitingForServices}
                        modifiedStaffData={modifiedStaffData}
                    />
                </Grid>
            )}
        </Grid >
    )
}
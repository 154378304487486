import { Alert, AlertTitle, Box, Button, CircularProgress, Grid, MenuItem, Rating, Slide, Snackbar, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DraftEditor from "../../../components/Editor/DraftEditor";
import { useSelector, useDispatch } from "react-redux";
import { client_id, client_onboard_data_lists } from "../../../middleware/reducers/client_onboarding_reducer";
import _ from "lodash"
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CreateAdministrativeNote, CreateAdmissionInformation, CreateCheckListAlertsNotification, CreateClientAnswerLovsReference, CreateClientDetail, CreateClientGoal, CreateClientNote, CreateClientQuestionAnswerRef, CreateContactDetailForClient, CreateEmergencyContactDetailForClient, CreateMedicationsForClient, CreateOnboardMedicalHistoryDetail, CreatePlanReview, CreatePreferenceForStaffWorker, CreateReferringDoctorInformation, CreateServiceInformation } from "../../../services/client_service";
import { UpdateClientDetail } from "../../../services/admin_service";
import moment from "moment";
import { GetChargeBandDetails } from "../../../services/finance_service";

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const Row = (props) => {
    const { row, screenWidth, handleIndividualRiskChanges, pIndex } = props;
    return (
        <React.Fragment>
            <Grid container >
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-start", }} >
                    <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, }}>
                        <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                            {row?.title}
                        </Typography>
                    </Grid>
                </Grid>
                {row?.childOptions?.map((value, index) => {
                    if (value?.type === "Select") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, justifyContent: "flex-start", }}>
                                <TextField
                                    select={true}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title}
                                    InputLabelProps={{
                                        style: {
                                            textTransform: "capitalize"
                                        }
                                    }}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                >
                                    {value?.options?.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )
                    }
                    if (value?.type === "Text") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={6} lg={3} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, display: "flex", justifyContent: "flex-start", }}>
                                <TextField
                                    type={"text"}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title}
                                    multiline={true}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                />
                            </Grid>
                        )
                    }
                })}
            </Grid>
        </React.Fragment >
    );
}

const RowHRA = (props) => {
    const { row, screenWidth, handleIndividualRiskChanges, pIndex } = props;

    return (
        <React.Fragment>
            <Grid container >
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "flex-start", }} >
                    <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, }}>
                        <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                            {row?.title}
                        </Typography>
                    </Grid>
                </Grid>
                {row?.childOptions?.map((value, index) => {
                    if (value?.type === "Select") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={4} lg={2} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, justifyContent: "flex-start", }}>
                                <TextField
                                    select={true}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title}
                                    InputLabelProps={{
                                        style: {
                                            textTransform: "capitalize"
                                        }
                                    }}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                >
                                    {value?.options?.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )
                    }
                    if (value?.type === "Text") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={6} md={6} lg={3} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", p: 1, display: "flex", justifyContent: "flex-start", }}>
                                <TextField
                                    type={"text"}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    label={value.title}
                                    multiline={true}
                                    value={value.value}
                                    sx={{ width: screenWidth }}
                                    onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)}
                                />
                            </Grid>
                        )
                    }
                    if (value?.type === "rating") {
                        return (
                            <Grid item={true} key={value.title + index} xs={12} sm={4} md={4} lg={4} sx={{ mx: 2, display: "flex", justifyContent: "flex-start", flexDirection: 'column' }}>
                                <Grid sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", }}>
                                    <Typography sx={{ fontFamily: "Roboto-Regular", }}>
                                        {value.title}
                                    </Typography>
                                </Grid>
                                <Grid item={true} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center", }}>
                                    <Rating sx={{ fontSize: "2.5rem" }} id="number-feedback" value={Number(value.value)} classid='rating-size' onChange={(e) => handleIndividualRiskChanges(e.target.value, pIndex, index)} />
                                </Grid>
                            </Grid>
                        )
                    }
                })}

            </Grid>
        </React.Fragment>
    );
}

const RowSP = (props) => {
    const { row, handleIndividualRiskChanges, pIndex } = props;
    // console.log("row?.order_unord_json", row?.order_unord_json)
    return (
        <Grid item={true} xs={11} md={5} lg={5.8} sx={{ mx: 1 }}>
            <Grid item={true} sx={{ m: 1, display: 'flex', flexWrap: 'wrap', }}>
                <span style={{ fontSize: "1.3em", fontFamily: "Roboto-Bold", }}>
                    {row?.title}
                </span>
            </Grid>
            <Grid item={true} xs={12} md={12} lg={12} >
                <Grid item={true} xs={12} md={12} lg={12} sx={{ m: 1, }} >
                    <DraftEditor data={row?.order_unord_json} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export const DynamicInputs = ({ can_create_multiple, inputsList, inputType, questionList, categoryValue, module }) => {
    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5.2
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        } else {
            return window.screen.width / 1.3
        }
    }
    const dispatch = useDispatch()
    const [screenWidth, setScreenWidth] = useState(width);
    const [dynamiInputs, setDynamicInputList] = useState(null);
    const [dynamicQuestions, setDynamicQuestions] = useState(null);
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [chargeBandsOption, setChargeBandsOption] = useState({
        title: "Charge Range",
        value: {
            title: "Please select charge range",
            id: "DF1"
        },
        width: screenWidth,
        type: "drop_down",
        required: false,
        multiline: false,
        error: null,
        options: [
            {
                title: "Please select charge range",
                id: "DF1"
            },
        ]
    })
    // // // // ======================================================================================
    const _saving_list = useSelector((state) => state._client_onboarding_module.client_onboard_data_list);
    const _client_id = useSelector((state) => state._client_onboarding_module.client_id);
    // /// /// ==========================================================================================================
    const [getAvailableChargeOption, loading_band, error_band] = GetChargeBandDetails()
    // /// /// ==========================================================================================================
    const [apiCreateClient, loading_client, error_client] = CreateClientDetail();
    const [apiCreateClientLovs, loading_lovs, error_lovs] = CreateClientAnswerLovsReference();
    const [apiCreateContactDetail, loading_contact, error_contact] = CreateContactDetailForClient();
    const [apiCreateEmergencyContact, loading_emergency, error_emergency] = CreateEmergencyContactDetailForClient();
    const [apiCreateAdministrative, loading_adm, error_adm] = CreateAdministrativeNote()
    const [apiCreateServiceInfo, loading_service, error_service] = CreateServiceInformation();
    const [apiCreatePreferenceForStaff, loading_preference, error_preference] = CreatePreferenceForStaffWorker();
    const [apiCreateClientNote, loading_note, error_note] = CreateClientNote();
    const [apiCreateClientGoal, loading_goal, error_goal] = CreateClientGoal();
    const [apiCreateCheckList, loading_check, error_check] = CreateCheckListAlertsNotification();
    const [apiCreatePlanReview, loading_review, error_review] = CreatePlanReview()
    const [apiUpdateClientDetail, loading_update, error_update] = UpdateClientDetail();
    const [apiCreateMedications, loading_medi, error_medi] = CreateMedicationsForClient();
    const [apiCreateMedicationHistory, loading_history, error_history] = CreateOnboardMedicalHistoryDetail();
    const [apiCreateReferringDoctor, loading_doctor, error_doctor] = CreateReferringDoctorInformation();
    const [apiCreateAdmission, loading_admission, error_admission] = CreateAdmissionInformation();
    const [apiClientQuestionAnswer, loading_question, error_question] = CreateClientQuestionAnswerRef();

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const getAvailableChargeList = async () => {
        try {
            const response = await getAvailableChargeOption();
            if (response?.data) {
                addChargeToState(response?.data?.chargeBandDetails?.data);
            }
        } catch (error) {
            console.log("error", error, error_band)
        }
    }

    const addChargeToState = (chargeBand) => {
        if (chargeBand?.length) {
            const copy = { ...chargeBandsOption }
            for (const key in chargeBand) {
                const data = chargeBand[key]?.attributes
                const find = copy.options.find((e) => e?.id === chargeBand[key]?.id)
                if (!find) {
                    const obj = {
                        title: data.charge_band_name,
                        id: chargeBand[key]?.id
                    }
                    copy.options.push(obj)
                }
            }
            setChargeBandsOption(copy)
        }
    }

    useEffect(() => {
        if (inputsList?.length) {
            const copy = [];
            for (const key in inputsList) {
                const type = inputsList[key].attributes?.design_frontend_input_type?.data?.attributes?.title;
                const label = inputsList[key].attributes?.input_name;
                const required = inputsList[key].attributes?.required;
                const active = inputsList[key].attributes?.active;
                const multiline = inputsList[key].attributes?.multiline;
                const have_child_option = inputsList[key].attributes?.have_child_option;
                const drop_down_options = inputsList[key].attributes?.drop_down_options?.data;
                const disabled = inputsList[key].attributes?.disabled;
                const placeholder = inputsList[key].attributes?.placeholder;

                if (active) {
                    let obj = {
                        title: label,
                        value: type?.toLowerCase() === "date" ? moment().format("YYYY-MM-DD") : type?.toLowerCase() === "time" ? moment().format("HH:MM:SS") : "",
                        width: screenWidth,
                        type: type,
                        required: required,
                        multiline: multiline,
                        error: null,
                        category: "text",
                        disabled: disabled,
                        placeholder: placeholder
                    }
                    if (have_child_option) {
                        obj.options = drop_down_options;
                        obj.value = drop_down_options[0].attributes.title;
                    }
                    copy.push(obj)
                }
            }
            setDynamicInputList(copy);
            handleStateValueWithDynamicForm(copy);
        }
        if (questionList?.length) {
            const copy = [];
            for (const key in questionList) {
                let object = {}
                object.title = questionList[key]?.attributes?.question;
                object.active = questionList[key]?.attributes?.active;
                object.width = screenWidth;
                object.multiline = false;
                object.error = null;
                object.category = "questions";
                const answer_type = questionList[key]?.attributes?.answer_type[0];
                if (answer_type?.__typename?.includes("Text")) {
                    object.type = "Text";
                    object.value = answer_type?.details_value || "";
                    object.required = false;
                    object.multiline = object.title.includes("Comments") ? true : false
                }
                if (answer_type?.__typename?.includes("Single")) {
                    object.type = "Select";
                    object.value = "No";
                    object.required = true;
                    object.options = answer_type?.drop_down_option_lovs?.data
                }
                if (answer_type?.__typename?.includes("IndividualRisk")) {
                    object.type = "IndividualRisk";
                    object.childOptions = [
                        {
                            type: "Select",
                            title: "Risk To Individual",
                            value: answer_type?.risk_options?.risk_to_individual_value || "Green",
                            options: answer_type?.risk_options?.risk_to_individual?.data,
                            required: true,
                        },
                        {
                            type: "Select",
                            title: "Risk To Staff",
                            value: answer_type?.risk_options?.risk_to_staff_value || "Green",
                            options: answer_type?.risk_options?.risk_to_staff?.data,
                            required: true,
                        },
                        {
                            type: "Select",
                            title: "Risk To Other",
                            value: answer_type?.risk_options?.risk_to_other_value || "Green",
                            options: answer_type?.risk_options?.risk_to_other?.data,
                            required: true,
                        },
                        {
                            type: "Text",
                            title: "Management Guides",
                            value: answer_type?.management_guides_value || "",
                            required: false,
                            multiline: true
                        },
                        {
                            type: "Text",
                            title: "Source Of Information",
                            value: answer_type?.source_of_information_value || "",
                            required: false,
                            multiline: true
                        },
                    ]
                }
                if (answer_type?.__typename?.includes("HomeRisk")) {
                    object.type = "HomeRisk";
                    object.childOptions = [
                        {
                            type: "Select",
                            title: "Selected Option",
                            value: answer_type?.selected_option_value || "No",
                            options: answer_type?.selected_options?.data,
                            required: true,
                        },
                        {
                            type: "Text",
                            title: "Details Action",
                            value: answer_type?.details_action_value || "",
                            required: false,
                            multiline: true
                        },
                        {
                            type: "rating",
                            title: "Rating",
                            value: answer_type?.rating || "0",
                            required: false,
                            multiline: true
                        },
                    ]
                }
                if (answer_type?.__typename?.includes("AnswersList")) {
                    object.type = "AnswersList";
                    object.order_unord_json = answer_type?.order_unord_json
                }
                copy.push(object)
            }
            setDynamicQuestions(copy)
            handleStateValueWithDynamicQuestions(copy);
        }
        if (chargeBandsOption.options.length <= 1) {
            getAvailableChargeList()
        }
    }, [])

    const [snacks, setSnack] = useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });
        // / // // // display message
        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const handleChangeValueInputs = (e, index) => {
        try {
            const copy = [...dynamiInputs];
            copy[index].value = e.target.value;
            if (copy[index].error !== null) {
                copy[index].error = null;
            }
            setDynamicInputList(copy);
        } catch (error) {
            console.log("error", error);
        }
    }

    const savingDataAsPerCategory = () => {
        const copy = [...dynamiInputs];
        let findError = false;
        const todayDate = new Date().getMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear();
        for (const key in copy) {
            if (
                (copy[key].type.toLowerCase() === "email" && copy[key].required && !validateEmail(copy[key].value)) ||
                (copy[key].type.toLowerCase() === "text" && copy[key].required && (copy[key].value === "" || copy[key].value === " ")) ||
                (copy[key].type.toLowerCase() === "date" && copy[key].required && (copy[key].value === "" || copy[key].value === todayDate)) ||
                (copy[key].type.toLowerCase() === "number" && copy[key].required && (copy[key].value === "" || copy[key].value < 10)) ||
                (copy[key].type.toLowerCase() === "time" && copy[key].required && (copy[key].value === ""))
            ) {
                copy[key].error = "Required";
                findError = true;
                setDynamicInputList(copy);
            }
        }
        if (findError) {
            return false
        }
        const fCopy = _.cloneDeep(dynamiInputs);
        dispatchData(fCopy);
    }

    const dispatchData = async (data) => {
        try {
            const copy_module = module?.toLowerCase()?.split(' ').join('_');
            const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
            const copy_saving_list = { ..._saving_list };
            const additionText = categoryValue === "Admission Information" && data[0].category === "questions" ? "_question" : ""
            copy_saving_list[copy_module] = {
                ...copy_saving_list[copy_module],
                [copy_category + additionText]: data,
            };
            dispatch(client_onboard_data_lists(copy_saving_list));
            savingDataWithApiByCategory(data)
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleStateValueWithDynamicForm = (data) => {
        try {
            const copy = [...data];
            const copy_module = module?.toLowerCase()?.split(' ').join('_');
            const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
            const copy_saving_list = { ..._saving_list };
            const _dataModule = copy_saving_list[copy_module];
            if (_dataModule) {
                const _dataList = _dataModule[copy_category]
                if (_dataList) {
                    for (const key in copy) {
                        if (copy[key]?.category === "text") {
                            copy[key].value = _dataList[key]?.value || "";
                        }
                    }
                    setDynamicInputList(copy)
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const savingQuestionsDataAsPerCategory = () => {
        const fCopy = _.cloneDeep(dynamicQuestions);
        dispatchData(fCopy);
    }

    const handleStateValueWithDynamicQuestions = (data) => {
        try {
            const copy = [...data];
            const copy_module = module?.toLowerCase()?.split(' ').join('_');
            const copy_category = categoryValue?.toLowerCase().split('/ ').join('')?.split(' ').join('_');
            const copy_saving_list = { ..._saving_list };
            const _dataModule = copy_saving_list[copy_module];
            const additionText = categoryValue === "Admission Information" && data[0].category === "questions" ? "_question" : ""
            if (_dataModule) {
                const _dataList = _dataModule[copy_category + additionText]
                if (_dataList) {
                    for (const key in copy) {
                        if (copy[key]?.type === "IndividualRisk" || copy[key]?.type === "HomeRisk") {
                            const childOpt = copy[key]?.childOptions
                            for (const keys in childOpt) {
                                childOpt[keys].value = _dataList[key].childOptions[keys].value
                            }
                        }
                        if (copy[key]?.category === "questions") {
                            copy[key].value = _dataList[key]?.value || "";
                        }
                    }
                    setDynamicQuestions(copy)
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleIndividualRiskChanges = (value, pIndex, index) => {
        try {
            const copy = [...dynamicQuestions];
            copy[pIndex].childOptions[index].value = value;
            setDynamicQuestions(copy);
        } catch (error) {
            console.log("error", error)
        }
    }

    const convertDataIntoSimplifyObjects = (data) => {
        let inputs = {};
        let selectedOptions = {};
        for (const key in data) {
            if (!data[key]?.options) {
                inputs[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = data[key].value;
            }
            if (data[key]?.options) {
                selectedOptions[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = {
                    title: data[key].value,
                    id: data[key]?.options.find((e) => e.attributes.title === data[key].value)?.id
                }
            }
        }
        return { inputs, selectedOptions }
    }

    const resolvers = async (data) => {
        try {
            setLoadingResponse(true)
            for (const array of data) {
                // Insert each array into the database, or process them as needed
                await apiCreateMedicationHistory({
                    variables: {
                        "client_detail_id": _client_id?.id,
                        "question_title": array.title,
                        "answer_value": array.value
                    }
                })
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    const savingDataWithApiByCategory = async (data) => {
        try {
            let clientId = _client_id?.id;
            if (categoryValue === "Basic Information") {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.age = simplify.inputs.age || "1";
                simplify.inputs.date_of_birth = simplify.inputs.date_of_birth || "2000-05-05";
                simplify.inputs.medicare_expiry_date = simplify.inputs.medicare_expiry_date || "2000-05-05";
                simplify.inputs.active = true;
                simplify.inputs.rankings = 5;
                // console.log("simplify.inputs", simplify.inputs);
                // console.log("simplify.selectedOptions", simplify.selectedOptions);
                const response = await apiCreateClient({
                    variables: simplify.inputs
                });
                if (response?.data) {
                    let count = 0;
                    // console.log("response?.data", response?.data);
                    const id = response?.data?.createClientDetail?.data?.id;
                    clientId = id;
                    dispatch(client_id({ id: id, ndis_number: simplify.inputs.ndis_number }))
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: id,
                            module: module,
                            category: categoryValue,
                            input_name: keys,
                            selected_option_value: simplify.selectedOptions[keys].title
                        }
                        // console.log("obj", obj);
                        const responseLovs = await apiCreateClientLovs({
                            variables: obj
                        })
                        if (responseLovs?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created, client id is: " + id, "success")
                    }
                };
            }
            if (categoryValue === "Contact Information" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                simplify.inputs.email = simplify.inputs.email === "" ? "demo@belacorp.com.au" : simplify.inputs.email;
                // ///////console.log("inputs", simplify.inputs)
                // //////console.log("selectedOptions", simplify.selectedOptions)
                const response = await apiCreateContactDetail({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            input_name: keys,
                            selected_option_value: simplify.selectedOptions[keys].title
                        }
                        // console.log("obj", obj);
                        const responseLovs = await apiCreateClientLovs({
                            variables: obj
                        })
                        if (responseLovs?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created.!", "success")
                    }
                }
            }
            if (categoryValue === "Emergency Contact Information" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                simplify.inputs.email = simplify.inputs.email === "" ? "demo@belacorp.com.au" : simplify.inputs.email;
                // console.log("simplify", simplify);
                const response = await apiCreateEmergencyContact({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    handleSnacksApper("Client Onboarding", categoryValue + " has been created.!", "success")
                }
            }
            if (categoryValue === "Administrative Notes" && _client_id?.id) {
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                const response = await apiCreateAdministrative({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                }
            }
            if (categoryValue === "Service Information" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                // console.log(simplify)
                const response = await apiCreateServiceInfo({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            input_name: keys,
                            selected_option_value: simplify.selectedOptions[keys].title
                        }
                        // console.log("obj", obj);
                        const responseLovs = await apiCreateClientLovs({
                            variables: obj
                        })
                        if (responseLovs?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Preference for Staff Worker" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                for (const keys in simplify.selectedOptions) {
                    simplify.inputs[keys] = simplify.selectedOptions[keys].title;
                }
                const response = await apiCreatePreferenceForStaff({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            input_name: "preference_" + keys,
                            selected_option_value: simplify.selectedOptions[keys].title
                        }
                        // console.log("obj", obj);
                        const responseLovs = await apiCreateClientLovs({
                            variables: obj
                        })
                        if (responseLovs?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Client Notes" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                const response = await apiCreateClientNote({
                    variables: simplify.inputs
                });
                if (response?.data) {
                    handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                }
            }
            if (categoryValue === "Client Goals" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                // console.log("simplify", simplify);
                const response = await apiCreateClientGoal({
                    variables: simplify.inputs
                });
                if (response?.data) {
                    handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                }
            }
            if (categoryValue === "Check List (Alerts / Notifications)" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                simplify.inputs.notification_time = simplify.inputs.notification_time + ":00"
                // console.log("simplify", simplify);
                const response = await apiCreateCheckList({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            input_name: "check_list_" + keys,
                            selected_option_value: simplify.selectedOptions[keys].title
                        }
                        // console.log("obj", obj);
                        const responseLovs = await apiCreateClientLovs({
                            variables: obj
                        })
                        if (responseLovs?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Plan Review" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                // console.log("simplify", simplify);
                const response = await apiCreatePlanReview({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                }
            }
            if (categoryValue === "Client Budget" && _client_id?.id) {
                // console.log("data", data);
                const simplify = convertDataIntoSimplifyObjects(data);
                // console.log("simplify", simplify.inputs);
                const response = await apiUpdateClientDetail({
                    variables: {
                        "id": _client_id?.id,
                        "data": {
                            "client_budget": simplify.inputs.client_budget,
                            "charge_band_detail_id": chargeBandsOption.value.id !== "DF1" ? chargeBandsOption.value.id : "",
                        }
                    }
                });
                if (response?.data) {
                    handleSnacksApper("Client Onboarding", categoryValue + " has been added", "success")
                }
            }
            if (categoryValue === "Medication" && _client_id?.id) {
                // console.log("data", data)
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                simplify.inputs.notification_time = simplify.inputs.notification_time + ":00";
                const response = await apiCreateMedications({
                    variables: simplify.inputs
                })
                if (response?.data) {
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            input_name: "medication_" + keys,
                            selected_option_value: simplify.selectedOptions[keys].title
                        }
                        // console.log("obj", obj);
                        const responseLovs = await apiCreateClientLovs({
                            variables: obj
                        })
                        if (responseLovs?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
            }
            if (categoryValue === "Medical History Details" && _client_id?.id) {
                const response = await resolvers(data)
                if (response) {
                    setLoadingResponse(false)
                    handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                }
            }
            if (categoryValue === "Referring Doctor Information" && _client_id?.id) {
                // console.log("data", data)
                const simplify = convertDataIntoSimplifyObjects(data);
                simplify.inputs.client_detail_id = _client_id?.id;
                // console.log("simplify", simplify)
                const response = await apiCreateReferringDoctor({
                    variables: simplify.inputs
                });
                if (response?.data) {
                    handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                }
            }
            if (categoryValue === "Admission Information" && _client_id?.id) {
                const simplify = convertDataIntoSimplifyObjects(data);
                if (Object.keys(simplify.inputs).length) {
                    simplify.inputs.client_detail_id = _client_id?.id;
                    const response = await apiCreateAdmission({
                        variables: simplify.inputs
                    })
                    if (response?.data) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
                if (Object.keys(simplify.selectedOptions).length) {
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            question_title: keys,
                            answer_type: [
                                {
                                    "__typename": "ComponentAnswersSingleAnswer",
                                    "__component": "answers.single-answer",
                                    "active": true,
                                    "drop_down_option_lovs": simplify.selectedOptions[keys].id
                                }
                            ]
                        }
                        const response = await apiClientQuestionAnswer({
                            variables: obj
                        })
                        if (response?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                    }
                }
            }
            if (module === "Individual Risk Assessment" && _client_id?.id) {
                // console.log("data", data)
                let count = 0;
                for (const key in data) {
                    const simplify = convertDataIntoSimplifyObjects(data[key].childOptions)
                    // console.log("simplify",simplify)
                    const object = {
                        client_detail_id: _client_id?.id,
                        module: module,
                        category: categoryValue,
                        question_title: data[key]?.title,
                        answer_type: [
                            {
                                "__typename": "ComponentAnswersIndividualRiskInputs",
                                "__component": "answers.individual-risk-inputs",
                                ...simplify?.inputs,
                                "risk_options": {
                                    "risk_to_individual": simplify?.selectedOptions?.risk_to_individual?.id,
                                    "risk_to_staff": simplify?.selectedOptions?.risk_to_staff?.id,
                                    "risk_to_other": simplify?.selectedOptions?.risk_to_other?.id,
                                }
                            }
                        ]
                    }
                    // console.log("object", object)
                    const response = await apiClientQuestionAnswer({
                        variables: object
                    })
                    if (response?.data) {
                        count++;
                    }
                }
                if (count === data?.length) {
                    handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                }
            }
            if (module === "Home Risk Assessment" && _client_id?.id) {
                if (categoryValue === "Representative Information") {
                    // console.log("data", data)
                    const simplify = convertDataIntoSimplifyObjects(data)
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            question_title: keys,
                            answer_type: [
                                {
                                    "__typename": "ComponentAnswersSingleAnswer",
                                    "__component": "answers.single-answer",
                                    "active": true,
                                    "drop_down_option_lovs": simplify.selectedOptions[keys].id
                                }
                            ]
                        }
                        const response = await apiClientQuestionAnswer({
                            variables: obj
                        })
                        if (response?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                    }
                }
                if (categoryValue === "Type of Residence") {
                    const simplify = convertDataIntoSimplifyObjects(data)
                    let count = 0;
                    for (const keys in simplify.selectedOptions) {
                        const obj = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            input_name: keys,
                            selected_option_value: simplify.selectedOptions[keys].title
                        }
                        // console.log("obj", obj);
                        const responseLovs = await apiCreateClientLovs({
                            variables: obj
                        })
                        if (responseLovs?.data) {
                            count++
                        }
                    }
                    if (count === Object.keys(simplify.selectedOptions).length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " has been created", "success")
                    }
                }
                if (categoryValue !== "Representative Information" && categoryValue !== "Type of Residence") {
                    let count = 0;
                    for (const key in data) {
                        const simplify = convertDataIntoSimplifyObjects(data[key].childOptions)
                        // console.log("simplify",simplify);
                        const object = {
                            client_detail_id: _client_id?.id,
                            module: module,
                            category: categoryValue,
                            question_title: data[key]?.title,
                            answer_type: [
                                {
                                    "__typename": "ComponentAnswersHomeRiskInputs",
                                    "__component": "answers.home-risk-inputs",
                                    ...simplify?.inputs,
                                    "selected_options": simplify?.selectedOptions?.selected_option?.id
                                }
                            ]
                        }
                        // console.log("object",object);
                        const response = await apiClientQuestionAnswer({
                            variables: object
                        })
                        if (response?.data) {
                            count++;
                        }
                    }
                    if (count === data?.length) {
                        handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                    }
                }
            }
            if (module === "Safety Plans" && _client_id?.id) {
                // console.log("data", data);
                let count = 0;
                for (const key in data) {
                    // const simplify = convertDataIntoSimplifyObjects(data[key].childOptions)
                    // console.log("simplify",simplify);
                    const object = {
                        client_detail_id: _client_id?.id,
                        module: module,
                        category: categoryValue,
                        question_title: data[key]?.title,
                        answer_type: [
                            {
                                "__typename": "ComponentAnswersList",
                                "__component": "answers.list",
                                "order_unord_json": data[key]?.order_unord_json
                            }
                        ]
                    }
                    // console.log("object", object);
                    const response = await apiClientQuestionAnswer({
                        variables: object
                    })
                    if (response?.data) {
                        count++;
                    }
                }
                if (count === data?.length) {
                    handleSnacksApper("Client Onboarding", categoryValue + " Questions has been created", "success")
                }
            }
            if (!clientId) {
                throw { message: "Cannot proceed. Basic information is missing." }
            }
        } catch (error) {
            // console.log("error", error);
            let message = "";
            if (error?.message || error_doctor || error_adm || error_admission || error_question || error_history || error_client || error_lovs || error_contact || error_emergency || error_service || error_preference || error_note || error_goal || error_check || error_review || error_update || error_medi) {
                const errors = error_doctor || error_adm || error_admission || error_question || error_history || error_client || error_lovs || error_contact || error_emergency || error_service || error_preference || error_note || error_goal || error_check || error_review || error_update || error_medi;
                if (errors?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (errors?.graphQLErrors) {
                    message = errors.graphQLErrors[0]?.message + "or fields are empty.!"
                }
                if (error?.message) {
                    message = error?.message;
                }
                handleSnacksApper(categoryValue?.toUpperCase(), message, "error")
            }
        }
    }

    // console.log("_saving_list", _saving_list)
    return (
        <Box sx={{ width: "100%", display: 'flex', flexDirection: "column" }}>
            {loadingResponse || loading_question || loading_client || loading_adm || loading_admission || loading_medi || loading_lovs || loading_review || loading_contact || loading_check || loading_goal || loading_emergency || loading_service || loading_note || loading_preference || loading_update || loading_doctor ? (
                <Box sx={{
                    background: "rgba(0,0,0,0.3)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <CircularProgress color="primary" size={35} />
                </Box>
            ) : null}
            {dynamiInputs && (
                <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
                    {dynamiInputs?.map((value, index) => {
                        if (value?.type?.toLowerCase() === "text" || value?.type?.toLowerCase() === "email" || value?.type?.toLowerCase() === "number" || value?.type?.toLowerCase() === "date" || value?.type?.toLowerCase() === "time") {
                            return (
                                <TextField
                                    type={value.type}
                                    key={value.title}
                                    id={value.title}
                                    htmlFor={value.title}
                                    required={value.required}
                                    multiline={value.multiline}
                                    label={value.title}
                                    disabled={value.disabled}
                                    placeholder={value.placeholder}
                                    value={value.value}
                                    sx={{ m: 1, width: value.width }}
                                    helperText={value.error ? value.error : ""}
                                    error={value.error ? true : false}
                                    onChange={(e) => handleChangeValueInputs(e, index)}
                                />
                            )
                        }
                        if (value?.type?.toLowerCase() === "select") {
                            return (
                                <TextField
                                    select={true}
                                    label={value.title}
                                    id={value.title}
                                    htmlFor={value.title}
                                    key={value.title}
                                    required={value.required}
                                    sx={{ m: 1, width: value.width }}
                                    value={value.value}
                                    helperText={value.error ? value.error : ""}
                                    error={value.error ? true : false}
                                    onChange={(e) => handleChangeValueInputs(e, index)}
                                >
                                    {value.options.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        }
                    })}
                    {categoryValue === "Client Budget" && (
                        <TextField
                            select={true}
                            label={chargeBandsOption.title}
                            key={chargeBandsOption.title}
                            // id={v.title}
                            htmlFor={chargeBandsOption.title}
                            required={chargeBandsOption.required}
                            sx={{ m: 1, width: screenWidth }}
                            value={chargeBandsOption.value.title}
                            onChange={(e) => {
                                const copy = { ...chargeBandsOption };
                                const find = copy?.options?.find((f) => f.title === e.target.value)
                                if (find.id !== "DF1") {
                                    copy.value = find;
                                    setChargeBandsOption(copy);
                                }
                            }}
                        >
                            {chargeBandsOption?.options.map((option) => (
                                <MenuItem key={option?.title} value={option?.title}>
                                    {option?.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    {/* saving values as per category */}
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mr: "4%" }}>
                        <Stack direction={"row"} spacing={2}>
                            {/* {can_create_multiple && (
                                <Tooltip title={"Can Add " + categoryValue} placement="left">
                                    <Button color={"primary"} startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
                                        variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                        Add
                                    </Button>
                                </Tooltip>
                            )} */}
                            <Tooltip title="Can Change After Save" placement="left">
                                <Button color={"primary"} onClick={() => savingDataAsPerCategory()}
                                    variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                    {loading_client || loading_admission || loading_adm || loading_medi || loading_lovs || loading_review || loading_contact || loading_check || loading_goal || loading_emergency || loading_service || loading_note || loading_preference || loading_update || loading_doctor ? <CircularProgress color="secondary" size={26} /> : null}
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Box>
                </Box>
            )}

            {dynamicQuestions && (
                <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
                    {dynamicQuestions?.map((v, index) => {
                        if (v?.active && v?.type?.includes("Text")) {
                            return (
                                <TextField
                                    type={v?.type?.toLowerCase()}
                                    key={v.title}
                                    id={v.title}
                                    htmlFor={v.title}
                                    required={v.required}
                                    multiline={v.multiline}
                                    label={v.title}
                                    value={v.value}
                                    sx={{ m: 1, width: v.width }}
                                    onChange={(e) => {
                                        const copy = [...dynamicQuestions];
                                        copy[index].value = e.target.value;
                                        setDynamicQuestions(copy);
                                    }}
                                />
                            )
                        }
                        if (v?.active && v?.type?.includes("Select")) {
                            return (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    key={v.title}
                                    id={v.title}
                                    htmlFor={v.title}
                                    required={v.required}
                                    sx={{ m: 1, width: v.width }}
                                    value={v.value}
                                    onChange={(e) => {
                                        const copy = [...dynamicQuestions]
                                        copy[index].value = e.target.value;
                                        setDynamicQuestions(copy);
                                    }}
                                >
                                    {v?.options.map((option) => (
                                        <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                                            {option?.attributes?.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        }
                        if (v?.active && v?.type?.includes("IndividualRisk")) {
                            return (
                                <Row
                                    key={v?.type + index}
                                    row={v}
                                    screenWidth={v.width}
                                    pIndex={index}
                                    handleIndividualRiskChanges={handleIndividualRiskChanges}
                                />
                            )
                        }
                        if (v?.active && v?.type?.includes("HomeRisk")) {
                            return (
                                <RowHRA
                                    key={v?.type + index}
                                    row={v}
                                    screenWidth={v.width}
                                    pIndex={index}
                                    handleIndividualRiskChanges={handleIndividualRiskChanges}
                                />
                            )
                        }
                        if (v?.active && v?.type?.includes("AnswersList")) {
                            return (
                                <RowSP
                                    key={v?.type + index}
                                    row={v}
                                    pIndex={index}
                                    handleIndividualRiskChanges={handleIndividualRiskChanges}
                                />
                            )
                        }
                    })}
                    {/* saving values as per category */}
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mr: "2%" }}>
                        <Stack direction={"row"} spacing={2}>
                            {/* {can_create_multiple && (
                                <Tooltip title={"Can Add " + categoryValue} placement="left">
                                    <Button color={"primary"} startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
                                        variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                        Add
                                    </Button>
                                </Tooltip>
                            )} */}
                            <Tooltip title="Can Change After Save" placement="left">
                                <Button color={"primary"} onClick={() => savingQuestionsDataAsPerCategory()}
                                    variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                    {loadingResponse || loading_question ? <CircularProgress color="secondary" size={26} /> : null}
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Box>
                </Box>
            )}
            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}
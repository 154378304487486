import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    client_onboard_data_list: {
        client_information: null,
        medical_information: null,
        individual_risk_assessment: null,
        home_risk_assessment: null,
        safety_plans: null,
    },
    client_id: null,
    client_info: null,
    client_healthcare: null,
    client_contact: null,
    client_demographics: null,
    client_emergency_contact: null,
    client_administratives: [],
    client_service: null,
    client_preference: null,
    client_client_notes: null,
    client_client_goals: null,
    client_check_list: null,
    client_plan_review: null,
    client_budget: null,
    client_medication: null,
    medical_history_details: null,
    referring_doctor_information: null,
    admission_information: null,
    draftList: [
        {
            information: {
                first_name: "Saba",
                last_name: "Salman",
                date_of_birth: "1991-08-22",
                age: 32,
                gender: "Female",
                statement: "Email",
                language: "English",
                NDIS: "12365987",
                primary_diagnosis: "abc zxc",
                type_of_service: "Permanent Care",
                complex_consultations: "Low",
            },
            centrelink: {
                medicare_number: "35986",
                medicare_expiry_date: "2005-09-06",
                centerlink: "35698",
            },
            contactDetails: {
                address_1: "adress 1",
                address_2: "address 2",
                country: "Australia",
                state: "WA",
                town_suburb: "Glenelg",
                post_code: "35698",
                email: "admin@belacorp.com.au",
                home_phone: "02326598872",
                mobile_phone: "02326598873",
                work_phone: "02326598874",
            },
            demographics: {
                country_of_birth: "Pakistan",
                family_status: "Single",
                living_arrangements: "Shared",
                religion: "Islam",
                citizenship: "Tourist",
                background: "Culturally diverse",
                indigenous: "No",
            },
            clientemergencyContact: {
                name: "123",
                relationship: "321",
                home_phone_number: "32",
                mobile_phone: "11111111111",
                address: "vvvvvv",
            },
            administratives: [
                {
                    notes: ""
                }
            ]
        }
    ],
    selectedDraft: null
};

export const ClientOnboardReducer = createSlice({
    name: 'client_onboarding',
    initialState,
    reducers: {
        clientInfoReducer: (state, action) => {
            state.client_info = action.payload;
        },
        clientHealthcare: (state, action) => {
            state.client_healthcare = action.payload;
        },
        clientContact: (state, action) => {
            state.client_contact = action.payload;
        },
        client_demographics: (state, action) => {
            state.client_demographics = action.payload;
        },
        client_emergency_contact: (state, action) => {
            state.client_emergency_contact = action.payload;
        },
        client_administratives: (state, action) => {
            state.client_administratives = action.payload;
        },
        client_service: (state, action) => {
            state.client_service = action.payload;
        },
        client_preference: (state, action) => {
            state.client_preference = action.payload;
        },
        client_client_notes: (state, action) => {
            state.client_client_notes = action.payload;
        },
        client_client_goals: (state, action) => {
            state.client_client_goals = action.payload;
        },
        client_check_list: (state, action) => {
            state.client_check_list = action.payload;
        },
        client_plan_review: (state, action) => {
            state.client_plan_review = action.payload;
        },
        client_budget: (state, action) => {
            state.client_budget = action.payload;
        },
        selected_draft: (state, action) => {
            state.selectedDraft = action.payload;
        },
        draft_list: (state, action) => {
            state.draftList = action.payload;
        },
        client_medication: (state, action) => {
            state.client_medication = action.payload;
        },
        medical_history_details: (state, action) => {
            state.medical_history_details = action.payload;
        },
        referring_doctor_information: (state, action) => {
            state.referring_doctor_information = action.payload;
        },
        admission_information: (state, action) => {
            state.admission_information = action.payload;
        },
        client_onboard_data_lists: (state, action) => {
            state.client_onboard_data_list = action.payload;
        },
        client_id: (state, action) => {
            state.client_id = action.payload;
        },
    },
});

export const {
    clientInfoReducer,
    selected_draft,
    draft_list,
    clientHealthcare,
    clientContact,
    client_demographics,
    client_emergency_contact,
    client_administratives,
    client_service,
    client_preference,
    client_client_notes,
    client_client_goals,
    client_check_list,
    client_plan_review,
    client_budget,
    client_medication,
    medical_history_details,
    referring_doctor_information,
    admission_information,
    client_onboard_data_lists,
    client_id
} = ClientOnboardReducer.actions;

export default ClientOnboardReducer.reducer;